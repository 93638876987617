import React, { useEffect, useRef, useState } from "react";
import MainLayout from "../MainLayout/MainLayout";
import styles from "./TwoColumnLayout.module.scss";
import { useDispatch, useSelector } from "react-redux";
import Separator from "../../components/Separator/Separator";
import axiosInstance from "../../utils/utils";
import {
  setConfluencePage,
  setConfluenceTree,
} from "../../redux/confluenceStore";
import classNames from "classnames";
import { setLoadingContext } from "../../redux/loaderStore";
import Loader from "../../components/Loader/Loader";
import _ from "lodash";
import pageNotFoundIllustration from "../../icons/404.svg";
import { useLocation, useParams } from "react-router-dom";
import { hasRealContent } from "../../utils/helpers";
import { COMPONENT_LOADER, MODAL_LOADER } from "../../constants/loader";
import ConfluencePageDataModal from "../../modals/ConfluencePageDataModal";
import { showModal } from "../../redux/modalStore";
import { setModalConfluencePage } from "../../redux/modalConfluenceStore";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import ExpandableMenu from "../../components/ExpandableMenu/ExpandableMenu";
import { ReactComponent as ArrowUpIcon } from "../../icons/circle-arrow-up.svg";
import Input from "../../components/Input/Input";
import useFetchOrganizationData from "../../actions/useFetchOrganizationData";

const TwoColumnLayout = (props) => {
  const { orgId } = useParams();
  const location = useLocation();
  const leftSideRef = useRef(null);
  const rightSideRef = useRef(null);

  const [selectedChapter, setSelectedChapter] = useState(null);
  const [selectedPageId, setSelectedPageId] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");

  const confluenceTreeData = useSelector(
    (state) => state.confluence.confluenceTreeData,
  );
  const confluencePageData = useSelector(
    (state) => state.confluence.confluencePageData,
  );

  const modalConfluencePageData = useSelector(
    (state) => state.modalConfluence.modalConfluencePageData,
  );
  const module = useSelector((state) => state.user.modules[orgId]);
  const loadingContexts = useSelector((state) => state.loader.loadingContexts);
  const notFoundPage = useSelector((state) => state.layout.notFoundPage);

  const hasFetchedPageData = useRef(false); // Ref to control the first fetch

  const isComponentLoading = loadingContexts[COMPONENT_LOADER];

  // Fetch organization data when navigating through URL
  useFetchOrganizationData();

  const clearConfluenceData = () => {
    dispatch(setConfluenceTree(null)); // Clear the confluence tree data
    dispatch(setConfluencePage(null)); // Clear the confluence page data
  };

  const dispatch = useDispatch();

  const sessionToken = localStorage.getItem("session_token");

  // Extracting path from URL
  const lastIndex = location.pathname.lastIndexOf("/");
  const modifiedPath = location.pathname.substring(lastIndex);

  const extractChapter = (pageName) => {
    const match = pageName.match(/^([A-H]\d*)\s*[-–]\s*/);
    return match ? match[1] : null;
  };

  // Find the module object corresponding to the current path
  const currentModule = module?.find((mod) => mod.path === modifiedPath);

  const getConfluencePageData = (pageId, pageName) => {
    setSelectedPageId(pageId); // Update the state immediately
    setSelectedChapter(extractChapter(pageName));

    if (!sessionToken) {
      clearConfluenceData();
      return;
    }

    const chapter = extractChapter(pageName);

    console.log(selectedChapter, "chapter");

    setSelectedChapter(chapter);
    setSelectedPageId(pageId);

    // Extract the space parameter from the module object
    const space = currentModule?.params?.space || "";

    const options = {
      method: "GET",
      url: `/confluence/page?org_id=${orgId}&space_key=${space}&page_id=${pageId}`,
      headers: {
        "session-token": sessionToken,
      },
    };

    dispatch(setLoadingContext({ context: COMPONENT_LOADER, isLoading: true }));

    return axiosInstance
      .request(options)
      .then((response) => {
        dispatch(
          setLoadingContext({ context: COMPONENT_LOADER, isLoading: false }),
        );
        dispatch(setConfluencePage(response.data));
      })
      .catch((error) => {
        clearConfluenceData();
        dispatch(
          setLoadingContext({ context: COMPONENT_LOADER, isLoading: false }),
        );
        dispatch(
          showModal({
            name: "error_modal",
          }),
        );
      });
  };

  const getModalConfluencePageData = (space, pageId) => {
    const options = {
      method: "GET",
      url: `/confluence/page?org_id=${orgId}&space_key=${space}&page_id=${pageId}`,
      headers: {
        "session-token": sessionToken,
      },
    };

    dispatch(setLoadingContext({ context: MODAL_LOADER, isLoading: true }));

    return axiosInstance
      .request(options)
      .then((response) => {
        dispatch(
          setLoadingContext({ context: MODAL_LOADER, isLoading: false }),
        );
        dispatch(setModalConfluencePage(response.data));
      })
      .catch(() => {
        dispatch(
          setLoadingContext({ context: MODAL_LOADER, isLoading: false }),
        );
        dispatch(
          showModal({
            name: "error_modal",
          }),
        );
      });
  };

  // Function to generate JSX for the page names
  const renderPageNames = (data, level = 0, currentPath) => {
    const isActive = confluencePageData?.title === data.page_name;

    return (
      <div
        key={data.page_id}
        className={styles.content}
        style={{ marginLeft: level * 5 }}
      >
        <div
          onClick={() =>
            getConfluencePageData(
              data.page_id,
              data.page_name,
              location.pathname,
            )
          }
          className={classNames(styles.pageItems, isActive && styles.active)}
        >
          {data.page_name}
        </div>
        {data?.children?.length > 0 && (
          <div className={styles.items}>
            {data?.children?.map((child) => (
              <div key={child.page_id}>{renderPageNames(child, level + 1)}</div>
            ))}
          </div>
        )}
      </div>
    );
  };

  const isConfluencePageData = !_.isEmpty(confluencePageData);

  // Send request for the first page item when the component mounts and confluenceTreeData is available
  useEffect(() => {
    if (
      confluenceTreeData?.children?.length > 0 &&
      !hasFetchedPageData.current
    ) {
      const firstPageId = confluenceTreeData?.children[0].page_id;
      getConfluencePageData(firstPageId, modifiedPath);
      hasFetchedPageData.current = true;
    }
  }, [confluenceTreeData, modifiedPath]);

  useEffect(() => {
    clearConfluenceData();
    hasFetchedPageData.current = false;
  }, [orgId]);

  const isContentEmpty = !hasRealContent(confluencePageData?.body);

  const addOnClickHandlersToLinks = (htmlContent) => {
    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(htmlContent, "text/html");

    const links = htmlDoc.querySelectorAll("a");
    const fileExtensions = /\.(pdf|docx|xlsx|csv|pptx|txt|zip)$/i; // Files extensions

    links.forEach((link) => {
      const cSpace = link.getAttribute("c-space");
      const cPageId = link.getAttribute("c-page_id");
      const href = link.getAttribute("href");

      if (href && fileExtensions.test(href)) {
        // Handle file links: open in a new tab
        link.setAttribute("target", "_blank");
        link.setAttribute("rel", "noopener noreferrer");
      } else if (cSpace && cPageId) {
        // Handle links with `c-space` and `c-page_id` (internal links for modal)
        link.setAttribute(
          "onclick",
          `handleLinkClick(event, '${cSpace}', ${cPageId})`,
        );
      } else if (href && href.startsWith("http")) {
        // Handle other external links
        link.setAttribute("target", "_blank");
        link.setAttribute("rel", "noopener noreferrer");
      }
    });

    return htmlDoc.body.innerHTML;
  };

  const scrollToTop = () => {
    // if (leftSideRef.current) {
    //   leftSideRef.current.scrollTo({ top: 0, behavior: "smooth" });
    // }

    if (rightSideRef.current) {
      rightSideRef.current.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  // Function to handle link clicks
  const handleLinkClick = (event, cSpace, cPageId) => {
    event.preventDefault();
    getModalConfluencePageData(cSpace, cPageId);
    dispatch(
      showModal({
        name: "quality_management_system_modal",
      }),
    );
  };

  useEffect(() => {
    window.handleLinkClick = handleLinkClick;
  }, []);

  const filterTreeData = (treeData, query) => {
    if (!query) return treeData; // Return the original tree data if there's no query

    const result = [];

    const searchTree = (nodes) => {
      nodes.forEach((node) => {
        if (node.page_name.toLowerCase().includes(query.toLowerCase())) {
          result.push(node);
        }
        if (node.children?.length > 0) {
          searchTree(node.children);
        }
      });
    };

    searchTree(treeData);
    return result; // Return only the matching nodes
  };

  const filteredTreeData = filterTreeData(
    confluenceTreeData?.children || [],
    searchQuery,
  );

  return (
    <MainLayout>
      <div className={styles.column}>
        <div className={styles.top}>
          <Breadcrumbs data={currentModule} />
        </div>
        {notFoundPage ? (
          <div className={styles.pageNotFoundWrapper}>
            <img src={pageNotFoundIllustration} alt="404 Illustration" />
            <span>
              Oops! We can't seem to find the page you're looking for.
            </span>
          </div>
        ) : (
          <>
            <Separator />
            <div
              className={classNames(
                styles.wrapper,
                isConfluencePageData && styles.rightSideActive,
              )}
            >
              <div className={styles.leftSide}>
                <Input
                  placeholder="Search pages..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  isClearable
                  onClear={() => setSearchQuery("")}
                />
                {searchQuery ? (
                  filteredTreeData?.length > 0 ? (
                    <div className={styles.results}>
                      {filteredTreeData.map((page) => (
                        <div
                          key={page.page_id}
                          onClick={() =>
                            getConfluencePageData(page.page_id, page.page_name)
                          }
                          className={classNames(styles.pageItems, {
                            [styles.active]: selectedPageId === page.page_id,
                          })}
                        >
                          {page.page_name}
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className={styles.noResults}>No pages found</div>
                  )
                ) : (
                  <ExpandableMenu
                    treeData={confluenceTreeData?.children}
                    onNodeClick={(pageId, pageName) =>
                      getConfluencePageData(pageId, pageName)
                    }
                    selectedPageId={selectedPageId}
                  />
                )}
              </div>

              <Separator vertical className={styles.separator} />
              {(isComponentLoading || isConfluencePageData) && (
                <div className={styles.rightSide} ref={rightSideRef}>
                  {isComponentLoading && (
                    <Loader text="Loading, thank you for your patience." />
                  )}
                  {isConfluencePageData && !isComponentLoading && (
                    <div className={styles.rightSideWrapper}>
                      <h1>{confluencePageData?.title}</h1>
                      {!isContentEmpty && (
                        <>
                          <Separator className={styles.dataSeparator} />
                          <div className={classNames(styles.dataWrapper)}>
                            <div
                              id="confluence-content"
                              dangerouslySetInnerHTML={{
                                __html: addOnClickHandlersToLinks(
                                  confluencePageData?.body,
                                ),
                              }}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          </>
        )}
        <ConfluencePageDataModal
          modalConfluencePageData={modalConfluencePageData}
          addOnClickHandlersToLinks={addOnClickHandlersToLinks}
        />
      </div>
      {confluencePageData && (
        <div className={styles.backToTop} onClick={() => scrollToTop()}>
          <ArrowUpIcon />
        </div>
      )}
    </MainLayout>
  );
};

export default TwoColumnLayout;

import { createSlice } from "@reduxjs/toolkit";

const overlayLoaderSlice = createSlice({
  name: "overlayLoader",
  initialState: {
    isVisible: false,
  },
  reducers: {
    showOverlay(state, action) {
      state.isVisible = true;
    },
    hideOverlay(state) {
      state.isVisible = false;
    },
  },
});

export const { showOverlay, hideOverlay } = overlayLoaderSlice.actions;

export default overlayLoaderSlice.reducer;

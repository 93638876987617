import React, { useState, useEffect, useRef } from "react";
import styles from "./QuestionTree.module.scss";
import { ReactComponent as DeleteIcon } from "../../icons/delete.svg";

const QuestionTree = ({ field, form, questionIndex = 0, ...props }) => {
  const [lineHeight, setLineHeight] = useState(0);
  const questionRef = useRef(null);
  const optionsRef = useRef(null);

  // Get values from Formik or use defaults
  const values = field.value || { question: "", options: ["", ""] };

  // Initialize the field with default values if it doesn't exist yet
  useEffect(() => {
    if (!field.value) {
      form.setFieldValue(field.name, { question: "", options: ["", ""] });
    }
  }, [field.name, field.value, form]);

  const addOption = () => {
    const newOptions = [...values.options, ""];
    form.setFieldValue(field.name, {
      ...values,
      options: newOptions,
    });
  };

  const deleteOption = (indexToDelete) => {
    // Ensure we maintain at least two options
    if (values.options.length <= 2) {
      return;
    }

    const newOptions = values.options.filter(
      (_, index) => index !== indexToDelete,
    );
    form.setFieldValue(field.name, {
      ...values,
      options: newOptions,
    });
  };

  useEffect(() => {
    if (questionRef.current && optionsRef.current) {
      const questionBottom = questionRef.current.getBoundingClientRect().bottom;
      const lastOption = optionsRef.current.lastElementChild;

      if (lastOption) {
        const horizontalLine = lastOption.querySelector(
          `.${styles.horizontalLine}`,
        );
        const horizontalLineBottom = horizontalLine
          ? horizontalLine.getBoundingClientRect().top + 1
          : lastOption.getBoundingClientRect().bottom;

        setLineHeight(horizontalLineBottom - questionBottom);
      }
    }
  }, [values.options]);

  const handleQuestionChange = (e) => {
    form.setFieldValue(field.name, {
      ...values,
      question: e.target.value,
    });
  };

  const handleOptionChange = (index, value) => {
    const newOptions = [...values.options];
    newOptions[index] = value;
    form.setFieldValue(field.name, {
      ...values,
      options: newOptions,
    });
  };

  return (
    <div className={styles.questionTree}>
      <div className={styles.question}>
        <label className={styles.label}>Question {questionIndex + 1}</label>
        <input
          type="text"
          placeholder="Write a new question"
          className={styles.questionInput}
          ref={questionRef}
          value={values.question}
          onChange={handleQuestionChange}
          onBlur={field.onBlur}
          name={`${field.name}.question`}
        />
      </div>
      <div className={styles.options} ref={optionsRef}>
        <div
          className={styles.verticalLine}
          style={{ height: lineHeight > 0 ? `${lineHeight}px` : "0px" }}
        ></div>
        {values.options.map((option, index) => (
          <div key={index} className={styles.option}>
            <div className={styles.horizontalLine}></div>
            <div className={styles.inputWrapper}>
              <label className={styles.label}>
                Option {questionIndex + 1}.{index + 1}
              </label>
              <div className={styles.inputWithAction}>
                <input
                  type="text"
                  value={option}
                  onChange={(e) => handleOptionChange(index, e.target.value)}
                  onBlur={field.onBlur}
                  name={`${field.name}.options[${index}]`}
                  placeholder="Write a new option"
                  className={styles.optionInput}
                />
                {index === values.options.length - 1 &&
                  values.options.length > 2 && (
                    <DeleteIcon
                      className={styles.removeQuestion}
                      onClick={() => deleteOption(index)}
                    />
                  )}
              </div>
              {index === values.options.length - 1 && (
                <button
                  type="button"
                  className={styles.addOption}
                  onClick={addOption}
                >
                  + Add option
                </button>
              )}
            </div>
          </div>
        ))}
      </div>
      {form.errors[field.name] && form.touched[field.name] && (
        <div className={styles.error}>{form.errors[field.name]}</div>
      )}
    </div>
  );
};

export default QuestionTree;

import React from "react";
import { useSelector } from "react-redux";
import styles from "./TreeLayout.module.scss";
import classNames from "classnames";
import { Tooltip } from "react-tooltip";

const TreeLayout = () => {
  const { findingsData, auditsData, risksData } = useSelector(
    (state) => state.organization,
  );
  const { meModeActive } = useSelector((state) => state.filters);
  const currentUser = useSelector((state) => state.user.email);

  const impactScores = { low: 1, medium: 2, high: 3, null: 1 };

  // Utility function to validate if the finding exists in findingsData
  const isValidFinding = (findingId, findingsData) => {
    return findingsData.hasOwnProperty(findingId);
  };

  const mainChapters = {};
  let directFindingsCount = 0;
  let directFindings = [];

  // Populate main chapters from risks
  Object.values(risksData).forEach((risk) => {
    const mainChapter = risk.qms_id[0]; // Extract the first letter

    if (!mainChapters[mainChapter]) {
      mainChapters[mainChapter] = {
        auditsCount: 0,
        linkedFindingsCount: 0,
        totalFindingsCount: 0,
        rpsCount: 0,
        improvementsCount: 0,
        finalRpsCount: 0,
        linkedFindings: [],
        audits: [],
        linkedImprovements: [],
      };
    }
  });

  // Count audits, linked findings, and calculate RPS
  Object.values(auditsData).forEach((auditTask) => {
    const countedChapters = new Set();
    const auditKey = auditTask.key;

    auditTask.form.forEach((formEntry) => {
      if (formEntry.risk && formEntry.risk.qms_id) {
        const auditChapter = formEntry.risk.qms_id[0];

        // Check if audit should be included in 'me mode'
        const isRelevantAudit =
          !meModeActive ||
          auditTask.linked_findings.some(
            (findingId) =>
              isValidFinding(findingId, findingsData) &&
              [
                findingsData[findingId].responsible,
                findingsData[findingId].accountable,
                findingsData[findingId].consulted,
                findingsData[findingId].informed,
              ].some((role) =>
                Array.isArray(role)
                  ? role.includes(currentUser)
                  : role === currentUser,
              ),
          );

        if (
          mainChapters[auditChapter] &&
          !countedChapters.has(auditChapter) &&
          isRelevantAudit
        ) {
          mainChapters[auditChapter].auditsCount += 1;
          mainChapters[auditChapter].audits.push(auditKey);
          countedChapters.add(auditChapter);

          const validLinkedFindings = auditTask.linked_findings.filter(
            (findingId) =>
              isValidFinding(findingId, findingsData) &&
              (!meModeActive ||
                [
                  findingsData[findingId].responsible,
                  findingsData[findingId].accountable,
                  findingsData[findingId].consulted,
                  findingsData[findingId].informed,
                  findingsData[findingId].reporter,
                ].some((role) =>
                  Array.isArray(role)
                    ? role.includes(currentUser)
                    : role === currentUser,
                )),
          );

          mainChapters[auditChapter].linkedFindingsCount +=
            validLinkedFindings.length;
          mainChapters[auditChapter].linkedFindings.push(
            ...validLinkedFindings,
          );
        }
      }
    });
  });

  // Calculate RPS for all findings and linked improvements
  Object.values(findingsData).forEach((finding) => {
    const isRelevantFinding =
      !meModeActive ||
      [
        finding.responsible,
        finding.accountable,
        finding.consulted,
        finding.informed,
      ].some((role) =>
        Array.isArray(role) ? role.includes(currentUser) : role === currentUser,
      );

    if (isRelevantFinding) {
      const chapter = finding.risk?.qms_id[0];
      if (chapter && mainChapters[chapter]) {
        mainChapters[chapter].totalFindingsCount += 1;

        const riskLevel = finding.risk.level || "null";
        const findingRps = impactScores[riskLevel];
        mainChapters[chapter].rpsCount += findingRps;

        if (finding.linked_improvements?.length > 0) {
          mainChapters[chapter].improvementsCount +=
            finding.linked_improvements.length;
          mainChapters[chapter].linkedImprovements.push(
            ...finding.linked_improvements,
          );

          const validLinkedImprovements = finding.linked_improvements.filter(
            (improvementId) => isValidFinding(improvementId, findingsData),
          );

          validLinkedImprovements.forEach(() => {
            mainChapters[chapter].finalRpsCount += findingRps;
            mainChapters[chapter].finalRpsCount -= impactScores[riskLevel];
          });
        } else {
          mainChapters[chapter].finalRpsCount += findingRps;
        }
      }

      if (finding.linked_audittasks.length === 0) {
        directFindingsCount += 1;
        directFindings.push(finding.key);
      }
    }
  });

  return (
    <div className={styles.treeLayout}>
      <div className={styles.columnTitles}>
        <div className={styles.columnTitle}>Define</div>
        <div className={styles.columnTitle}>Measure</div>
        <div className={styles.columnTitle}>Analyse</div>
        <div className={styles.columnTitle}>Improve</div>
        <div className={styles.columnTitle}>Control</div>
      </div>
      {Object.entries(mainChapters)
        .sort(([chapterA], [chapterB]) => chapterA.localeCompare(chapterB)) // Sorting alphabetically by chapter name
        .map(([chapter, data], index) => {
          return (
            <div className={styles.chapterRow} key={index}>
              <LineComponent />
              <ChapterBox chapter={chapter} />
              <div className={styles.measure}>
                <AuditBox
                  auditsCount={data.auditsCount}
                  audits={data.audits}
                  tooltipId={`audit-tooltip-${chapter}`}
                />
                <FindingBox
                  findingsCount={data.linkedFindingsCount}
                  linkedFindings={data.linkedFindings}
                  tooltipId={`finding-tooltip-${chapter}`}
                />
              </div>
              <RPSBox rpsCount={data.rpsCount} />
              <ImprovementBox
                improvementsCount={data.improvementsCount}
                linkedImprovements={data.linkedImprovements}
                tooltipId={`improvement-tooltip-${chapter}`}
              />
              <FinalRPSBox finalRpsCount={data.finalRpsCount} />
            </div>
          );
        })}
      <div className={styles.directFindingsRow}>
        <HalfLineComponent />
        <div className={styles.placeholderBox} /> {/* Empty Define column */}
        <div className={classNames(styles.measure, styles.lastRow)}>
          <div className={styles.directFindingsBox}>Direct</div>
          <FindingBox
            findingsCount={directFindingsCount}
            linkedFindings={directFindings}
            tooltipId="direct-findings-tooltip"
          />
        </div>
        <div className={styles.placeholderBox} /> {/* Empty Control column */}
      </div>
      <Tooltip />
    </div>
  );
};

const ChapterBox = ({ chapter }) => (
  <div className={styles.chapterBox}>
    <div>Chapter {chapter}</div>
  </div>
);

const AuditBox = ({ auditsCount, audits, tooltipId }) => (
  <div
    className={classNames(
      styles.auditBox,
      auditsCount === 0 && styles.emptyBox,
    )}
    data-tooltip-id={tooltipId}
    data-tooltip-content={`${audits.join(", ")}`}
  >
    <div>{auditsCount} Audits</div>
    <Tooltip id={tooltipId} place="top" className={styles.tooltip} />
  </div>
);

const FindingBox = ({ findingsCount, linkedFindings, tooltipId }) => (
  <div
    className={classNames(
      styles.findingBox,
      findingsCount === 0 && styles.emptyBox,
    )}
    data-tooltip-id={tooltipId}
    data-tooltip-content={`${linkedFindings?.join(", ")}`}
  >
    <div>{findingsCount} Findings</div>
    <Tooltip id={tooltipId} place="top" className={styles.tooltip} />
  </div>
);

const RPSBox = ({ rpsCount }) => (
  <div className={styles.rpsBox}>
    <div>{rpsCount} RPS</div>
  </div>
);

const ImprovementBox = ({
  improvementsCount,
  linkedImprovements,
  tooltipId,
}) => (
  <div
    className={classNames(
      styles.improvementBox,
      improvementsCount === 0 && styles.emptyBox,
    )}
    data-tooltip-id={tooltipId}
    data-tooltip-content={`${linkedImprovements.join(", ")}`}
  >
    <div>{improvementsCount} Improvements</div>
    <Tooltip id={tooltipId} place="top" className={styles.tooltip} />
  </div>
);

const FinalRPSBox = ({ finalRpsCount }) => (
  <div className={styles.finalRpsBox}>
    <div>{finalRpsCount || "0"} RPS</div>
  </div>
);

const LineComponent = () => <div className={styles.line}></div>;

const HalfLineComponent = () => <div className={styles.halfLine}></div>;

export default TreeLayout;

import React, { useState } from "react";
import styles from "./CollapsibleCard.module.scss";
import { ReactComponent as DownArrowIcon } from "../../icons/down-arrow.svg";
import Button from "../Button/Button";

const CollapsibleCard = ({
  title,
  children,
  defaultOpen = false,
  buttonText,
  onButtonClick,
}) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  const handleButtonClick = () => {
    if (!isOpen) {
      setIsOpen(true); // Expand the card before triggering action
    }
    if (onButtonClick) {
      onButtonClick();
    }
  };

  return (
    <div className={styles.card}>
      <div className={styles.header}>
        <div
          className={styles.headerLeftSide}
          onClick={() => setIsOpen(!isOpen)}
        >
          <DownArrowIcon
            className={`${styles.icon} ${isOpen ? styles.open : ""}`}
          />
          <span className={styles.title}>{title}</span>
        </div>
        {buttonText && onButtonClick && (
          <Button
            text={buttonText}
            onClick={handleButtonClick}
            className={styles.actionButton}
            type="button"
          />
        )}
      </div>
      {isOpen && <div className={styles.content}>{children}</div>}
    </div>
  );
};

export default CollapsibleCard;

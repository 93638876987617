import React from "react";
import styles from "./Button.module.scss";
import classNames from "classnames";

const Button = ({
  text,
  type,
  onClick,
  disabled,
  width,
  className,
  icon,
  iconPosition = "left",
}) => {
  return (
    <button
      className={classNames(
        styles.buttonWrapper,
        className,
        disabled && styles.disabled,
      )}
      type={type}
      onClick={onClick}
      style={{ width: width }}
      disabled={disabled}
    >
      {icon && iconPosition === "left" && (
        <span className={styles.iconLeft}>
          {typeof icon === "string" ? (
            <img src={icon} alt="Button icon" />
          ) : typeof icon === "function" ? (
            React.createElement(icon)
          ) : (
            icon
          )}
        </span>
      )}
      {text}
      {icon && iconPosition === "right" && (
        <span className={styles.iconRight}>
          {typeof icon === "string" ? (
            <img src={icon} alt="Button icon" />
          ) : typeof icon === "function" ? (
            React.createElement(icon)
          ) : (
            icon
          )}
        </span>
      )}
    </button>
  );
};

export default Button;

import React, { useState } from "react";
import styles from "./QMSAccordion.module.scss";
import PlusIcon from "../../icons/plus-circle.svg";
import CloseIcon from "../../icons/close-borderly.svg";
import { ReactComponent as CrossIcon } from "../../icons/close.svg";
import { ReactComponent as CheckIcon } from "../../icons/check.svg";
import classNames from "classnames";
import axiosInstance from "../../utils/utils";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Button from "../Button/Button";
import { getOrganizationData } from "../../actions/organization";
import { Tooltip } from "react-tooltip";
import Swal from "sweetalert2";
import Spinner from "../Spinner/Spinner";

const QMSAccordion = ({ permit, selectedChapter }) => {
  const dispatch = useDispatch();
  const { orgId, pageId } = useParams();

  const sessionToken = localStorage.getItem("session_token");

  const [isOpen, setIsOpen] = useState(false);
  const [isValidating, setIsValidating] = useState(false);

  const module = useSelector((state) => state.user.modules[orgId]);
  const currentModule = module?.find((mod) => mod.path.includes("qms"));
  const spaceKey = currentModule?.params?.space || "";

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  console.log(permit.questions, "questions");

  // Check if `selectedChapter` contains a number (A1, A2, ...)
  const isSpecificChapter = selectedChapter && /\d/.test(selectedChapter);

  // Check if ANY question has been validated (qms exists)
  const hasValidation = permit.questions.some((question) => question.qms);

  const validateCompliance = async (e) => {
    e.stopPropagation();

    if (!isSpecificChapter || !permit || !permit.questions.length) return;

    setIsValidating(true); // Show loading state

    // Show a toast notification that this will take time without confirmation
    Swal.fire({
      title: "Validation Started",
      text: "This may take a few minutes. You can continue working in other parts of the application.",
      icon: "info",
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 7000,
      timerProgressBar: true,
      didOpen: (toast) => {
        const popup = Swal.getPopup();

        popup.addEventListener("mouseenter", () => {
          Swal.stopTimer();
        });

        popup.addEventListener("mouseleave", () => {
          Swal.resumeTimer();
        });
      },
    });

    const chapter = permit.questions[0]?.chapter; // Assume all questions belong to the same chapter
    const questionNums = permit.questions.map((q) => q.num); // Extract 'num' values

    const url = `/ai/qms_permit_questions?org_id=${orgId}&space_key=${spaceKey}&page_id=${pageId}&chapter=${chapter}`;

    try {
      const response = await axiosInstance.post(url, questionNums, {
        headers: {
          "session-token": sessionToken,
        },
      });
      console.log("Validation Response:", response.data);

      await dispatch(
        getOrganizationData(
          orgId,
          sessionToken,
          null,
          false,
          false,
          false,
          false,
          false,
          true,
        ),
      );

      // Show success toast notification
      Swal.fire({
        title: "Success!",
        text: "Compliance validation completed successfully.",
        icon: "success",
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 7000,
        timerProgressBar: true,
        didOpen: (toast) => {
          const popup = Swal.getPopup();

          popup.addEventListener("mouseenter", () => {
            Swal.stopTimer();
          });

          popup.addEventListener("mouseleave", () => {
            Swal.resumeTimer();
          });
        },
      });
    } catch (error) {
      console.error("Error validating compliance:", error);

      // Show error toast notification
      Swal.fire({
        title: "Error",
        text: "Validation failed. Please try again.",
        icon: "error",
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        didOpen: (toast) => {
          const popup = Swal.getPopup();

          popup.addEventListener("mouseenter", () => {
            Swal.stopTimer();
          });

          popup.addEventListener("mouseleave", () => {
            Swal.resumeTimer();
          });
        },
      });
    } finally {
      setIsValidating(false);
    }
  };

  return (
    <div className={styles.accordion}>
      <div
        className={classNames(styles.accordionButton, isOpen && styles.opened)}
        onClick={toggleAccordion}
      >
        {permit.name} {/* Permit name at the top */}
        <div className={styles.buttonGroup}>
          {isSpecificChapter && (
            <Button
              text={isValidating ? "Validating..." : "Validate Compliance"}
              onClick={validateCompliance}
              disabled={isValidating}
              className={styles.validateButton}
              icon={isValidating ? <Spinner /> : null}
            />
          )}
          {isOpen ? (
            <img src={CloseIcon} alt="Close icon" className={styles.icon} />
          ) : (
            <img src={PlusIcon} alt="Open icon" className={styles.icon} />
          )}
        </div>
      </div>
      {isOpen && (
        <div className={styles.accordionContent}>
          <table className={styles.permitTable}>
            <thead>
              <tr>
                <th>Chapter</th>
                <th className={styles.center}>#</th>
                <th>Permit Question</th>
                {hasValidation && <th>Validation</th>}
              </tr>
            </thead>
            <tbody>
              {permit.questions.map((question, index) => {
                const uniqueKey =
                  question.uuid ||
                  `${question.chapter}-${question.num}` ||
                  `question-${index}`;

                return (
                  <tr key={uniqueKey}>
                    <td className={styles.center}>{question.chapter}</td>
                    <td>{question.num}</td>
                    <td>{question.question}</td>
                    {hasValidation && (
                      <td className={styles.center}>
                        {question.qms ? (
                          <>
                            <span
                              data-tooltip-id={`tooltip-${question.num}`}
                              data-tooltip-content={question.qms.explanation}
                            >
                              {question.qms.answered ? (
                                <CheckIcon
                                  className={classNames(
                                    styles.validationIcon,
                                    styles.check,
                                  )}
                                />
                              ) : (
                                <CrossIcon
                                  className={classNames(
                                    styles.validationIcon,
                                    styles.cross,
                                  )}
                                />
                              )}
                            </span>
                            <Tooltip
                              id={`tooltip-${question.num}`}
                              place="top"
                              className={styles.tooltip}
                            />
                          </>
                        ) : (
                          <span className={styles.noData}>
                            No validation yet
                          </span>
                        )}
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default QMSAccordion;

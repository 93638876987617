import React, { useEffect, useRef, useState } from "react";
import { Formik, Form, Field } from "formik";
import Modal from "../components/Modal/Modal";
import { hideModal, showModal } from "../redux/modalStore";
import { useDispatch, useSelector } from "react-redux";
import DatePickerField from "../components/FormikComponents/DatePickerField";
import Textarea from "../components/Textarea/Textarea";
import styles from "./NewFindingModal.module.scss";
import Button from "../components/Button/Button";
import Input from "../components/Input/Input";
import FormikCheckbox from "../components/FormikComponents/FormikCheckBox/FormikCheckBox";
import FormikUpload from "../components/FormikComponents/FormikUpload/FormikUpload";
import Select from "../components/Select/Select";
import _ from "lodash";
import axiosInstance from "../utils/utils";
import { useNavigate, useParams } from "react-router-dom";
import { getOrganizationData } from "../actions/organization";
import * as Yup from "yup";
import store from "../core/store";
import { setLinkedData } from "../redux/findingsStore";
import ConfirmationPopup from "./ConfirmationPopup";
import classNames from "classnames";

const NewFindingModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [prefilledChapterFromAPI, setPrefilledChapterFromAPI] = useState("");
  const [checklist, setChecklist] = useState({});
  const formikRef = useRef(null); // Ref to access Formik's setFieldValue

  const { risksData, auditsData, linkedData, findingsData } = useSelector(
    (state) => state.organization,
  );
  const openedModal = useSelector((state) => state.modal.openedModal);

  const { orgId } = useParams();
  const sessionToken = localStorage.getItem("session_token");

  const organizationData = useSelector(
    (state) => state.user.organizations[orgId],
  );

  const risksArray = Object.values(risksData);
  const sortedRisks = _.sortBy(risksArray, "risk_id");

  const validationSchema = Yup.object().shape({
    description: Yup.string().required("Please provide a description."),
    reference: Yup.string().required("Reference cannot be empty."),
    chapter: Yup.string().required("Please select an associated chapter."),
    risk: Yup.string().required("Please select an associated risk."),
    attachments: Yup.array()
      .of(Yup.string())
      .required("File upload is required"),
  });

  let chapterOptions = sortedRisks.map((risk) => ({
    id: risk.qms_id,
    label: risk.qms_id + " " + risk.qms_description,
  }));

  chapterOptions = _.uniqBy(chapterOptions, "id");

  const riskOptions = sortedRisks.map((risk) => ({
    id: risk.risk_num,
    reference: risk.risk_id,
    label: `${risk.risk_id} ${risk.description}`,
  }));

  const handleCloseModal = (dirty, resetForm) => {
    // Check if there are unsaved changes
    if (dirty) {
      setShowConfirmationPopup(true); // Show confirmation if there are unsaved changes
    } else {
      // If there are no unsaved changes, check if opened from the audits modal
      if (openedModal?.data?.id) {
        // Close the new finding modal and reopen the audits modal
        dispatch(hideModal()); // Close the new finding modal

        // Ensure that the id is always at least 4 digits
        const auditId = String(openedModal.data.id).padStart(4, "0"); // For example, pads '768' to '0768'
        const formattedKey = `A-${auditId}`;

        // Reopen the audits modal with the previous data
        const updatedAuditData =
          store.getState().organization.auditsData[formattedKey];

        if (updatedAuditData) {
          reopenAuditModal(updatedAuditData); // Reopen the audits modal
        }
      } else {
        // Otherwise, just close the modal without reopening anything
        resetForm();
        dispatch(hideModal());
        setChecklist({});
      }
    }
  };

  const handleConfirmClose = (resetForm) => {
    setShowConfirmationPopup(false);

    if (openedModal?.data?.id) {
      // Close the new finding modal and reopen the audits modal
      resetForm();
      dispatch(hideModal()); // Close the new finding modal

      // Reopen the audits modal with the previous data
      const updatedAuditData =
        store.getState().organization.auditsData[`A-${openedModal.data.id}`];

      setTimeout(() => {
        if (updatedAuditData) {
          reopenAuditModal(updatedAuditData); // Reopen the audits modal with the previous data
        }
      }, 200); // Delay to allow the Redux store to update
    } else {
      // Else, just close the modal
      resetForm(); // Reset form values
      dispatch(hideModal());
      setChecklist({});
    }
  };

  const handleCancel = () => {
    setShowConfirmationPopup(false);
  };

  const formatDate = (inputDate) => {
    if (!inputDate) {
      return new Date().toISOString().split("T")[0];
    }
    return new Date(inputDate).toISOString().split("T")[0];
  };

  // Function to reopen the audit modal with a timeout
  const reopenAuditModal = (updatedAuditData) => {
    setTimeout(() => {
      dispatch(
        showModal({
          name: "audits_modal",
          data: updatedAuditData, // Reopen the audit modal with the previous data
        }),
      );
    }, 100);
  };

  const handleSubmit = async (values, actions) => {
    const selectedRisk = riskOptions.find((risk) => risk.label === values.risk);
    const riskNumToSend = selectedRisk ? selectedRisk.id : null;

    if (!riskNumToSend) {
      actions.setSubmitting(false);
      setLoading(false);
      return;
    }

    const valuesToSend = {
      ...values,
      date: formatDate(values.date),
      risk: riskNumToSend,
    };

    const options = {
      method: "POST",
      url: `/nocodb/findings?org_id=${orgId}`,
      headers: { "session-token": sessionToken },
      data: [valuesToSend],
    };

    try {
      const response = await axiosInstance.request(options);
      const findingId = response.data[0]; // Get the new finding ID

      // Check if openedModal?.data?.id exists
      if (openedModal?.data?.id) {
        const linkOptions = {
          method: "POST",
          url: `/nocodb/link_findings?org_id=${orgId}&link_target=AuditTask&target_id=${openedModal.data.id}`,
          headers: { "session-token": sessionToken },
          data: [findingId],
        };

        await axiosInstance.request(linkOptions);

        // Call getOrganizationData to refresh the organization data
        await dispatch(
          getOrganizationData(
            orgId,
            sessionToken,
            navigate,
            false,
            true,
            false,
            true,
            false,
            false,
          ),
        );

        setTimeout(() => {
          // After refreshing organization data, fetch the updated audit data
          const updatedAuditData =
            store.getState().organization.auditsData[
              `A-${openedModal.data.id}`
            ];
          const newFinding =
            store.getState().organization.findingsData[findingId]; // Fetch the new finding from the updated state

          if (newFinding) {
            // Update linkedData by appending the new finding
            const updatedLinkedFindings = [
              ...(updatedAuditData.linked_findings || []), // Keep existing linked findings
              newFinding, // Add the newly created finding
            ];

            // updated linkedData in Redux with the new finding
            dispatch(
              setLinkedData({
                ...store.getState().organization.linkedData,
                linked_findings: updatedLinkedFindings, // Append the new finding
              }),
            );
          }

          // Close the new finding modal and reopen the audit modal
          dispatch(hideModal());
          if (updatedAuditData) {
            reopenAuditModal(updatedAuditData); // Reopen the audit modal with updated data
          }
        }, 300);
      } else {
        // If openedModal.data.id is undefined, refresh organization data with different parameters
        await dispatch(
          getOrganizationData(
            orgId,
            sessionToken,
            navigate,
            false,
            true,
            false,
            false,
            false,
            false,
          ),
        );

        dispatch(hideModal());
      }

      actions.resetForm(); // Reset the Formik form
    } catch (error) {
      console.error("Error adding finding:", error);
    } finally {
      setLoading(false);
      actions.setSubmitting(false);
    }
  };

  const handleDescriptionBlur = async (descriptionValue, setFieldValue) => {
    if (!descriptionValue) return;
    // No need to set local state, as Formik will handle description value

    try {
      const findingsChecks = organizationData?.FindingsChecks || [];
      const params = new URLSearchParams();
      params.append("description", descriptionValue);

      findingsChecks.forEach((question) => {
        params.append("questions", question);
      });

      const requestUrl = `/ai/finding_check?${params.toString()}`;
      const response = await axiosInstance.get(requestUrl);

      if (response.data.chapter) {
        setFieldValue("chapter", response.data.chapter);
      }

      if (response.data.checklist) {
        setChecklist(response.data.checklist);
      }
    } catch (error) {
      console.error("Error checking description:", error);
    }
  };

  const ChecklistUI = ({ checklist }) => {
    // Check if all items in the checklist are answered
    const hasUnansweredItems = Object.values(checklist).some(
      (item) => item.check === false,
    );

    // If all items are answered, don't render the checklist
    if (!hasUnansweredItems) return null;

    return (
      <div className={styles.checklistContainer}>
        <div className={styles.title}>Description Improvement Suggestions:</div>
        <ul>
          {Object.entries(checklist).map(([question, { check, answer }]) => (
            <div
              key={question}
              className={classNames(
                styles.list,
                check ? styles.checked : styles.unchecked,
              )}
            >
              <div className={styles.question}>{question} :</div>
              <div className={styles.answer}>
                {check ? "Answered" : "Needs more description"}
              </div>
            </div>
          ))}
        </ul>
      </div>
    );
  };

  // The prefilled chapter value from the modal or API
  const prefilledRisk = openedModal?.data?.risk
    ? `${openedModal.data.risk.risk_id} ${openedModal.data.risk.description}`
    : "";

  const prefilledChapter =
    prefilledChapterFromAPI ||
    (openedModal?.data?.risk
      ? `${openedModal.data.risk.qms_id} ${openedModal.data.risk.qms_description}`
      : "");

  return (
    <Formik
      innerRef={formikRef}
      initialValues={{
        description: openedModal?.data?.description || "",
        date: "",
        reference: "",
        attachments: [],
        risk: prefilledRisk,
        status: "Done",
        chapter: prefilledChapter,
      }}
      validationSchema={validationSchema}
      onSubmit={(values, actions) => {
        setLoading(true);
        handleSubmit(values, actions);
      }}
      enableReinitialize={true}
    >
      {({ values, dirty, setFieldValue, resetForm }) => {
        // Extract the chapter id from values.chapter
        const chapterId = values.chapter.split(" ")[0]; // Get the first part of the string before the first space

        // Filter riskOptions based on the extracted chapterId
        const filteredRiskOptions = riskOptions.filter((risk) =>
          risk.reference.startsWith(chapterId),
        );

        return (
          <>
            <Modal
              name="new_finding_modal"
              title="New Finding"
              onClose={() => handleCloseModal(dirty, resetForm)}
              width="55vw"
            >
              <Form className={styles.content}>
                <div className={styles.description}>
                  <label htmlFor="description">Description</label>
                  <Field
                    name="description"
                    component={Textarea}
                    onBlur={(e) =>
                      handleDescriptionBlur(
                        e.target.value,
                        formikRef.current.setFieldValue,
                      )
                    }
                  />
                </div>
                {checklist && <ChecklistUI checklist={checklist} />}
                <div className={styles.row}>
                  <div className={styles.date}>
                    <label htmlFor="date">Date</label>
                    <Field
                      name="date"
                      component={DatePickerField}
                      validateOnBlur={true}
                      validateOnChange={false}
                    />
                  </div>
                  <div className={styles.reference}>
                    <label htmlFor="reference">Reference</label>
                    <Field name="reference" component={Input} />
                  </div>
                </div>
                <div>
                  <FormikCheckbox name="status" label="Needs follow-up?" />
                </div>
                <div className={styles.attachment}>
                  <label htmlFor="attachments">Attachments</label>
                  <Field
                    name="attachments"
                    component={FormikUpload}
                    greenIcon
                  />
                </div>
                <div className={styles.row}>
                  <div className={styles.chapter}>
                    <label htmlFor="chapter">Associated Chapter</label>
                    <Field
                      name="chapter"
                      component={Select}
                      options={chapterOptions}
                      text="Select chapter"
                      onClick={() => {
                        setFieldValue("risk", "");
                      }}
                    />
                  </div>

                  <div className={styles.risk}>
                    <label htmlFor="risk">Associated Risk</label>
                    <Field
                      name="risk"
                      component={Select}
                      options={filteredRiskOptions}
                      text="Select risk"
                      disabled={values.chapter === ""}
                    />
                  </div>
                </div>
                <Button
                  text={loading ? "Adding..." : "Add New Finding"}
                  type="submit"
                  className={styles.submitButton}
                  disabled={loading}
                />
              </Form>
            </Modal>
            {showConfirmationPopup && (
              <ConfirmationPopup
                onConfirmClose={() => handleConfirmClose(resetForm)}
                onCancel={handleCancel}
              />
            )}
          </>
        );
      }}
    </Formik>
  );
};

export default NewFindingModal;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LoaderOvarlay_loaderOverlay__ynkC\\+ {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.LoaderOvarlay_loaderGif__CDDHl {
  width: 30vw;
  height: 30vh;
  object-fit: contain;
}`, "",{"version":3,"sources":["webpack://./src/components/LoaderOverlay/LoaderOvarlay.module.scss"],"names":[],"mappings":"AACA;EACE,eAAA;EACA,MAAA;EACA,OAAA;EACA,YAAA;EACA,aAAA;EACA,oCAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,aAAA;AAAF;;AAGA;EACE,WAAA;EACA,YAAA;EACA,mBAAA;AAAF","sourcesContent":["\n.loaderOverlay {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100vw;\n  height: 100vh;\n  background: rgba(255, 255, 255, 0.8);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  z-index: 9999;\n}\n\n.loaderGif {\n  width: 30vw;\n  height: 30vh;\n  object-fit: contain;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loaderOverlay": `LoaderOvarlay_loaderOverlay__ynkC+`,
	"loaderGif": `LoaderOvarlay_loaderGif__CDDHl`
};
export default ___CSS_LOADER_EXPORT___;

import React from "react";
import styles from "./Modal.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { hideModal } from "../../redux/modalStore";
import OutsideClickHandler from "react-outside-click-handler";
import { ReactComponent as CloseIcon } from "../../icons/cross-yellow.svg";

const Modal = ({
  name,
  children,
  title,
  width,
  minWidth,
  maxWidth,
  height,
  onClose,
  isPopupOpen,
  headerAction,
}) => {
  const openedModal = useSelector((state) => state.modal.openedModal);
  const dispatch = useDispatch();

  const handleCloseModal = () => {
    if (onClose) {
      onClose(); // Only trigger the confirmation popup
      return; // Prevent further execution so that hideModal isn't called immediately
    }

    // Keep this here if there is no popup (other usages), so modal still closes as normal
    if (!isPopupOpen) {
      dispatch(hideModal());
    }
  };

  return openedModal?.name === name ? (
    <div className={styles.modal}>
      <OutsideClickHandler
        onOutsideClick={handleCloseModal}
        disabled={isPopupOpen}
      >
        <div
          className={styles.header}
          style={{
            width: width,
            minWidth: minWidth,
            maxWidth: maxWidth,
          }}
        >
          <div className={styles.title}>{title}</div>
          <div className={styles.rightSide}>
            {headerAction && <div>{headerAction}</div>}
            <CloseIcon className={styles.icon} onClick={handleCloseModal} />
          </div>
        </div>
        <div
          className={styles.box}
          style={{
            width: width,
            minWidth: minWidth,
            maxWidth: maxWidth,
            height: height,
          }}
        >
          <div className={styles.children}>{children}</div>
        </div>
      </OutsideClickHandler>
    </div>
  ) : (
    ""
  );
};

export default Modal;

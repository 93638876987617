import React, { useState, useEffect } from "react";
import {
  getColor,
  generateCumulativeTimeSeries,
  getPast12Months,
} from "../../utils/helpers";
import styles from "./GraphAccordion.module.scss";
import { Tooltip } from "react-tooltip";
import { useSelector } from "react-redux";
import LineGraph from "../LineGraph/LineGraph";
import DownArrowIcon from "../../icons/down-arrow.svg";
import UpArrowIcon from "../../icons/up-arrow.svg";
import OutsideClickHandler from "react-outside-click-handler";

const GraphAccordion = ({
  findingsData,
  improvementsData,
  onAccordionToggle,
}) => {
  const [mainChaptersData, setMainChaptersData] = useState({
    chapters: {},
    globalMin: Infinity,
    globalMax: -Infinity,
  });
  const [openGroup, setOpenGroup] = useState(null);

  const { risksData } = useSelector((state) => state.organization);

  useEffect(() => {
    const groupedData = generateCumulativeTimeSeries(
      findingsData,
      improvementsData,
    );

    // Structure to group sub-chapters under their main chapters
    const chapters = {};
    let globalMin = Infinity;
    let globalMax = -Infinity;

    Object.keys(groupedData).forEach((subChapter) => {
      const mainChapter = subChapter.split("-")[0]; // Extract main chapter
      if (!chapters[mainChapter]) {
        chapters[mainChapter] = {
          subChapters: [],
          cumulativeData: groupedData[subChapter].map((monthData) => ({
            ...monthData,
            count: 0,
          })),
        };
      }
      chapters[mainChapter].subChapters.push({
        key: subChapter,
        data: groupedData[subChapter],
      });

      // Add up counts for the main chapter legend
      groupedData[subChapter].forEach((monthData, index) => {
        const count = monthData.count;
        chapters[mainChapter].cumulativeData[index].count += count;

        // Update global min and max
        if (count < globalMin) globalMin = count;
        if (count > globalMax) globalMax = count;
      });
    });

    setMainChaptersData({ chapters, globalMin, globalMax });
  }, [findingsData, improvementsData]);

  // Get past 12 months for headers
  const months = getPast12Months();

  const toggleAccordion = (mainChapterKey) => {
    const isOpen = openGroup === mainChapterKey ? null : mainChapterKey;
    setOpenGroup(isOpen);

    // Call the callback function passed from the parent component
    if (onAccordionToggle) {
      onAccordionToggle(isOpen); // Pass the opened chapter key to the parent
    }
  };

  // Handle outside click to close the accordion
  const handleOutsideClick = () => {
    if (openGroup !== null) {
      setOpenGroup(null);
      if (onAccordionToggle) {
        onAccordionToggle(null); // Close accordion and update openChapter
      }
    }
  };

  // Function to filter out duplicate improvements based on their key
  const uniqueImprovements = (improvements) => {
    const seenKeys = new Set();
    return improvements.filter((improvement) => {
      if (seenKeys.has(improvement.key)) {
        return false;
      } else {
        seenKeys.add(improvement.key);
        return true;
      }
    });
  };

  return (
    <OutsideClickHandler onOutsideClick={handleOutsideClick}>
      <div className={styles.graphAccordion}>
        {Object.keys(mainChaptersData.chapters).map((mainChapterKey) => {
          const { subChapters, cumulativeData } =
            mainChaptersData.chapters[mainChapterKey];

          // Finding the corresponding qms_description for the main chapter
          const matchingRisk = Object.values(risksData).find(
            (risk) => risk.qms_id === mainChapterKey,
          );
          const riskDescription = matchingRisk
            ? matchingRisk.qms_description
            : "";

          const min = mainChaptersData.globalMin;
          const max = mainChaptersData.globalMax;

          return (
            <div key={mainChapterKey} className={styles.accordionItem}>
              <div
                className={styles.accordionHeader}
                onClick={() => toggleAccordion(mainChapterKey)}
              >
                <div className={styles.legend}>
                  {cumulativeData.map((item, index) => (
                    <div
                      key={index}
                      className={styles.monthSquare}
                      data-tooltip-id="month-tooltip"
                      data-tooltip-html={`<strong>Month:</strong> ${months[index]} | <strong>RPS:</strong> ${item.count}`}
                      style={{
                        backgroundColor: getColor(item.count, min, max),
                      }}
                    ></div>
                  ))}
                  <div className={styles.name}>
                    {mainChapterKey}{" "}
                    {riskDescription && ` - ${riskDescription}`}
                  </div>
                  {openGroup === mainChapterKey ? (
                    <img
                      src={UpArrowIcon}
                      alt="Up Arrow Icon"
                      className={styles.icon}
                    />
                  ) : (
                    <img
                      src={DownArrowIcon}
                      alt="Down Arrow Icon"
                      className={styles.icon}
                    />
                  )}
                </div>
              </div>
              {openGroup === mainChapterKey && (
                <div className={styles.accordionBody}>
                  {subChapters.map(
                    ({ key: subChapterKey, data: subChapterData }) => {
                      const minSub = Math.min(
                        ...subChapterData.map((item) => item.count),
                      );
                      const maxSub = Math.max(
                        ...subChapterData.map((item) => item.count),
                      );

                      return (
                        <div key={subChapterKey} className={styles.graph}>
                          <div className={styles.subChapterTitle}>
                            {subChapterKey}
                          </div>
                          <LineGraph
                            data={subChapterData.map((item, index) => {
                              return {
                                month: months[index],
                                count: item.count,
                                relatedFindings: item.relatedFindings || [], // Add related findings
                                relatedImprovements: uniqueImprovements(
                                  item.relatedImprovements || [],
                                ), // Add related improvements
                              };
                            })}
                            months={months}
                          />
                          <div className={styles.legend}>
                            {subChapterData.map((item, index) => (
                              <div
                                key={index}
                                className={styles.monthSquare}
                                data-tooltip-id="month-tooltip"
                                data-tooltip-content={`Month: ${months[index]} | Count: ${item.count}`}
                                style={{
                                  backgroundColor: getColor(
                                    item.count,
                                    minSub,
                                    maxSub,
                                  ),
                                }}
                              ></div>
                            ))}
                          </div>
                        </div>
                      );
                    },
                  )}
                </div>
              )}
            </div>
          );
        })}
        <Tooltip id="month-tooltip" place="top" className={styles.tooltip} />
      </div>
    </OutsideClickHandler>
  );
};

export default GraphAccordion;

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../utils/utils";
import { setUserData } from "../redux/userStore";
import { getOrganizationData } from "./organization";
import { setLoadingContext } from "../redux/loaderStore";
import { PAGE_LOADER } from "../constants/loader";
import { showModal } from "../redux/modalStore";

const useFetchOrganizationData = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { orgId } = useParams();

  const sessionToken = localStorage.getItem("session_token");
  const currentOrganizationId = useSelector(
    (state) => state.user.organizations[orgId]?.UUID,
  );

  useEffect(() => {
    if (orgId && currentOrganizationId !== orgId) {
      // Organization data needs to be refreshed
      dispatch(setLoadingContext({ context: PAGE_LOADER, isLoading: true }));

      dispatch(getOrganizationData(orgId, sessionToken, navigate, false))
        .then(() => {
          const refreshQuery = "&refresh=true";
          return axiosInstance
            .request({
              method: "GET",
              url: `/auth/session?session_token=${sessionToken}${refreshQuery}`,
            })
            .then((response) => {
              dispatch(setUserData(response.data));
              localStorage.setItem("last_refresh_timestamp", Date.now());
            })
            .catch(() => {
              dispatch(showModal({ name: "error_modal" }));
            });
        })
        .finally(() => {
          dispatch(
            setLoadingContext({ context: PAGE_LOADER, isLoading: false }),
          );
        });
    }
  }, [orgId, currentOrganizationId, dispatch, sessionToken, navigate]);
};

export default useFetchOrganizationData;

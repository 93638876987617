import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./core/App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store, { persistor } from "./core/store";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { PersistGate } from "redux-persist/integration/react";
import * as Sentry from "@sentry/react";

// Initialize Sentry
Sentry.init({
  dsn: "https://06544af6dd55e7aef00bceeaee17752b@o4508721051205632.ingest.de.sentry.io/4508722673680464",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  tracePropagationTargets: [
    "localhost",
    /^https:\/\/dev\.border\.ly\/api/, // Dev API
    /^https:\/\/border\.ly\/api/, // Production API
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // 10% sample rate during development
  replaysOnErrorSampleRate: 1.0, // Always sample errors
  // Dynamically set the environment
  environment:
    process.env.REACT_APP_SENTRY_ENVIRONMENT ||
    (window.location.hostname.includes("dev")
      ? "dev"
      : window.location.hostname.includes("localhost")
        ? "local-isidora"
        : "production"),
});

const root = ReactDOM.createRoot(document.getElementById("root"));
const queryClient = new QueryClient();

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </QueryClientProvider>
  </React.StrictMode>,
);

reportWebVitals();

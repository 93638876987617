import React, { useState } from "react";
import { Field } from "formik";
import classNames from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import DownArrowIcon from "../../icons/down-arrow.svg";
import UpArrowIcon from "../../icons/up-arrow.svg";
import CheckIcon from "../../icons/check-thin.svg";
import styles from "./IconSelect.module.scss";

const IconSelect = ({
  options,
  name,
  disabled,
  placeholder = "Select an option",
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Field name={name}>
      {({ field, form }) => {
        const selectedOption = options.find(
          (option) => option.value === field.value,
        );

        const handleSelect = (option) => {
          form.setFieldValue(name, option.value); // Set the value
          setIsOpen(false);
        };

        return (
          <OutsideClickHandler onOutsideClick={() => setIsOpen(false)}>
            <div className={styles.iconSelectWrapper}>
              <div
                className={classNames(
                  styles.iconSelect,
                  disabled && styles.disabled,
                  form.errors[name] && form.touched[name] && styles.error,
                )}
                onClick={() => !disabled && setIsOpen(!isOpen)}
              >
                <div className={styles.labelWithIcon}>
                  {/* Display icon for the selected option */}
                  {selectedOption?.icon && (
                    <img
                      src={selectedOption.icon}
                      alt={selectedOption.label}
                      className={styles.icon}
                    />
                  )}
                  <span>
                    {selectedOption ? selectedOption.label : placeholder}
                  </span>
                </div>
                {/* Dropdown arrow */}
                <img
                  src={isOpen ? UpArrowIcon : DownArrowIcon}
                  alt="Toggle Dropdown"
                  className={styles.arrowIcon}
                />
              </div>
              {isOpen && (
                <div
                  className={classNames(
                    styles.options,
                    styles.overlay, // Ensure dropdown overlays other elements
                  )}
                >
                  {options.map((option) => (
                    <div
                      key={option.value}
                      className={classNames(
                        styles.option,
                        option.value === field.value && styles.selected,
                      )}
                      onClick={() => handleSelect(option)}
                    >
                      <div className={styles.labelWithIcon}>
                        {/* Display icon for each option */}
                        {option.icon && (
                          <img
                            src={option.icon}
                            alt={option.label}
                            className={styles.icon}
                          />
                        )}
                        <span>{option.label}</span>
                      </div>
                      {/* Tick icon for selected option */}
                      {option.value === field.value && (
                        <img
                          src={CheckIcon}
                          alt="Selected"
                          className={styles.tickIcon}
                        />
                      )}
                    </div>
                  ))}
                </div>
              )}
              {form.errors[name] && form.touched[name] && (
                <div className={styles.errorMessage}>{form.errors[name]}</div>
              )}
            </div>
          </OutsideClickHandler>
        );
      }}
    </Field>
  );
};

export default IconSelect;

import React, { useEffect, useState } from "react";
import styles from "./AuditCalendar.module.scss";
import classNames from "classnames";
import { ReactComponent as ArrowNextIcon } from "../../icons/arrow-right.svg";
import { ReactComponent as ArrowPreviousIcon } from "../../icons/arrow-previous.svg";
import { generateDates } from "../../utils/helpers";

const AuditCalendar = ({ startDate, endDate, frequency }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  // Reset currentIndex when frequency changes
  useEffect(() => {
    setCurrentIndex(0);
  }, [frequency]);

  // Return early if required props are missing or invalid
  if (!startDate || !endDate || !frequency) {
    return null;
  }

  // Validate dates
  const start = new Date(startDate);
  const end = new Date(endDate);

  if (isNaN(start.getTime()) || isNaN(end.getTime()) || start > end) {
    return (
      <div className={styles.calendarWrapper}>Invalid date range selected</div>
    );
  }

  const scheduledDates = generateDates(startDate, endDate, frequency);

  // Check if the frequency is quarterly or greater
  const isYearView = ["quarterly", "half-yearly", "yearly"].includes(
    frequency.toLowerCase(),
  );

  if (isYearView) {
    // Group dates by year
    const datesByYear = {};
    scheduledDates.forEach((dateStr) => {
      const date = new Date(dateStr);
      const year = date.getFullYear();

      if (!datesByYear[year]) {
        datesByYear[year] = [];
      }

      datesByYear[year].push(date);
    });

    const years = Object.keys(datesByYear)
      .map(Number)
      .sort((a, b) => a - b);
    const visibleYears = years.slice(currentIndex, currentIndex + 3);

    const handleNext = () => {
      if (currentIndex + 1 < years.length - 2) {
        setCurrentIndex(currentIndex + 1);
      }
    };

    const handlePrevious = () => {
      if (currentIndex - 1 >= 0) {
        setCurrentIndex(currentIndex - 1);
      }
    };

    const handleFirst = () => {
      setCurrentIndex(0);
    };

    const handleLast = () => {
      setCurrentIndex(Math.max(0, years.length - 3));
    };

    // Format the day of week and date
    const formatDateDisplay = (date) => {
      const dayOfWeek = date
        .toLocaleString("default", { weekday: "long" })
        .toUpperCase();
      const dayOfMonth = date.getDate();
      const month = date
        .toLocaleString("default", { month: "long" })
        .toUpperCase();
      return `${dayOfWeek} ${dayOfMonth} ${month}`;
    };

    return (
      <div className={styles.container}>
        <div className={styles.calendarContainer}>
          <ArrowPreviousIcon
            className={classNames(styles.navButton, styles.leftArrow, {
              [styles.disabled]: currentIndex === 0,
            })}
            onClick={handlePrevious}
            disabled={currentIndex === 0}
          />
          <div className={styles.calendarWrapper}>
            {visibleYears.map((year) => (
              <div key={year} className={styles.yearContainer}>
                <div className={styles.year}>{year}</div>
                <div className={styles.datesList}>
                  {datesByYear[year].map((date, index) => (
                    <div key={index} className={styles.dateButton}>
                      {formatDateDisplay(date)}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
          <ArrowNextIcon
            className={classNames(styles.navButton, styles.rightArrow, {
              [styles.disabled]: currentIndex + 3 >= years.length,
            })}
            onClick={handleNext}
            disabled={currentIndex + 3 >= years.length}
          />
        </div>
        <div className={styles.footerControls}>
          <button
            onClick={handleFirst}
            disabled={currentIndex === 0}
            className={styles.footerButton}
          >
            Go to first
          </button>
          <button
            onClick={handleLast}
            disabled={currentIndex + 3 >= years.length}
            className={styles.footerButton}
          >
            Go to latest
          </button>
        </div>
      </div>
    );
  } else {
    // Original calendar view logic for frequencies less than quarterly
    const monthsToRender = [];
    let temp = new Date(startDate);
    const endDateTime = new Date(endDate).getTime();

    while (temp.getTime() <= endDateTime) {
      const year = temp.getFullYear();
      const month = temp.getMonth();

      // Check if there are scheduled dates in this month
      const hasScheduledDates = scheduledDates.some((date) => {
        const dateObj = new Date(date);
        return dateObj.getFullYear() === year && dateObj.getMonth() === month;
      });

      if (hasScheduledDates) {
        monthsToRender.push(new Date(temp));
      }

      temp.setMonth(temp.getMonth() + 1);
    }

    const visibleMonths = monthsToRender.slice(currentIndex, currentIndex + 3);

    const handleNext = () => {
      if (currentIndex + 1 < monthsToRender.length - 2) {
        setCurrentIndex(currentIndex + 1);
      }
    };

    const handlePrevious = () => {
      if (currentIndex - 1 >= 0) {
        setCurrentIndex(currentIndex - 1);
      }
    };

    const handleFirst = () => {
      setCurrentIndex(0);
    };

    const handleLast = () => {
      setCurrentIndex(Math.max(0, monthsToRender.length - 3));
    };

    return (
      <div className={styles.container}>
        <div className={styles.calendarContainer}>
          <ArrowPreviousIcon
            className={classNames(styles.navButton, styles.leftArrow, {
              [styles.disabled]: currentIndex === 0,
            })}
            onClick={handlePrevious}
            disabled={currentIndex === 0}
          />
          <div className={styles.calendarWrapper}>
            {visibleMonths.map((monthDate) => {
              const year = monthDate.getFullYear();
              const month = monthDate.getMonth();
              const firstDay = new Date(year, month, 1);
              const lastDay = new Date(year, month + 1, 0);
              const daysInMonth = lastDay.getDate();
              const monthName = monthDate.toLocaleString("default", {
                month: "long",
              });

              const firstDayIndex = (firstDay.getDay() + 6) % 7; // Shift Sunday to the last

              // **Previous month's days to fill the first row**
              const prevMonthLastDate = new Date(year, month, 0).getDate();
              const prevMonthYear = month === 0 ? year - 1 : year;
              const prevMonth = month === 0 ? 11 : month - 1;

              const prevMonthDays = [...Array(firstDayIndex)].map((_, i) => {
                const dayNum = prevMonthLastDate - firstDayIndex + i + 1;
                const dateObj = new Date(prevMonthYear, prevMonth, dayNum);
                const dateStr = `${dateObj.getFullYear()}-${String(dateObj.getMonth() + 1).padStart(2, "0")}-${String(dateObj.getDate()).padStart(2, "0")}`;
                const isScheduled = scheduledDates.includes(dateStr);

                return (
                  <div
                    key={`prev-${dayNum}`}
                    className={classNames(styles.day, styles.outsideMonth, {
                      [styles.scheduled]: isScheduled,
                    })}
                  >
                    {dayNum}
                  </div>
                );
              });

              // **Next month's days to fill the last row**
              const nextMonthYear = month === 11 ? year + 1 : year;
              const nextMonth = month === 11 ? 0 : month + 1;
              const totalCells = firstDayIndex + daysInMonth;
              const nextMonthDays =
                totalCells % 7 === 0
                  ? []
                  : [...Array(7 - (totalCells % 7))].map((_, i) => {
                      const dayNum = i + 1;
                      // Create proper date object and format consistently
                      const dateObj = new Date(
                        nextMonthYear,
                        nextMonth,
                        dayNum,
                      );
                      const dateStr = `${dateObj.getFullYear()}-${String(dateObj.getMonth() + 1).padStart(2, "0")}-${String(dateObj.getDate()).padStart(2, "0")}`;
                      const isScheduled = scheduledDates.includes(dateStr);

                      return (
                        <div
                          key={`next-${dayNum}`}
                          className={classNames(
                            styles.day,
                            styles.outsideMonth,
                            {
                              [styles.scheduled]: isScheduled,
                            },
                          )}
                        >
                          {dayNum}
                        </div>
                      );
                    });

              return (
                <div key={`${year}-${month}`} className={styles.monthContainer}>
                  <div className={styles.month}>
                    {monthName} {year}
                  </div>
                  <div className={styles.calendar}>
                    <div className={styles.weekdays}>
                      {["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"].map((day) => (
                        <div key={day} className={styles.weekday}>
                          {day}
                        </div>
                      ))}
                    </div>
                    <div className={styles.days}>
                      {prevMonthDays}
                      {[...Array(daysInMonth)].map((_, day) => {
                        const dateObj = new Date(year, month, day + 1);
                        const dateStr = `${dateObj.getFullYear()}-${String(dateObj.getMonth() + 1).padStart(2, "0")}-${String(dateObj.getDate()).padStart(2, "0")}`;
                        return (
                          <div
                            key={dateStr}
                            className={classNames(styles.day, {
                              [styles.scheduled]:
                                scheduledDates.includes(dateStr),
                            })}
                          >
                            {day + 1}
                          </div>
                        );
                      })}
                      {nextMonthDays}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <ArrowNextIcon
            className={classNames(styles.navButton, styles.rightArrow, {
              [styles.disabled]: currentIndex + 3 >= monthsToRender.length,
            })}
            onClick={handleNext}
            disabled={currentIndex + 3 >= monthsToRender.length}
          />
        </div>
        <div className={styles.footerControls}>
          <button
            onClick={handleFirst}
            disabled={currentIndex === 0}
            className={styles.footerButton}
          >
            Go to first
          </button>
          <button
            onClick={handleLast}
            disabled={currentIndex + 3 >= monthsToRender.length}
            className={styles.footerButton}
          >
            Go to latest
          </button>
        </div>
      </div>
    );
  }
};

export default AuditCalendar;

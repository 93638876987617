import React from "react";
import styles from "./SmallConfirmationPopup.module.scss";
import Button from "../../components/Button/Button";

const SmallConfirmationPopup = ({
  message,
  confirmText,
  cancelText,
  onConfirm,
  onCancel,
}) => {
  return (
    <div className={styles.overlay}>
      <div className={styles.modal}>
        <p className={styles.message}>{message}</p>
        <div className={styles.buttonGroup}>
          <Button
            text={cancelText || "Cancel"}
            onClick={onCancel}
            className={styles.cancelButton}
          />
          <Button
            text={confirmText || "Delete"}
            onClick={onConfirm}
            className={styles.confirmButton}
          />
        </div>
      </div>
    </div>
  );
};

export default SmallConfirmationPopup;

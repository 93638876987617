import React from "react";
import styles from "./AuthLayout.module.scss";
import BorderlyLogo from "../../icons/borderly-plus-white-logo.svg";
import ErrorModal from "../../modals/ErrorModal";
import Button from "../../components/Button/Button";
import { updateLoggedIn } from "../../redux/authStore";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

const AuthLayout = ({ children }) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const handleLogOut = () => {
    localStorage.removeItem("session_token");
    dispatch(updateLoggedIn(false));
  };

  return (
    <div className={styles.background}>
      {/* Show the logout button only if the current path is not '/login' */}
      {location.pathname !== "/login" && (
        <Button
          text="Log out"
          className={styles.button}
          onClick={handleLogOut}
        />
      )}
      <img src={BorderlyLogo} alt="Borderly Logo" className={styles.logo} />
      <div className={styles.box}>{children}</div>
      <ErrorModal />
    </div>
  );
};

export default AuthLayout;

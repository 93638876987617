import Router from "./Router";
import { BrowserRouter } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import * as Sentry from "@sentry/react";

Sentry.captureException(new Error("Test Sentry Integration"));

const App = () => {
  return (
    <BrowserRouter>
      <Tooltip id="tooltip" />
      <Router />
    </BrowserRouter>
  );
};

export default App;

import React from "react";
import classNames from "classnames";
import styles from "./RadioGroup.module.scss";

const RadioGroup = ({ options, selectedValue, onChange }) => {
  const handleSelectOption = (value) => {
    onChange({
      target: {
        value,
      },
    });
  };

  return (
    <div className={styles.wrapper}>
      {options.map((option) => (
        <div
          key={option.id}
          className={classNames(styles.optionItem, {
            [styles.selected]: selectedValue === option.value,
          })}
          onClick={() => handleSelectOption(option.value)}
        >
          <div className={styles.radio}>
            <div
              className={classNames(styles.radioInner, {
                [styles.radioChecked]: selectedValue === option.value,
              })}
            />
          </div>
          <span>{option.label}</span>
        </div>
      ))}
    </div>
  );
};

export default RadioGroup;

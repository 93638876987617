import React, { useEffect, useState } from "react";
import styles from "./Audits.module.scss";
import Table from "../../components/Table/Table";
import MultiSelect from "../../components/MultiSelect/MultiSelect";
import "react-datepicker/dist/react-datepicker.css";
import Input from "../../components/Input/Input";
import classNames from "classnames";
import { showModal } from "../../redux/modalStore";
import { useDispatch, useSelector } from "react-redux";
import AuditsModal from "../../modals/AuditsModal";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import MainLayout from "../../layouts/MainLayout/MainLayout";
import SearchIcon from "../../icons/search.svg";
import PageLoader from "../../components/Loader/PageLoader";
import { AUDITS_LOADER } from "../../constants/loader";
import { formatDate, handleExport } from "../../utils/helpers";
import _ from "lodash";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Separator from "../../components/Separator/Separator";
import { setLinkedData } from "../../redux/organizationStore";
import DateRangeFilter from "../../components/DateRangeFilter/DateFIlterRange";
import useFetchOrganizationData from "../../actions/useFetchOrganizationData";
import Button from "../../components/Button/Button";
import ArchiveIcon from "../../icons/archive.svg";
import ExportIcon from "../../icons/export.svg";
import axiosInstance from "../../utils/utils";
import { getOrganizationData } from "../../actions/organization";
import Swal from "sweetalert2";
import BulkEditModal from "../../modals/BulkEditModal/BulkEditModal";
import EditIcon from "../../icons/edit.svg";
import {
  clearResetSelection,
  resetSelection,
} from "../../redux/rowSelectionStore";
import ConfirmationPopup from "../../modals/ConfirmationPopup";
import ArchiveIllustration from "../../icons/archive-illustration.svg";
import { hideOverlay, showOverlay } from "../../redux/overlayLoaderStore";

const Audits = () => {
  const dispatch = useDispatch();
  const { orgId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const sessionToken = localStorage.getItem("session_token");

  const [selectedChapters, setSelectedChapters] = useState([]);
  const [selectedNames, setSelectedNames] = useState([]);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [selectedResponsibles, setSelectedResponsibles] = useState([]);
  const [selectedFrequencies, setSelectedFrequencies] = useState([]);
  const [dateRange, setDateRange] = useState([null, null]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedIssueData, setSelectedIssueData] = useState(null);
  const [selectedAudits, setSelectedAudits] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingAction, setLoadingAction] = useState("");
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);

  const { loadingContexts } = useSelector((state) => state.loader);
  const modules = useSelector((state) => state.user.modules[orgId]);
  const { meModeActive } = useSelector((state) => state.filters);
  const currentUser = useSelector((state) => state.user.email);
  const { auditsData, data } = useSelector((state) => state.organization);
  const openedModal = useSelector((state) => state.modal.openedModal);
  const resetRowSelection = useSelector(
    (state) => state.rowSelection.resetSelection,
  );

  const isLoading = loadingContexts[AUDITS_LOADER];

  useFetchOrganizationData();

  // Extracting path from URL
  const lastIndex = location.pathname.lastIndexOf("/");
  const modifiedPath = location.pathname.substring(lastIndex);

  // Find the module object corresponding to the current path
  const currentModule = modules?.find((mod) => mod.path === modifiedPath);

  const uniqueChapters = auditsData
    ? _.uniqBy(
        Object.values(auditsData).flatMap((issue) =>
          issue?.form?.map((form) => ({
            id: `${form?.risk?.qms_id}-${form?.risk?.qms_description}`,
            label: `${form?.risk?.qms_id} - ${form?.risk?.qms_description}`,
          })),
        ),
        "id",
      )
    : [];

  const handleChapterFilterChange = (newSelectedValues) => {
    setSelectedChapters(newSelectedValues);
  };

  const auditNames = auditsData
    ? _.uniqBy(
        Object.values(auditsData).map((audit) => ({
          id: audit.name,
          label: audit.name,
        })),
        "id",
      )
    : [];

  const handleNameFilterChange = (newSelectedValues) => {
    setSelectedNames(newSelectedValues);
  };

  const uniqueStatuses = auditsData
    ? _.uniqBy(
        Object.values(auditsData).map((issue) => ({
          id: issue.status,
          label: issue.status,
        })),
        "id",
      )
    : [];

  const handleStatusFilterChange = (newSelectedValues) => {
    setSelectedStatuses(newSelectedValues);
  };

  const uniqueResponsibles = auditsData
    ? _.uniqBy(
        Object.values(auditsData).flatMap((issue) =>
          Array.isArray(issue.responsible)
            ? issue.responsible.map((res) => ({
                id: res,
                label: res,
              }))
            : issue.responsible
              ? [{ id: issue.responsible, label: issue.responsible }]
              : [],
        ),
        "id",
      )
    : [];

  const handleResponsibleFilterChange = (newSelectedValues) => {
    setSelectedResponsibles(newSelectedValues);
  };

  const uniqueFrequencies = auditsData
    ? _.uniqBy(
        Object.values(auditsData)
          .filter((item) => item.frequency)
          .map((item) => ({
            id: item.frequency,
            label:
              item.frequency.charAt(0).toUpperCase() + item.frequency.slice(1),
          })),
        "id",
      )
    : [];

  const handleFrequencyFilterChange = (newSelectedValues) => {
    setSelectedFrequencies(newSelectedValues);
  };

  const handleSelectedRowsChange = (selectedRows) => {
    setSelectedAudits(selectedRows);
  };

  const handleExportSelectedAudits = async () => {
    setLoading(true);
    setLoadingAction("export");

    await handleExport({
      selectedItems: selectedAudits,
      orgId,
      sessionToken,
      getKey: (item) => item.key,
      filePrefix: "Audit",
    });

    setLoading(false);
    setLoadingAction("");
  };

  const handleBulkArchive = async () => {
    setShowConfirmationPopup(false);

    if (!selectedAudits.length) return;

    const payload = selectedAudits.map((project) => ({
      id: project.id,
      archived: true,
    }));

    const archivedKeys = selectedAudits.map((project) => project.key);

    setLoading(true);
    setLoadingAction("archive");
    dispatch(showOverlay());

    try {
      await axiosInstance.patch(
        `/nocodb/audit_tasks?org_id=${orgId}`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            "session-token": sessionToken,
          },
        },
      );

      // Dispatch updated organization data
      await dispatch(
        getOrganizationData(
          orgId,
          sessionToken,
          navigate,
          false,
          false,
          false,
          true,
          false,
          false,
        ),
      );

      // Show success alert
      Swal.fire({
        toast: true,
        icon: "success",
        title: `Archive successful`,
        text: `Keys archived: ${archivedKeys.join(", ")}`,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });
    } catch (error) {
      Swal.fire({
        toast: true,
        icon: "error",
        title: `Archive failed`,
        text: `Could not archive audit tasks. Please try again.`,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });
    } finally {
      setLoading(false);
      setLoadingAction("");
      dispatch(hideOverlay()); // Hide overlay

      // Trigger row selection reset
      dispatch(resetSelection());
      setTimeout(() => dispatch(clearResetSelection()), 100); // Reset to false after a short delay
    }
  };

  const handleBulkEdit = () => {
    dispatch(
      showModal({
        name: "bulk_edit_modal",
        data: selectedAudits,
      }),
    );
  };

  const filteredData = auditsData
    ? Object.values(auditsData).filter((item) => {
        const {
          date,
          form,
          status,
          responsible,
          accountable,
          consulted,
          informed,
          key,
          name,
          frequency,
        } = item;

        const dueDate = new Date(date);
        const formattedStartDate = dateRange[0];
        const formattedEndDate = dateRange[1];

        const searchQueryLower = searchQuery.toLowerCase();

        const filters = [
          selectedChapters.length === 0 ||
            form.some((formItem) =>
              selectedChapters.includes(
                `${formItem.risk.qms_id}-${formItem.risk.qms_description}`,
              ),
            ),
          selectedNames.length === 0 || selectedNames.includes(name),
          selectedStatuses.length === 0 || selectedStatuses.includes(status),
          selectedResponsibles.length === 0 ||
            (Array.isArray(responsible)
              ? responsible.some((r) => selectedResponsibles.includes(r))
              : selectedResponsibles.includes(responsible)),
          selectedFrequencies.length === 0 ||
            selectedFrequencies.includes(frequency),
          (!formattedStartDate && !formattedEndDate) || // No date range filter applied
            (dueDate >= formattedStartDate && dueDate <= formattedEndDate),
          !searchQueryLower || // If there's no search query, ignore this filter
            key?.toLowerCase().includes(searchQueryLower) ||
            name?.toLowerCase().includes(searchQueryLower) ||
            frequency?.toLowerCase().includes(searchQueryLower) ||
            (Array.isArray(responsible)
              ? responsible.some((r) =>
                  r.toLowerCase().includes(searchQueryLower),
                )
              : responsible?.toLowerCase().includes(searchQueryLower)) ||
            form.some((formItem) =>
              `${formItem.risk.qms_id}-${formItem.risk.qms_description}`
                ?.toLowerCase()
                .includes(searchQueryLower),
            ),
          !meModeActive ||
            [responsible, accountable, consulted, informed].some((role) =>
              Array.isArray(role)
                ? role.includes(currentUser)
                : role === currentUser,
            ),
        ];

        return filters.every(Boolean);
      })
    : [];

  const getLinkedData = (audit) => {
    const { linked_findings = [] } = audit;

    const findings = linked_findings
      .map((key) => {
        const finding = data.findings?.[key];
        return finding;
      })
      .filter(Boolean); // Filter out any undefined values

    return { linked_findings: findings };
  };

  const handleRowClick = (row) => {
    const linkedData = getLinkedData(row.original);
    dispatch(setLinkedData(linkedData));
    setSelectedIssueData(row.original);
    dispatch(
      showModal({
        name: "audits_modal",
        data: row.original,
      }),
    );
  };

  const columns = [
    {
      accessorKey: "select",
      header: ({ table }) => (
        <div
          className={styles.customCheckboxContainer}
          onClick={(e) => {
            e.stopPropagation(); // Stop click event propagation
            table.toggleAllPageRowsSelected(!table.getIsAllPageRowsSelected());
          }}
        >
          {table.getIsAllPageRowsSelected() ? (
            <div className={styles.checked} />
          ) : (
            <div className={styles.unchecked} />
          )}
        </div>
      ),
      size: 50,
      cell: ({ row }) => (
        <div
          className={styles.customCheckboxContainer}
          onClick={(e) => {
            e.stopPropagation(); // Prevent row click
            row.toggleSelected();
          }}
        >
          {row.getIsSelected() ? (
            <div className={styles.checked} />
          ) : (
            <div className={styles.unchecked} />
          )}
        </div>
      ),
      enableSorting: false,
    },
    {
      accessorKey: "key",
      header: "Audit Key",
      cell: (props) => <div>{props.getValue()}</div>,
      enableSorting: false,
    },
    {
      accessorKey: "name",
      header: "Audit Name",
      size: 250,
      cell: (props) => <div>{props.getValue()}</div>,
      enableSorting: false,
    },
    {
      accessorKey: "responsible",
      header: "Responsible",
      size: 170,
      cell: (props) => {
        return props.row.original.responsible ? (
          <div>{props.row.original.responsible}</div>
        ) : (
          <div>Not defined</div>
        );
      },
      enableSorting: false,
    },
    {
      accessorKey: "status",
      header: "Status",
      minSize: 90,
      cell: (props) => {
        const status = props.getValue();
        let statusClassName = "";

        switch (status) {
          case "Open":
            statusClassName = styles.openStatus;
            break;
          case "In Progress":
            statusClassName = styles.inProgressStatus;
            break;
          case "In Review":
            statusClassName = styles.inReviewStatus;
            break;
          case "Done":
            statusClassName = styles.resolvedStatus;
            break;
          default:
            break;
        }

        return (
          <div className={classNames(styles.statusCell, statusClassName)}>
            {status}
          </div>
        );
      },
      enableSorting: false,
    },
    {
      accessorKey: "date",
      header: "Due Date",
      cell: (props) => <div>{formatDate(props.getValue())}</div>,
      sortingFn: "datetime",
    },
    {
      accessorKey: "frequency",
      header: "Frequency",
      cell: (props) => (
        <div>
          {props.row.original?.frequency?.charAt(0).toUpperCase() +
            props.row.original?.frequency?.slice(1)}
        </div>
      ),
      enableSorting: false,
    },
    {
      accessorKey: "chapter",
      header: "Chapter",
      cell: (props) => {
        const uniqueRisks = _.uniqBy(
          props.row.original.form,
          (item) => `${item.risk?.qms_id} - ${item.risk?.qms_description}`,
        );
        return (
          <div>
            {uniqueRisks.map((item, index) => (
              <div key={index}>
                {item.risk?.qms_id} - {item.risk?.qms_description}
              </div>
            ))}
          </div>
        );
      },
      size: 250,
      enableSorting: false,
    },
  ];

  return (
    <MainLayout>
      <BulkEditModal modalType="audits" orgId={orgId} />
      <div className={styles.column}>
        {isLoading && <PageLoader text="Loading, it will be ready shortly" />}
        {!isLoading && (
          <>
            <Breadcrumbs data={currentModule} />
            <div className={styles.auditsContainer}>
              <AuditsModal auditData={openedModal?.data} />
              <div className={styles.rowWrapper}>
                <div className={styles.actionsWrapper}>
                  <Button
                    text="Bulk Edit"
                    className={styles.button}
                    onClick={handleBulkEdit}
                    disabled={selectedAudits.length < 2 || loading}
                    icon={EditIcon}
                    iconPosition="right"
                  />
                  <Button
                    text={
                      loading && loadingAction === "archive"
                        ? "Archiving..."
                        : "Archive"
                    }
                    className={styles.button}
                    onClick={() => setShowConfirmationPopup(true)}
                    disabled={selectedAudits.length === 0 || loading}
                    icon={ArchiveIcon}
                    iconPosition="right"
                  />
                  <Button
                    text={
                      loading && loadingAction === "export"
                        ? "Exporting..."
                        : "Export audits"
                    }
                    className={styles.button}
                    onClick={handleExportSelectedAudits}
                    disabled={selectedAudits.length === 0 || loading}
                    icon={ExportIcon}
                    iconPosition="right"
                  />
                </div>
              </div>
              <div className={styles.rowWrapper}>
                <div className={styles.filtersWrapper}>
                  <MultiSelect
                    label={
                      selectedNames?.length > 0
                        ? selectedNames.join(", ")
                        : "Audit"
                    }
                    options={auditNames}
                    onChangeSelectedValues={handleNameFilterChange}
                    selectedValues={selectedNames}
                    disabled={!auditsData || auditsData.length === 0}
                  />
                  <MultiSelect
                    label={
                      selectedResponsibles.length > 0
                        ? selectedResponsibles.join(", ")
                        : "Responsible"
                    }
                    options={uniqueResponsibles}
                    onChangeSelectedValues={handleResponsibleFilterChange}
                    selectedValues={selectedResponsibles}
                    disabled={!auditsData || auditsData.length === 0}
                  />
                  <MultiSelect
                    label={
                      selectedStatuses?.length > 0
                        ? selectedStatuses.join(", ")
                        : "Status"
                    }
                    options={uniqueStatuses}
                    onChangeSelectedValues={handleStatusFilterChange}
                    selectedValues={selectedStatuses}
                    disabled={!auditsData || auditsData.length === 0}
                  />
                  <MultiSelect
                    label={
                      selectedFrequencies.length > 0
                        ? selectedFrequencies.join(", ")
                        : "Frequency"
                    }
                    options={uniqueFrequencies}
                    onChangeSelectedValues={handleFrequencyFilterChange}
                    selectedValues={selectedFrequencies}
                    disabled={!auditsData || auditsData.length === 0}
                  />
                  <MultiSelect
                    label={
                      selectedChapters.length > 0
                        ? uniqueChapters
                            .filter((chapter) =>
                              selectedChapters.includes(chapter.id),
                            )
                            .map((chapter) => chapter.label)
                            .join(", ")
                        : "Chapter"
                    }
                    options={uniqueChapters}
                    onChangeSelectedValues={handleChapterFilterChange}
                    selectedValues={selectedChapters}
                    disabled={!auditsData || auditsData.length === 0}
                  />
                  <DateRangeFilter
                    handleDateRangeChange={setDateRange}
                    dateRange={dateRange}
                    isClearable
                  />
                </div>
                <Input
                  type="text"
                  placeholder="Search"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className={styles.input}
                  icon={SearchIcon}
                />
              </div>
              <div className={styles.tableWrapper}>
                <Table
                  columns={columns}
                  data={filteredData}
                  onRowClick={handleRowClick}
                  onSelectedRowsChange={handleSelectedRowsChange}
                  centered
                  rowHover
                  initialSorting={[{ id: "date", desc: true }]}
                  resetSelection={resetRowSelection}
                />
              </div>
              {!filteredData.length && (
                <div className={styles.noData}>
                  Sorry, there is no data to display.
                </div>
              )}
            </div>
          </>
        )}
      </div>
      {showConfirmationPopup && (
        <ConfirmationPopup
          message="Are you sure you want to archive selected audit tasks?"
          illustration={ArchiveIllustration}
          confirmText="Yes, archive"
          onConfirm={handleBulkArchive}
          onCancel={() => setShowConfirmationPopup(false)}
        />
      )}
    </MainLayout>
  );
};

export default Audits;

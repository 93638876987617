import React, { useEffect, useRef } from "react";
import styles from "./MainLayout.module.scss";
import Header from "../Header/Header";
import SideBar from "../SideBar/SideBar";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { useLocation, useParams } from "react-router-dom";
import { updateMenuActive } from "../../redux/layoutStore";
import PageLoader from "../../components/Loader/PageLoader";
import LogoutModal from "../../modals/LogoutModal";
import OrganizationSwitchModal from "../../modals/OrganizationSwitchModal";
import {
  CONFLUENCE_TREE_LOADER,
  ORGANIZATION_LOADER,
  PAGE_LOADER,
  REFRESH_BUTTON_LOADER,
} from "../../constants/loader";
import ErrorModal from "../../modals/ErrorModal";
import FloatingBar from "../../components/FloatingBar/FloatingBar";
import NewFindingModal from "../../modals/NewFindingModal";
import NewImprovementProjectModal from "../../modals/NewImprovementProjectModal";
import ExportModal from "../../modals/ExportModal";
import { useMediaQuery } from "@mui/material";
import BulkEditModal from "../../modals/BulkEditModal/BulkEditModal";
import LoaderOverlay from "../../components/LoaderOverlay/LoaderOverlay";

const MainLayout = (props) => {
  const menuActive = useSelector((state) => state.layout.menuActive);
  const loadingContexts = useSelector((state) => state.loader.loadingContexts);
  const openedModal = useSelector((state) => state.modal.openedModal);

  const isConfluenceTreeLoading = loadingContexts[CONFLUENCE_TREE_LOADER];
  const isRefreshDataLoading = loadingContexts[REFRESH_BUTTON_LOADER];
  const isPageLoading = loadingContexts[PAGE_LOADER];

  const dispatch = useDispatch();
  const location = useLocation();
  const menuIconRef = useRef(null);
  const { orgId } = useParams();
  const isMobile = useMediaQuery("(max-width:768px)");

  // closing sidebar when navigating through header or "back" button in browser
  useEffect(() => {
    dispatch(updateMenuActive(false));
  }, [location.pathname]);

  // Disable body scrolling when sidebar is open
  useEffect(() => {
    if (menuActive) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [menuActive]);

  // If loading, only show the loader, hide the rest of the content
  if (isRefreshDataLoading) {
    return <PageLoader text="Loading, it will be ready shortly..." />;
  }

  return (
    <>
      {isPageLoading ? (
        <PageLoader text="We are gathering information, it will be ready shortly" />
      ) : (
        <>
          <Header menuIconRef={menuIconRef} />
          {isConfluenceTreeLoading ? (
            <PageLoader text="Loading, it will be ready shortly..." />
          ) : (
            <>
              <LogoutModal />
              <OrganizationSwitchModal />
              <ErrorModal />
              <NewFindingModal />
              <NewImprovementProjectModal />
              <ExportModal />
              <LoaderOverlay />
              {menuActive && <SideBar menuIconRef={menuIconRef} />}
              <div
                className={classNames(styles.main, {
                  [styles.menuActive]: menuActive,
                })}
              >
                <div
                  className={classNames(styles.content, {
                    [styles.noScroll]: menuActive,
                  })}
                >
                  {props.children}
                </div>
              </div>
            </>
          )}
          {openedModal?.name !== "audits_modal" && !isMobile && <FloatingBar />}
        </>
      )}
    </>
  );
};

export default MainLayout;

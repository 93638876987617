import React, { useEffect, useState } from "react";
import styles from "./Table.module.scss";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
} from "@tanstack/react-table";
import classNames from "classnames";
import { Tooltip } from "react-tooltip";
import AscendingIcon from "../../icons/sort-ascending.svg";
import DescendingIcon from "../../icons/sort-descending.svg";

const Table = ({
  columns,
  data,
  onRowClick,
  onSelectedRowsChange = () => {},
  centered,
  rowHover,
  initialSorting = [],
  resetSelection = false,
}) => {
  const [rowSelection, setRowSelection] = useState({});
  const [sorting, setSorting] = useState(initialSorting);

  // Reset selection when resetSelection prop changes to true
  useEffect(() => {
    if (resetSelection) {
      setRowSelection({});
    }
  }, [resetSelection]);

  const handleRowClick = (row) => {
    if (onRowClick) {
      onRowClick(row);
    }
  };

  const table = useReactTable({
    data: data || [],
    columns: columns || [],
    getCoreRowModel: getCoreRowModel(),
    enableRowSelection: !!onSelectedRowsChange,
    onRowSelectionChange: setRowSelection,
    getSortedRowModel: getSortedRowModel(),
    enableSorting: true,
    state: {
      rowSelection,
      sorting,
    },
    onSortingChange: setSorting,
    columnResizeMode: "onChange",
  });

  useEffect(() => {
    const selectedRows = table
      .getSelectedRowModel()
      .rows.map((row) => row.original);
    onSelectedRowsChange(selectedRows);
  }, [rowSelection]);

  const handleSelectAllClick = (e) => {
    const allRowsSelected = table.getIsAllPageRowsSelected();
    table.toggleAllPageRowsSelected(!allRowsSelected);
  };

  return (
    <div
      className={styles.tableContainer}
      style={{ width: table.getCenterTotalSize() }}
    >
      {table.getHeaderGroups().map((headerGroup) => (
        <div className={styles.tr} key={headerGroup.id}>
          {headerGroup.headers.map((header) => (
            <div
              className={styles.th}
              style={{
                width: header.getSize(),
                cursor: header.column.getCanSort() ? "pointer" : "default",
              }}
              key={header.id}
              onClick={() => {
                if (header.column.getCanSort()) {
                  const isDesc = header.column.getIsSorted() === "desc";
                  setSorting([{ id: header.id, desc: !isDesc }]);
                }
              }}
            >
              {header.id === "select" ? (
                <div
                  className={styles.customCheckboxContainer}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleSelectAllClick(e);
                  }}
                >
                  {table.getIsAllPageRowsSelected() ? (
                    <div className={styles.checked} />
                  ) : (
                    <div className={styles.unchecked} />
                  )}
                </div>
              ) : (
                flexRender(header.column.columnDef.header, header.getContext())
              )}
              {header.column.getCanSort() && (
                <img
                  src={
                    header.column.getIsSorted() === "desc"
                      ? DescendingIcon
                      : AscendingIcon
                  }
                  alt={
                    header.column.getIsSorted() === "desc"
                      ? "Descending"
                      : "Ascending"
                  }
                  className={
                    header.column.getIsSorted()
                      ? styles.activeSortIcon
                      : styles.inactiveSortIcon
                  }
                />
              )}
              <div
                onMouseDown={header.getResizeHandler()}
                className={classNames(
                  styles.resizer,
                  header.column.getIsResizing() && styles.isResizing,
                )}
              />
            </div>
          ))}
        </div>
      ))}
      {table.getRowModel()?.rows?.map((row) => (
        <div
          className={classNames(styles.tr, rowHover && styles.hover)}
          key={row.id}
          onClick={() => handleRowClick(row)}
        >
          {row.getVisibleCells().map((cell) => (
            <div
              className={classNames(
                styles.td,
                centered &&
                  cell.column.columnDef.header === "Status" &&
                  styles.centeredData,
                onRowClick && styles.pointer,
              )}
              style={{ width: cell.column.getSize() }}
              key={cell.id}
            >
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </div>
          ))}
        </div>
      ))}
      <Tooltip id="date-tooltip" place="top" className={styles.tooltip} />
    </div>
  );
};

export default Table;

import React, { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./CascadingDropdown.module.scss";
import { ReactComponent as ArrowNextIcon } from "../../icons/arrow-next.svg";
import { ReactComponent as DownArrowIcon } from "../../icons/down-arrow.svg";
import { ReactComponent as UpArrowIcon } from "../../icons/up-arrow.svg";

const CascadingDropdown = ({
  label,
  text,
  field,
  form,
  className,
  disabled,
  options = [],
  suboptions = [],
  selectedValues = [],
  disableSelected = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [hoveredOption, setHoveredOption] = useState(null);

  const selectedOptionLabel =
    suboptions.find((sub) => sub.id === field.value)?.label || text;

  const optionToSuboptions = options.reduce((acc, option) => {
    acc[option.id] = suboptions.filter((sub) =>
      sub.reference.startsWith(option.id),
    );
    return acc;
  }, {});

  const handleSelect = (option) => {
    form.setFieldValue(field.name, option.value);
    setIsOpen(false);
  };

  return (
    <OutsideClickHandler onOutsideClick={() => setIsOpen(false)}>
      <div className={`${styles.dropdownContainer} ${className || ""}`}>
        {label && <label>{label}</label>}
        <div
          className={`${styles.dropdownTrigger} ${disabled ? styles.disabled : ""} ${isOpen && styles.active}`}
          onClick={() => {
            if (!disabled) {
              setIsOpen((prev) => {
                if (!prev) setHoveredOption(null);
                return !prev;
              });
            }
          }}
        >
          {selectedOptionLabel}
          <span className={styles.icon}>
            {isOpen ? <UpArrowIcon /> : <DownArrowIcon />}
          </span>
        </div>

        {isOpen && (
          <div className={styles.dropdownList}>
            <div
              className={`${styles.optionsColumn} ${
                hoveredOption ? styles.active : ""
              }`}
            >
              {options.map((option) => {
                return (
                  <div
                    key={option.id}
                    className={`${styles.dropdownItem} ${
                      hoveredOption === option.id ? styles.highlighted : ""
                    }`}
                    onMouseEnter={() => setHoveredOption(option.id)}
                  >
                    {option.label}
                    <ArrowNextIcon />
                  </div>
                );
              })}
            </div>
            {hoveredOption && (
              <div
                className={`${styles.suboptionsColumn} ${
                  hoveredOption ? styles.active : ""
                }`}
              >
                {optionToSuboptions[hoveredOption]?.map((sub) => {
                  const isDisabled =
                    disableSelected && selectedValues.includes(sub.id);

                  return (
                    <div
                      key={sub.id}
                      className={`${styles.subDropdownItem} ${
                        isDisabled ? styles.disabledItem : ""
                      }`}
                      onClick={() =>
                        !isDisabled &&
                        handleSelect({ value: sub.id, label: sub.label })
                      }
                    >
                      {sub.label}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        )}
      </div>
    </OutsideClickHandler>
  );
};

export default CascadingDropdown;

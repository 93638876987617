import React, { useCallback, useEffect, useRef } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import Login from "../views/Login/Login";
import Organizations from "../views/Organizations/Organizations";
import { useDispatch, useSelector } from "react-redux";
import { updateLoggedIn } from "../redux/authStore";
import axiosInstance from "../utils/utils";
import HomePage from "../views/Home/HomePage";
import { setUserData } from "../redux/userStore";
import QualityManagementSystem from "../views/QualityManagementSystem/QualityManagementSystem";
import {
  resetConfluenceStore,
  setConfluenceTree,
} from "../redux/confluenceStore";
import ResourcesArchive from "../views/ResourcesArchive/ResourcesArchive";
import KnowledgeBase from "../views/KnowledgeBase/KnowledgeBase";
import ManagementReports from "../views/ManagementReports/ManagementReports";
import { showNotFoundPage } from "../redux/layoutStore";
import PageLoader from "../components/Loader/PageLoader";
import { setLoadingContext } from "../redux/loaderStore";
import Audits from "../views/Audits/Audits";
import Findings from "../views/Findings/Findings";
import NewFinding from "../views/NewFinding/NewFinding";
import RiskAnalysis from "../views/RiskAnalysis/RiskAnalysis";
import ImprovementProjects from "../views/ImprovementProjects/ImprovementProjects";
import {
  COMPONENT_LOADER,
  CONFLUENCE_TREE_LOADER,
  ORGANIZATION_LOADER,
  PAGE_LOADER,
} from "../constants/loader";
import { showModal } from "../redux/modalStore";
import ManageAudits from "../views/ManageAudits/ManageAudits";
import ManageUsersAndOrganizations from "../views/ManageUsers&Organizations/ManageUsersAndOrganizations";
import { useMediaQuery } from "@mui/material";

const Router = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const location = useLocation();
  // const orgId = localStorage.getItem("orgId");
  const isMobile = useMediaQuery("(max-width:768px)");

  const orgId = location.pathname.split("/")[1];
  const hasNavigatedRef = useRef(false);

  const loggedIn = useSelector((state) => state.auth.loggedIn);
  const module = useSelector((state) => state.user.modules[orgId]);

  const fetchingTree = useRef(false);
  const fetchingPage = useRef(false);

  const getUserData = (sessionToken) => {
    const options = {
      method: "GET",
      url: `/auth/session?session_token=${sessionToken}`,
    };

    return axiosInstance
      .request(options)
      .then((response) => {
        dispatch(setUserData(response.data));
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          // Handle invalid session token (401 Unauthorized)
          dispatch(updateLoggedIn(false)); // Log out the user
          localStorage.removeItem("session_token"); // Remove the token
          navigate("/login", { replace: true }); // Navigate to login page
        } else {
          dispatch(
            showModal({
              name: "error_modal",
            }),
          );
        }
      });
  };

  // AUTHENTICATION
  useEffect(() => {
    const validateAndFetch = () => {
      dispatch(updateLoggedIn("pending"));

      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get("token");

      const sessionToken = localStorage.getItem("session_token");

      const handleLogout = () => {
        localStorage.removeItem("session_token"); // Remove token from local storage
        dispatch(updateLoggedIn(false)); // Log out the user
        navigate("/login", { replace: true }); // Navigate to login page
      };

      // If there is a new token in the URL, use it to override the existing session token
      if (token) {
        const options = {
          method: "PATCH",
          url: "/auth/session",
          params: { auth_token: token },
        };

        axiosInstance
          .request(options)
          .then((response) => {
            if (response.status === 200) {
              const newSessionToken = response.data.session_token;
              localStorage.setItem("session_token", newSessionToken); // Override old token
              dispatch(updateLoggedIn(true));
              navigate("/organizations", { replace: true });
            } else {
              console.error("Unexpected status code:", response.status);
              handleLogout();
            }
          })
          .catch((error) => {
            if (error.response && error.response.status === 401) {
              handleLogout();
            } else {
              console.error("Error validating token:", error);
              dispatch(
                setLoadingContext({ context: PAGE_LOADER, isLoading: false }),
              );
              dispatch(
                showModal({
                  name: "error_modal",
                }),
              );
            }
          });
      } else if (sessionToken) {
        // If no new token, validate the existing session token
        dispatch(updateLoggedIn(true));
        getUserData(sessionToken, true)
          .then(() => {
            if (location.pathname === "/login") {
              navigate("/organizations", { replace: true });
            }
          })
          .catch((error) => {
            handleLogout();
            console.error("Error getting user data:", error);
          });
      } else {
        handleLogout();
      }
    };

    validateAndFetch();
  }, [dispatch, location.pathname, navigate]);

  // CONFLUENCE DOCUMENT TREE
  const getConfluenceTree = useCallback(
    async (path, sessionToken) => {
      // Use a ref or variable to prevent duplicate calls
      if (fetchingTree.current) return;
      fetchingTree.current = true;

      dispatch(
        setLoadingContext({ context: CONFLUENCE_TREE_LOADER, isLoading: true }),
      );

      // Find the matching item in the data array
      const item = module?.find((data) => `/${orgId}${data.path}` === path);

      if (!item || item.module !== "ConfluenceDocumentTree" || !item.params) {
        console.error(
          "Path not found or no parameters available for this path.",
        );
        dispatch(
          setLoadingContext({
            context: CONFLUENCE_TREE_LOADER,
            isLoading: false,
          }),
        );
        fetchingTree.current = false; // Reset fetching flag
        return;
      }

      const { space, page_name, page_id } = item.params;
      let queryParams = `org_id=${orgId}`;

      if (space) {
        queryParams += `&space_key=${space}`;
      }

      if (page_name) {
        queryParams += `&page_name=${encodeURIComponent(page_name)}`;
      } else if (page_id) {
        queryParams += `&page_id=${page_id}`;
      }

      const url = `/confluence/tree?${queryParams}`;

      try {
        const response = await axiosInstance.get(url, {
          headers: { "session-token": sessionToken },
        });
        dispatch(showNotFoundPage(false));
        dispatch(setConfluenceTree(response.data));
      } catch (error) {
        if (error.response && error.response.status === 404) {
          dispatch(showNotFoundPage(true));
        } else {
          dispatch(
            showModal({
              name: "error_modal",
            }),
          );
        }
      } finally {
        dispatch(
          setLoadingContext({
            context: CONFLUENCE_TREE_LOADER,
            isLoading: false,
          }),
        );
        fetchingTree.current = false; // Reset fetching flag
      }
    },
    [dispatch, module, orgId],
  );

  const sessionToken = localStorage.getItem("session_token");

  useEffect(() => {
    // Ensure the user is logged in and module data is available before calling getConfluenceTree
    if (loggedIn === true && module) {
      const currentPath = location.pathname;
      getConfluenceTree(currentPath, sessionToken);
    }
  }, [location.pathname, loggedIn, module, sessionToken, getConfluenceTree]);

  //useRef to prevent loops
  useEffect(() => {
    if (loggedIn === true && orgId && !hasNavigatedRef.current) {
      // If user is on the root path or login page, navigate to organization path
      if (location.pathname === "/" || location.pathname === "/login") {
        dispatch(resetConfluenceStore());
        navigate(`/${orgId}`, { replace: true });
        hasNavigatedRef.current = true;
      } else {
        hasNavigatedRef.current = true;
      }
    }
  }, [loggedIn, orgId, dispatch, navigate, location.pathname]);

  return (
    <Routes>
      {loggedIn === true && (
        <Route path="*" element={<Navigate to={"/organizations"} replace />} />
      )}
      {loggedIn === true && (
        <Route index path="/organizations" element={<Organizations />} />
      )}
      {loggedIn === true && <Route path="/:orgId" element={<HomePage />} />}
      {loggedIn === true && !isMobile && (
        <Route
          path="/:orgId/qms/:pageId?"
          element={
            <QualityManagementSystem getConfluenceTree={getConfluenceTree} />
          }
        />
      )}
      {loggedIn === true && !isMobile && (
        <Route path="/:orgId/resources" element={<ResourcesArchive />} />
      )}
      {loggedIn === true && !isMobile && (
        <Route path="/:orgId/knowledge-base" element={<KnowledgeBase />} />
      )}
      {loggedIn === true && !isMobile && (
        <Route
          path="/:orgId/management-reports"
          element={<ManagementReports />}
        />
      )}
      {loggedIn === true && !isMobile && (
        <Route path="/:orgId/audits/:auditKey?" element={<Audits />} />
      )}
      {loggedIn === true && !isMobile && (
        <Route
          path="/:orgId/improvement-projects/:improvementKey?"
          element={<ImprovementProjects />}
        />
      )}
      )}
      {loggedIn === true && !isMobile && (
        <Route path="/:orgId/findings/:findingKey?" element={<Findings />} />
      )}
      {loggedIn === true && isMobile && (
        <Route path="/:orgId/new-finding" element={<NewFinding />} />
      )}
      {loggedIn === true && !isMobile && (
        <Route path="/:orgId/risk-analysis" element={<RiskAnalysis />} />
      )}
      {loggedIn === true && !isMobile && (
        <Route path="/:orgId/manage-audits" element={<ManageAudits />} />
      )}
      {loggedIn === true && !isMobile && (
        <Route path="/:orgId/admin" element={<ManageUsersAndOrganizations />} />
      )}
      {loggedIn === false && <Route index path="/login" element={<Login />} />}
      {loggedIn === false && (
        <Route path="*" element={<Navigate to={"/login"} replace />} />
      )}
      {loggedIn === "pending" && (
        <Route
          path="*"
          element={
            <PageLoader text="Just a moment while we prepare your account. This won’t take long." />
          }
        />
      )}
    </Routes>
  );
};

export default Router;

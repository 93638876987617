import React from "react";
import styles from "./LoaderOvarlay.module.scss";
import LoaderGif from "../../icons/Borderly_loading.gif";
import { useSelector } from "react-redux";

const LoaderOverlay = () => {
  const { isVisible } = useSelector((state) => state.overlayLoader);

  if (!isVisible) return null;

  return (
    <div className={styles.loaderOverlay}>
      <img src={LoaderGif} alt="Loading" className={styles.loaderGif} />
    </div>
  );
};

export default LoaderOverlay;

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import Modal from "../../components/Modal/Modal";
import Select from "../../components/Select/Select";
import Input from "../../components/Input/Input";
import DatePickerField from "../../components/FormikComponents/DatePickerField";
import styles from "./BulkEditModal.module.scss";
import Button from "../../components/Button/Button";
import { hideModal } from "../../redux/modalStore";
import axiosInstance from "../../utils/utils";
import { getOrganizationData } from "../../actions/organization";
import { useNavigate } from "react-router-dom";
import Pill from "../../components/Pill/Pill";
import classNames from "classnames";
import {
  clearResetSelection,
  resetSelection,
} from "../../redux/rowSelectionStore";
import Swal from "sweetalert2";
import { hideOverlay, showOverlay } from "../../redux/overlayLoaderStore";

const BulkEditModal = ({ modalType, orgId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sessionToken = localStorage.getItem("session_token");

  const openedModal = useSelector((state) => state.modal.openedModal);
  const selectedData = openedModal?.data || [];

  // Dynamic configuration
  const config = {
    improvements: {
      endpoint: `/nocodb/improvements?org_id=${orgId}`,
      fields: [
        "date",
        "status",
        "responsible",
        "accountable",
        "consulted",
        "informed",
      ],
    },
    findings: {
      endpoint: `/nocodb/findings?org_id=${orgId}`,
      fields: [
        "date",
        "status",
        "responsible",
        "accountable",
        "consulted",
        "informed",
      ],
    },
    audits: {
      endpoint: `/nocodb/audit_tasks?org_id=${orgId}`,
      fields: [
        "date",
        "status",
        "responsible",
        "accountable",
        "consulted",
        "informed",
      ],
    },
  };

  const { endpoint } = config[modalType];

  const validationSchema = Yup.object().shape({
    date: Yup.date().nullable(),
    status: Yup.string().nullable(),
    responsible: Yup.string().email("Invalid email").nullable(),
    accountable: Yup.array().of(Yup.string().email("Invalid email")).nullable(),
    consulted: Yup.array().of(Yup.string().email("Invalid email")).nullable(),
    informed: Yup.array().of(Yup.string().email("Invalid email")).nullable(),
  });

  const handleSave = async (values) => {
    dispatch(showOverlay());
    dispatch(hideModal()); // Close the modal..

    try {
      const changedFields = Object.keys(values).reduce((acc, key) => {
        const value = values[key];

        // Handle date formatting
        if (key === "date" && value instanceof Date) {
          acc[key] = value.toISOString().split("T")[0]; // Format as YYYY-MM-DD
        } else if (
          value !== undefined &&
          value !== null &&
          !(Array.isArray(value) && value.length === 0)
        ) {
          acc[key] = value;
        }

        return acc;
      }, {});

      const payload = selectedData.map((item) => ({
        id: item.id,
        ...changedFields,
      }));

      // Send the PATCH request
      await axiosInstance.patch(endpoint, payload, {
        headers: {
          "Content-Type": "application/json",
          "session-token": sessionToken,
        },
      });

      // Extract and display edited keys
      const editedKeys = selectedData.map((item) => item.key);

      // Dynamically reload data based on modalType
      const loadOptions = {
        improvements: {
          loadFindings: false,
          loadImprovements: true,
          loadAuditTasks: false,
          loadAudits: false,
          loadPermits: false,
        },
        findings: {
          loadFindings: true,
          loadImprovements: false,
          loadAuditTasks: false,
          loadAudits: false,
          loadPermits: false,
        },
        audits: {
          loadFindings: false,
          loadImprovements: false,
          loadAuditTasks: true,
          loadAudits: true,
          loadPermits: false,
        },
      };

      const {
        loadFindings,
        loadImprovements,
        loadAuditTasks,
        loadAudits,
        loadPermits,
      } = loadOptions[modalType] || {};

      // Fetch updated organization data
      await dispatch(
        getOrganizationData(
          orgId,
          sessionToken,
          navigate,
          false,
          loadFindings,
          loadImprovements,
          loadAuditTasks,
          loadAudits,
          loadPermits,
        ),
      );

      Swal.fire({
        toast: true,
        icon: "success",
        title: `Edit successful`,
        text: `Keys edited: ${editedKeys.join(", ")}`,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });
    } catch (error) {
      Swal.fire({
        toast: true,
        icon: "error",
        title: `Edit failed`,
        text: `Could not save changes. Please try again.`,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });
    } finally {
      // Trigger row selection reset
      dispatch(resetSelection());
      setTimeout(() => dispatch(clearResetSelection()), 100); // Reset to false after a short delay

      dispatch(hideOverlay()); // Hide overlay
    }
  };

  const handleCancel = () => {
    // Trigger row selection reset
    dispatch(resetSelection());
    setTimeout(() => dispatch(clearResetSelection()), 100); // Reset to false after a short delay
    dispatch(hideModal());
  };

  const statusOptions = [
    { label: "Open", value: "Open" },
    { label: "In Progress", value: "In Progress" },
    { label: "In Review", value: "In Review" },
    { label: "Done", value: "Done" },
  ];

  return (
    <Modal
      name="bulk_edit_modal"
      title={`Edit: ${
        selectedData.length > 0 &&
        selectedData.map((item) => item.key).join(", ")
      }`}
      onClose={handleCancel}
      maxWidth="40vw"
    >
      <Formik
        initialValues={{
          accountable: [],
          consulted: [],
          informed: [],
        }}
        validationSchema={validationSchema}
        onSubmit={handleSave}
      >
        {({ values, dirty, isSubmitting }) => (
          <Form className={styles.form}>
            <div className={styles.fields}>
              <div className={styles.row}>
                <div className={classNames(styles.field, styles.date)}>
                  <label>Date</label>
                  <Field name="date" component={DatePickerField} />
                </div>
                <div className={classNames(styles.field, styles.status)}>
                  <label>Status</label>
                  <Field
                    name="status"
                    component={Select}
                    options={statusOptions}
                    text="Select status"
                  />
                </div>
              </div>
              <div className={styles.field}>
                <label>Responsible</label>
                <Field
                  name="responsible"
                  component={Input}
                  placeholder="Enter responsible email"
                />
              </div>
              <div className={styles.field}>
                <label>Accountable</label>
                <Field
                  name="accountable"
                  component={Pill}
                  placeholder="Enter accountable emails"
                  limit={5}
                />
              </div>
              <div className={styles.field}>
                <label>Consulted</label>
                <Field
                  name="consulted"
                  component={Pill}
                  placeholder="Enter consulted emails"
                  limit={5}
                />
              </div>
              <div className={styles.field}>
                <label>Informed</label>
                <Field
                  name="informed"
                  component={Pill}
                  placeholder="Enter informed emails"
                  limit={5}
                />
              </div>
            </div>
            <div className={styles.actions}>
              <Button
                text="Cancel"
                onClick={handleCancel}
                className={styles.button}
              />
              <Button
                text="Save Changes"
                type="submit"
                disabled={!dirty || isSubmitting}
                className={styles.button}
              />
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default BulkEditModal;

import React, { useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CalendarIcon from "../../icons/calendar.svg";
import styles from "./DateRangeFilter.module.scss";
import { enGB } from "date-fns/locale";

// Register the locale that starts weeks on Monday
registerLocale("en-GB", enGB);

const DateRangeFilter = ({ dateRange, handleDateRangeChange, isClearable }) => {
  const [startDate, endDate] = dateRange;

  const CustomButton = React.forwardRef(({ value, onClick }, ref) => (
    <button
      type="button"
      className={`${styles.customInput} ${!startDate && !endDate ? styles.placeholder : ""}`}
      onClick={onClick}
      ref={ref}
    >
      {value ? value : "Start date - End date"}
      <img
        src={CalendarIcon}
        alt="Calendar icon"
        className={`${styles.calendarIcon} ${!startDate && !endDate ? styles.grayIcon : ""}`}
      />
    </button>
  ));

  return (
    <DatePicker
      selectsRange
      startDate={startDate}
      endDate={endDate}
      onChange={(update) => handleDateRangeChange(update)}
      dateFormat="dd/MM/yyyy"
      wrapperClassName="dateRangePicker"
      isClearable={isClearable}
      customInput={<CustomButton />}
      popperPlacement="top-start"
      locale="en-GB"
    />
  );
};

export default DateRangeFilter;

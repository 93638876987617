import React, { useEffect, useState } from "react";
import styles from "./ImprovementProjects.module.scss";
import Table from "../../components/Table/Table";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import MainLayout from "../../layouts/MainLayout/MainLayout";
import classNames from "classnames";
import Input from "../../components/Input/Input";
import SearchIcon from "../../icons/search.svg";
import ArchiveIcon from "../../icons/archive.svg";
import ExportIcon from "../../icons/export.svg";
import EditIcon from "../../icons/edit.svg";
import MultiSelect from "../../components/MultiSelect/MultiSelect";
import { formatDate, handleExport } from "../../utils/helpers";
import { showModal } from "../../redux/modalStore";
import { useDispatch, useSelector } from "react-redux";
import ImprovementProjectsModal from "../../modals/ImprovementProjectsModal";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import { setLinkedData } from "../../redux/organizationStore";
import DateRangeFilter from "../../components/DateRangeFilter/DateFIlterRange";
import useFetchOrganizationData from "../../actions/useFetchOrganizationData";
import Button from "../../components/Button/Button";
import axiosInstance from "../../utils/utils";
import { getOrganizationData } from "../../actions/organization";
import Swal from "sweetalert2";
import BulkEditModal from "../../modals/BulkEditModal/BulkEditModal";
import {
  resetSelection,
  clearResetSelection,
} from "../../redux/rowSelectionStore";
import ConfirmationPopup from "../../modals/ConfirmationPopup";
import ArchiveIllustration from "../../icons/archive-illustration.svg";
import { hideOverlay, showOverlay } from "../../redux/overlayLoaderStore";

const ImprovementProjects = () => {
  const { orgId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const sessionToken = localStorage.getItem("session_token");

  const [searchQuery, setSearchQuery] = useState("");
  const [selectedResponsibles, setSelectedResponsibles] = useState([]);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [dateRange, setDateRange] = useState([null, null]);
  const [loading, setLoading] = useState(false);
  const [loadingAction, setLoadingAction] = useState("");
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);

  const [selectedImprovementProjectData, setSelectedImprovementProjectData] =
    useState(null);

  const [selectedImprovementProjects, setSelectedImprovementProjects] =
    useState([]);

  const modules = useSelector((state) => state.user.modules[orgId]);
  const { meModeActive } = useSelector((state) => state.filters);
  const currentUser = useSelector((state) => state.user.email);
  const { improvementsData, data } = useSelector((state) => state.organization);
  const resetRowSelection = useSelector(
    (state) => state.rowSelection.resetSelection,
  );

  const improvementProjectsData = Object.values(improvementsData || {});

  useFetchOrganizationData();

  // Extracting path from URL
  const lastIndex = location.pathname.lastIndexOf("/");
  const modifiedPath = location.pathname.substring(lastIndex);

  // Find the module object corresponding to the current path
  const currentModule = modules?.find((mod) => mod.path === modifiedPath);

  // Extracting unique responsibles using flatMap for consistent handling
  const uniqueResponsibles = improvementProjectsData
    .flatMap((project) =>
      Array.isArray(project.responsible)
        ? project.responsible
        : [project.responsible],
    )
    .filter(Boolean)
    .filter((value, index, self) => self.indexOf(value) === index)
    .map((responsible) => ({ id: responsible, label: responsible }));

  const uniqueStatuses = improvementProjectsData
    .flatMap((project) =>
      Array.isArray(project.status) ? project.status : [project.status],
    )
    .filter(Boolean)
    .filter((value, index, self) => self.indexOf(value) === index)
    .map((status) => ({ id: status, label: status }));

  const handleResponsibleFilterChange = (newSelectedValues) => {
    setSelectedResponsibles(newSelectedValues);
  };

  const handleStatusFilterChange = (newSelectedValues) => {
    setSelectedStatuses(newSelectedValues);
  };

  const handleDateRangeFilterChange = (newDateRange) => {
    setDateRange(newDateRange);
  };

  const handleSelectedRowsChange = (selectedRows) => {
    setSelectedImprovementProjects(selectedRows);
  };

  const handleExportSelectedImprovementProjects = async () => {
    setLoading(true);
    setLoadingAction("export");

    await handleExport({
      selectedItems: selectedImprovementProjects,
      orgId,
      sessionToken,
      getKey: (item) => item.key,
      filePrefix: "Improvement Project",
    });

    setLoading(false);
    setLoadingAction("");
  };

  const handleBulkArchive = async () => {
    setShowConfirmationPopup(false);

    if (!selectedImprovementProjects.length) return;

    const payload = selectedImprovementProjects.map((project) => ({
      id: project.id,
      archived: true,
    }));

    const archivedKeys = selectedImprovementProjects.map(
      (project) => project.key,
    );

    setLoading(true);
    setLoadingAction("archive");
    dispatch(showOverlay());

    try {
      await axiosInstance.patch(
        `/nocodb/improvements?org_id=${orgId}`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            "session-token": sessionToken,
          },
        },
      );

      // Dispatch updated organization data
      await dispatch(
        getOrganizationData(
          orgId,
          sessionToken,
          navigate,
          false,
          false,
          true,
          false,
          false,
          false,
        ),
      );

      // Show success alert
      Swal.fire({
        toast: true,
        icon: "success",
        title: `Archive successful`,
        text: `Keys archived: ${archivedKeys.join(", ")}`,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });
    } catch (error) {
      console.error("Error archiving improvements:", error);
      Swal.fire({
        toast: true,
        icon: "error",
        title: `Archive failed`,
        text: `Could not archive improvements. Please try again.`,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });
    } finally {
      setLoading(false);
      setLoadingAction("");
      dispatch(hideOverlay()); // Hide overlay

      // Trigger row selection reset
      dispatch(resetSelection());
      setTimeout(() => dispatch(clearResetSelection()), 100); // Reset to false after a short delay
    }
  };

  const handleBulkEdit = () => {
    dispatch(
      showModal({
        name: "bulk_edit_modal",
        data: selectedImprovementProjects,
      }),
    );
  };

  const filteredData = improvementProjectsData.filter((item) => {
    const {
      responsible,
      accountable,
      consulted,
      informed,
      status,
      date: itemDate,
    } = item;

    const dueDate = new Date(itemDate);
    const formattedStartDate = dateRange[0];
    const formattedEndDate = dateRange[1];

    // Convert search query to lowercase for consistent comparison
    const searchQueryLower = searchQuery.toLowerCase();

    // Responsible match logic handling both arrays and single values
    const responsibleMatch =
      selectedResponsibles.length === 0 ||
      (Array.isArray(responsible)
        ? responsible.some((r) => selectedResponsibles.includes(r))
        : selectedResponsibles.includes(responsible));

    // Status match using labels directly
    const statusMatch =
      selectedStatuses.length === 0 || selectedStatuses.includes(status);

    // Date range match
    const dateMatch =
      (!formattedStartDate && !formattedEndDate) ||
      (dueDate >= formattedStartDate && dueDate <= formattedEndDate);

    // Search query match
    const searchMatch =
      !searchQueryLower ||
      item.key?.toLowerCase().includes(searchQueryLower) ||
      item.name?.toLowerCase().includes(searchQueryLower) ||
      item.description?.toLowerCase().includes(searchQueryLower) ||
      (Array.isArray(responsible)
        ? responsible.some((r) => r.toLowerCase().includes(searchQueryLower))
        : responsible?.toLowerCase().includes(searchQueryLower)) ||
      status?.toString().toLowerCase().includes(searchQueryLower);

    // Me mode filter: only show projects where the current user is responsible, accountable, consulted & informed
    const meModeMatch =
      !meModeActive ||
      [responsible, accountable, consulted, informed].some((role) =>
        Array.isArray(role) ? role.includes(currentUser) : role === currentUser,
      );

    return [
      responsibleMatch,
      statusMatch,
      dateMatch,
      searchMatch,
      meModeMatch,
    ].every(Boolean);
  });

  const getLinkedData = (improvement) => {
    const { linked_findings = [] } = improvement;

    const findings = linked_findings
      .map((key) => {
        return data.findings?.[key];
      })
      .filter(Boolean); // Filter out any undefined values

    return { linked_findings: findings };
  };

  const handleRowClick = (row) => {
    const linkedData = getLinkedData(row.original);
    dispatch(setLinkedData(linkedData));
    setSelectedImprovementProjectData(row.original);
    dispatch(
      showModal({
        name: "improvement_projects_modal",
        data: row.original,
      }),
    );
  };

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    }
    return (
      <>
        {text.substring(0, maxLength)}
        <span className={styles.readMore}>... read more</span>
      </>
    );
  };

  const simpleTruncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    }
    return (
      <>
        {text.substring(0, maxLength)}
        <span>...</span>
      </>
    );
  };

  const columns = [
    {
      accessorKey: "select",
      header: ({ table }) => (
        <div
          className={styles.customCheckboxContainer}
          onClick={(e) => {
            e.stopPropagation(); // Stop click event propagation
            table.toggleAllPageRowsSelected(!table.getIsAllPageRowsSelected());
          }}
        >
          {table.getIsAllPageRowsSelected() ? (
            <div className={styles.checked} />
          ) : (
            <div className={styles.unchecked} />
          )}
        </div>
      ),
      size: 50,
      cell: ({ row }) => (
        <div
          className={styles.customCheckboxContainer}
          onClick={(e) => {
            e.stopPropagation(); // Prevent row click
            row.toggleSelected();
          }}
        >
          {row.getIsSelected() ? (
            <div className={styles.checked} />
          ) : (
            <div className={styles.unchecked} />
          )}
        </div>
      ),
      enableSorting: false,
    },
    {
      accessorKey: "key",
      header: "Key",
      size: 80,
      cell: (props) => <div>{props.getValue()}</div>,
      enableSorting: false,
    },
    {
      accessorKey: "name",
      header: "Name",
      size: 270,
      cell: (props) => <div>{simpleTruncateText(props.getValue(), 60)}</div>,
      enableSorting: false,
    },
    {
      accessorKey: "description",
      header: "Description",
      size: 350,
      cell: (props) => <div>{truncateText(props.getValue(), 80)}</div>,
      enableSorting: false,
    },
    {
      accessorKey: "date",
      header: "Date",
      size: 130,
      cell: (props) => (
        <div>
          {props.getValue() ? formatDate(props.getValue()) : "Not defined"}
        </div>
      ),
      sortingFn: "datetime",
    },
    {
      accessorKey: "responsible",
      header: "Responsible",
      size: 170,
      cell: (props) => {
        return props.row.original.responsible ? (
          <div>{props.row.original.responsible}</div>
        ) : (
          <div>Not defined</div>
        );
      },
      enableSorting: false,
    },
    {
      accessorKey: "status",
      header: "Status",
      size: 120,
      cell: (props) => {
        const status = props.getValue();
        let statusClassName = "";

        switch (status) {
          case "Open":
            statusClassName = styles.openStatus;
            break;
          case "In Progress":
            statusClassName = styles.inProgressStatus;
            break;
          case "In Review":
            statusClassName = styles.inReviewStatus;
            break;
          case "Done":
            statusClassName = styles.resolvedStatus;
            break;
          default:
            break;
        }

        return (
          <div className={classNames(styles.statusCell, statusClassName)}>
            {status}
          </div>
        );
      },
      enableSorting: false,
    },
  ];

  return (
    <MainLayout>
      <ImprovementProjectsModal
        improvementProjectsData={selectedImprovementProjectData}
      />
      <BulkEditModal modalType="improvements" orgId={orgId} />
      <div className={styles.column}>
        <Breadcrumbs data={currentModule} />
        <div className={styles.improvementProjectsContainer}>
          <div className={styles.rowWrapper}>
            <div className={styles.actionsWrapper}>
              <Button
                text="Bulk Edit"
                className={styles.button}
                onClick={handleBulkEdit}
                disabled={selectedImprovementProjects.length < 2 || loading}
                icon={EditIcon}
                iconPosition="right"
              />
              <Button
                text={
                  loading && loadingAction === "archive"
                    ? "Archiving..."
                    : "Archive"
                }
                className={styles.button}
                onClick={() => setShowConfirmationPopup(true)}
                disabled={selectedImprovementProjects.length === 0 || loading}
                icon={ArchiveIcon}
                iconPosition="right"
              />
              <Button
                text={
                  loading && loadingAction === "export"
                    ? "Exporting..."
                    : "Export"
                }
                className={styles.button}
                onClick={handleExportSelectedImprovementProjects}
                disabled={selectedImprovementProjects.length === 0 || loading}
                icon={ExportIcon}
                iconPosition="right"
              />
            </div>
          </div>
          <div className={styles.rowWrapper}>
            <div className={styles.filtersWrapper}>
              <MultiSelect
                label={
                  selectedResponsibles.length > 0
                    ? selectedResponsibles.join(", ")
                    : "Responsible"
                }
                options={uniqueResponsibles}
                onChangeSelectedValues={handleResponsibleFilterChange}
                selectedValues={selectedResponsibles}
                disabled={!improvementProjectsData.length}
              />
              <MultiSelect
                label={
                  selectedStatuses.length > 0
                    ? selectedStatuses.join(", ")
                    : "All statuses"
                }
                options={uniqueStatuses}
                selectedValues={selectedStatuses}
                onChangeSelectedValues={handleStatusFilterChange}
                disabled={!improvementProjectsData.length}
              />
              <DateRangeFilter
                handleDateRangeChange={handleDateRangeFilterChange}
                dateRange={dateRange}
                isClearable
              />
            </div>
            <Input
              type="text"
              placeholder="Search"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className={styles.input}
              icon={SearchIcon}
            />
          </div>
          <div className={styles.tableWrapper}>
            <Table
              columns={columns}
              data={filteredData ? filteredData : improvementProjectsData}
              onRowClick={handleRowClick}
              onSelectedRowsChange={handleSelectedRowsChange}
              rowHover
              initialSorting={[{ id: "date", desc: true }]}
              resetSelection={resetRowSelection}
            />
          </div>
          {!filteredData?.length && (
            <div className={styles.noData}>
              Sorry, there is no data to display.
            </div>
          )}
        </div>
      </div>
      {showConfirmationPopup && (
        <ConfirmationPopup
          message="Are you sure you want to archive selected improvement projects?"
          illustration={ArchiveIllustration}
          confirmText="Yes, archive"
          onConfirm={handleBulkArchive}
          onCancel={() => setShowConfirmationPopup(false)}
        />
      )}
    </MainLayout>
  );
};

export default ImprovementProjects;

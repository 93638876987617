import React, { useEffect, useRef } from "react";
import { Chart } from "chart.js/auto";

const impactScores = { low: 1, medium: 2, high: 3, null: 1 };

const LineGraph = ({ data, months }) => {
  const canvasRef = useRef(null);
  const chartRef = useRef(null);

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.destroy();
    }

    // Process each data point to calculate RPS and store related findings/improvements
    const combinedDataPoints = data.map((item, index) => {
      const hasFindings =
        item.relatedFindings && item.relatedFindings.length > 0;
      const hasImprovements =
        item.relatedImprovements && item.relatedImprovements.length > 0;

      // Calculate impact of findings
      const findingImpact = item.relatedFindings.reduce((total, finding) => {
        const riskLevel =
          finding.risk && finding.risk.level ? finding.risk.level : "null"; // Use 'null' if risk or level is undefined
        return total + impactScores[riskLevel];
      }, 0);

      // Calculate impact of improvements (subtract based on risk level)
      const processedFindings = new Set();
      const improvementImpact = item.relatedImprovements.reduce(
        (total, improvement) => {
          improvement.linked_findings.forEach((findingKey) => {
            if (!processedFindings.has(findingKey)) {
              const linkedFinding = item.relatedFindings.find(
                (f) => f.key === findingKey,
              );
              if (linkedFinding) {
                const impact = impactScores[linkedFinding.risk.level];
                total -= impact; // Apply negative impact only once per unique finding
                processedFindings.add(findingKey); // Track finding to avoid double-counting
              }
            }
          });
          return total;
        },
        0,
      );

      const RPS = findingImpact + improvementImpact;

      // Set the point color
      let pointColor = "#EBFF99"; // Default color

      // Different colors for findings and improvements
      // if (hasFindings && !hasImprovements) {
      //   pointColor = "#AD88C6";
      // } else if (hasImprovements && !hasFindings) {
      //   pointColor = "#88AB8E";
      // } else if (hasFindings && hasImprovements) {
      //   pointColor = "#88AB8E";
      // }

      return {
        x: months[index],
        y: RPS,
        findings: item.relatedFindings,
        improvements: item.relatedImprovements,
        findingImpact,
        improvementImpact,
        RPS,
        pointBackgroundColor: pointColor,
        pointBorderColor: "#112C22",
      };
    });

    const chartData = {
      labels: months,
      datasets: [
        {
          label: "RPS",
          data: combinedDataPoints,
          fill: true,
          borderColor: "#007940",
          tension: 0.1,
          pointBackgroundColor: combinedDataPoints.map(
            (point) => point.pointBackgroundColor,
          ),
          pointBorderColor: combinedDataPoints.map(
            (point) => point.pointBorderColor,
          ),
          backgroundColor: "#EDE6DE",
          pointRadius: 5, // Show points on the line
        },
      ],
    };

    const chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y: {
          beginAtZero: true,
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false, // Disable default tooltip
          external: function (context) {
            // Tooltip Element
            let tooltipEl = document.getElementById("chartjs-tooltip");

            // Create tooltip element if it doesn't exist
            if (!tooltipEl) {
              tooltipEl = document.createElement("div");
              tooltipEl.id = "chartjs-tooltip";
              tooltipEl.style.position = "absolute";
              tooltipEl.style.pointerEvents = "none";
              tooltipEl.style.opacity = 0;
              tooltipEl.style.transition = "opacity 0.3s ease";
              document.body.appendChild(tooltipEl);
            }

            const tooltipModel = context.tooltip;

            // Hide if no tooltip
            if (tooltipModel.opacity === 0) {
              tooltipEl.style.opacity = 0;
              return;
            }

            // Get the data point related to the tooltip
            const dataPoint = context.tooltip.dataPoints[0].raw;

            // Set text for the tooltip
            let tooltipContent = `<div><strong>RPS: ${dataPoint.RPS}</strong></div>`;

            // Add RPS Calculation Breakdown
            tooltipContent += `<div><strong>RPS Calculation:</strong></div>`;
            tooltipContent += `<div>Findings Impact: +${dataPoint.findingImpact}</div>`;
            tooltipContent += `<div>Improvements Impact: ${dataPoint.improvementImpact}</div>`;

            // Parse the current month to filter findings/improvements
            const pointMonth = dataPoint.x; // month label (for example 'Sept')

            // Show findings related to this specific month
            const filteredFindings = dataPoint.findings.filter((finding) => {
              const findingMonth = new Date(finding.date).toLocaleString(
                "default",
                { month: "short" },
              );
              return findingMonth === pointMonth;
            });

            if (filteredFindings.length > 0) {
              tooltipContent += `<div style="color: #AD88C6; font-weight: bold">Findings:</div>`;
              filteredFindings.forEach((finding) => {
                const impact =
                  impactScores[
                    finding.risk && finding.risk.level
                      ? finding.risk.level
                      : "null"
                  ];

                // Add colored text for the risk levels
                let levelColor = "#007940"; // Default color for low
                if (finding.risk && finding.risk.level) {
                  if (finding.risk.level === "medium") {
                    levelColor = "#FF7427"; // Orange for medium
                  } else if (finding.risk.level === "high") {
                    levelColor = "#C60C30"; // Red for high
                  }
                }

                const riskLevelText =
                  finding.risk && finding.risk.level
                    ? finding.risk.level
                    : "unknown"; // Default to 'unknown' if level is not available
                tooltipContent += `<div>${finding.key}: +${impact} <span style="color:${levelColor};">(${riskLevelText} level)</span></div>`;
              });
            }

            // Show improvements related to this specific month
            const filteredImprovements = dataPoint.improvements.filter(
              (improvement) => {
                const improvementMonth = new Date(
                  improvement.date,
                ).toLocaleString("default", { month: "short" });
                return improvementMonth === pointMonth;
              },
            );

            // Map to store improvement key and count
            const improvementCountMap = new Map();

            // Iterate through filtered improvements
            filteredImprovements.forEach((improvement) => {
              improvement.linked_findings.forEach((linkedFindingKey) => {
                const linkedFinding = dataPoint.findings.find(
                  (f) => f.key === linkedFindingKey,
                );

                if (linkedFinding) {
                  if (!improvementCountMap.has(improvement.key)) {
                    improvementCountMap.set(improvement.key, {
                      count: 0,
                      impact: 0,
                    });
                  }
                  const current = improvementCountMap.get(improvement.key);
                  current.count += 1; // Increment the count of improvements with the same key
                  current.impact = impactScores[linkedFinding.risk.level] * -1; // Store negative impact based on risk level
                }
              });
            });

            // Add "Improvements:" label before rendering improvements in the tooltip
            if (improvementCountMap.size > 0) {
              tooltipContent += `<div style="color: #88AB8E; font-weight: bold">Improvements:</div>`;
              improvementCountMap.forEach((value, key) => {
                const totalImpact = value.impact * value.count; // Correct multiplication of negative impact by count
                tooltipContent += `<div>${key}: ${value.impact} x ${value.count} = ${totalImpact}</div>`;
              });
            }

            // Set the inner HTML of the tooltip
            tooltipEl.innerHTML = tooltipContent;

            // Set position and styles of tooltip
            const position = context.chart.canvas.getBoundingClientRect();
            tooltipEl.style.opacity = 1;
            tooltipEl.style.left =
              position.left + window.pageXOffset + tooltipModel.caretX + "px";
            tooltipEl.style.top =
              position.top + window.pageYOffset + tooltipModel.caretY + "px";

            // Apply inline styles to control appearance
            tooltipEl.style.backgroundColor = "white";
            tooltipEl.style.border = "1px solid #EAECF0";
            tooltipEl.style.borderRadius = "8px";
            tooltipEl.style.boxShadow = " 0 6px 12px 4px rgba(0, 0, 0, 0.12)";
            tooltipEl.style.padding = "10px";
            tooltipEl.style.height = "auto";
            tooltipEl.style.overflowY = "auto";
            tooltipEl.style.whiteSpace = "normal";
          },
        },
      },
    };

    const ctx = canvasRef.current.getContext("2d");
    chartRef.current = new Chart(ctx, {
      type: "line",
      data: chartData,
      options: chartOptions,
    });

    return () => {
      if (chartRef.current) {
        chartRef.current.destroy();
      }
    };
  }, [data, months]);

  return (
    <div style={{ height: "200px", width: "100%" }}>
      <canvas
        ref={canvasRef}
        style={{ height: "100%", width: "100%" }}
      ></canvas>
    </div>
  );
};

export default LineGraph;

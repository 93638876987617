import React from "react";
import styles from "./AuditCard.module.scss";
import { ReactComponent as CalendarIcon } from "../../icons/calendar.svg";
import { ReactComponent as OpenIcon } from "../../icons/arrow-left.svg";
import { ReactComponent as UpdateIcon } from "../../icons/update.svg";
import { formatDate } from "../../utils/helpers";
import classNames from "classnames";

const AuditCard = ({
  title,
  dateRange,
  frequency,
  lastUpdated,
  onClick,
  isSelected,
}) => {
  const frequencyColors = {
    daily: "#FCC8AC",
    weekly: "#FFA1F2",
    quarterly: "#A4D9F5",
    fortnightly: "#C7B3F3",
    monthly: "#7ADDAF",
    "half-yearly": "#DDEAFB",
    yearly: "#FDC0EF",
  };

  return (
    <div
      className={classNames(styles.auditCard, {
        [styles.selected]: isSelected,
      })}
      onClick={onClick}
    >
      <div className={styles.header}>
        <div className={styles.title}>{title}</div>
        {frequency && (
          <div
            className={styles.frequencyBadge}
            style={{ backgroundColor: frequencyColors[frequency] }}
          >
            {frequency}
          </div>
        )}
      </div>
      <div className={styles.content}>
        <div>
          <div className={styles.lastUpdated}>
            <UpdateIcon />
            Last updated: {formatDate(lastUpdated)}
          </div>
          <div className={styles.date}>
            <CalendarIcon />
            {dateRange}
          </div>
        </div>
        {!isSelected && (
          <div className={styles.actions}>
            <OpenIcon />
          </div>
        )}
      </div>
    </div>
  );
};

export default AuditCard;

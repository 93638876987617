import React from "react";
import styles from "./CalendarLinkPopup.module.scss";
import Button from "../../components/Button/Button";
import { ReactComponent as CopyIcon } from "../../icons/copy.svg";
import OutsideClickHandler from "react-outside-click-handler";

const CalendarLinkPopup = ({ onClose, link }) => {
  const handleCopy = () => {
    navigator.clipboard.writeText(link);
  };

  return (
    <div className={styles.overlay}>
      <OutsideClickHandler onOutsideClick={onClose}>
        <div className={styles.popup}>
          <p style={{ marginBottom: "8px", fontSize: "14px" }}>
            <p style={{ marginBottom: "8px", fontSize: "14px" }}>
              This calendar feed link lets you sync your audit tasks with your
              calendar (Google Calendar, Outlook, Apple Calendar, etc.).
              <br />
              <br />
              <b>Do not share it</b> — it contains your personal access token.
              <br />
              <br />
              To use it, just copy the link and paste it into your calendar app
              as a new calendar by URL.
            </p>
          </p>
          <div className={styles.linkContainer}>
            <input value={link} readOnly className={styles.linkInput} />
            <button
              onClick={handleCopy}
              className={styles.copyButton}
              aria-label="Copy link"
            >
              <CopyIcon />
            </button>
          </div>
          <div className={styles.buttonGroup}>
            <Button
              text="Got it"
              onClick={onClose}
              className={styles.confirmButton}
            />
          </div>
        </div>
      </OutsideClickHandler>
    </div>
  );
};

export default CalendarLinkPopup;

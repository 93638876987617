import React from "react";
import styles from "./ConfirmationPopup.module.scss";
import Button from "../components/Button/Button";

const ConfirmationPopup = ({
  illustration,
  message,
  confirmText = "Confirm",
  cancelText = "Cancel",
  onConfirm,
  onCancel,
}) => (
  <div className={styles.overlay}>
    <div className={styles.popup}>
      {illustration && (
        <img
          src={illustration}
          className={styles.illustration}
          alt="Popup illustration"
        />
      )}
      <p>{message}</p>
      <div className={styles.buttonGroup}>
        <Button
          text={cancelText}
          onClick={onCancel}
          className={styles.cancelButton}
        />
        <Button
          text={confirmText}
          onClick={onConfirm}
          className={styles.confirmButton}
        />
      </div>
    </div>
  </div>
);

export default ConfirmationPopup;

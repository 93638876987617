import React from "react";
import Modal from "../components/Modal/Modal";
import LogoutIllustration from "../icons/are-you-sure.svg";
import styles from "./LogoutModal.module.scss";
import Button from "../components/Button/Button";
import classNames from "classnames";
import { hideModal } from "../redux/modalStore";
import { useDispatch } from "react-redux";
import { updateLoggedIn } from "../redux/authStore";

const LogoutModal = () => {
  const dispatch = useDispatch();

  const handleCloseModal = () => {
    dispatch(hideModal(null));
  };

  const handleLogOut = () => {
    localStorage.removeItem("session_token");
    dispatch(updateLoggedIn(false));
    handleCloseModal();
  };

  return (
    <Modal
      name="logout_modal"
      title="Are you sure you want to log out?"
      width="100%"
    >
      <div className={styles.content}>
        <img
          src={LogoutIllustration}
          alt="Illustration of a person thinking"
          className={styles.illustration}
        />
        <div className={styles.buttonRow}>
          <Button
            className={classNames(styles.button, styles.cancel)}
            text="Cancel"
            onClick={handleCloseModal}
          />
          <Button
            className={styles.button}
            text="Yes, log out"
            onClick={handleLogOut}
          />
        </div>
      </div>
    </Modal>
  );
};

export default LogoutModal;

import React, { useEffect, useState } from "react";
import Modal from "../components/Modal/Modal";
import styles from "./AuditsModal.module.scss";
import { useDispatch, useSelector } from "react-redux";
import Accordion from "../components/Accordion/Accordion";
import { Field, Form, Formik } from "formik";
import axiosInstance from "../utils/utils";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../components/Button/Button";
import { hideModal, showModal } from "../redux/modalStore";
import Separator from "../components/Separator/Separator";
import classNames from "classnames";
import { ReactComponent as EditIcon } from "../icons/edit.svg";
import { getOrganizationData } from "../actions/organization";
import Pill from "../components/Pill/Pill";
import Select from "../components/Select/Select";
import Table from "../components/Table/Table";
import SecondaryButton from "../components/Button/SecondaryButton";
import ModalSideBar from "../components/ModalSideBar/ModalSideBar";
import ConfirmationPopup from "./ConfirmationPopup";
import { setLinkedData } from "../redux/findingsStore";
import Input from "../components/Input/Input";
import { formatDate } from "../utils/helpers";
import WYSIWYGEditor from "../components/WYSIWYGEditor/WYSIWYGEditor";
import DOMPurify from "dompurify";
import { marked } from "marked";
import DatePickerField from "../components/FormikComponents/DatePickerField";
import * as Yup from "yup";
import WarningIllustration from "../icons/no-data.svg";

const AuditsModal = ({ auditData }) => {
  const openedModal = useSelector((state) => state.modal.openedModal);
  const { linkedData, findingsData } = useSelector(
    (state) => state.organization,
  );

  const openedModalData = auditData || openedModal?.data;

  const [loading, setLoading] = useState(false);
  const [editableData, setEditableData] = useState(openedModalData || {});
  const [unsavedChanges, setUnsavedChanges] = useState({});
  const [editField, setEditField] = useState(null);
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const [updates, setUpdates] = useState(openedModal?.data?.updates || []);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [updatedLinkedData, setUpdatedLinkedData] = useState(null);

  const dispatch = useDispatch();
  const { orgId } = useParams();
  const navigate = useNavigate();
  const sessionToken = localStorage.getItem("session_token");

  console.log(auditData, "audit data");
  console.log(openedModalData, "opened modal data");

  // This function extracts linked findings from the Redux store and maps them to the findings data
  const getLinkedData = (audit) => {
    const { linked_findings = [] } = audit;
    const findings = linked_findings
      .map((key) => findingsData?.[key])
      .filter(Boolean);
    return { linked_findings: findings };
  };

  useEffect(() => {
    setEditableData(openedModalData || {});
    setUnsavedChanges({});
    setEditField(null);
    setLoading(false);
    setUpdates(openedModal?.data?.updates || []);

    // Fetch linked data for findings
    if (openedModalData) {
      const updatedData = getLinkedData(openedModalData);
      setUpdatedLinkedData(updatedData);
      dispatch(setLinkedData(updatedData));
    }
  }, [openedModalData, openedModal, dispatch]);

  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      setEditField(null); // Reset editField when Esc is pressed
    }
  };

  useEffect(() => {
    // Add the event listener when the component mounts
    window.addEventListener("keydown", handleKeyDown);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const renderLinkedFindings = () => {
    const dataToShow = updatedLinkedData?.linked_findings?.length
      ? updatedLinkedData.linked_findings
      : linkedData?.linked_findings || [];

    if (dataToShow.length > 0) {
      return (
        <>
          <div className={styles.title}>Linked Findings</div>
          <div className={styles.table}>
            <Table columns={columns} data={dataToShow} />
          </div>
          <SecondaryButton
            text="View all findings..."
            className={styles.secondaryButton}
            onClick={() => {
              navigate(`/${orgId}/findings`);
              dispatch(hideModal());
            }}
          />
        </>
      );
    } else {
      return (
        <div className={styles.noLinkedFindings}>
          No linked findings available
        </div>
      );
    }
  };

  const modifyDescription = (description) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(description, "text/html");
    const links = doc.querySelectorAll("a");

    links.forEach((link) => {
      link.setAttribute("target", "_blank");
    });

    return doc.body.innerHTML;
  };

  const handleSaveResponses = async (values, shouldHideModal = true) => {
    setLoading(true);
    let hasChanges = false;

    // Build an object containing only the changed fields
    const changedFields = { id: openedModalData.id };

    // We need to check if any responses have changed
    let hasAnyResponseChanged = false;

    // Track all responses (changed or not) in the finalResponses object
    const finalResponses = { ...editableData.responses };

    // Process form data (responses to questions)
    for (const formItem of openedModalData.form || []) {
      const questions = formItem.questions;

      for (const questionId in questions) {
        const question = questions[questionId];
        // Ensure existingAnswer is properly handled based on type
        const existingAnswer =
          question.answer ||
          (question.type === "text" || question.type === "select" ? "" : []);
        const newResponse = values.responses[questionId];

        let hasChanged = false;

        if (question.type === "text") {
          // Handle text type questions
          if (typeof newResponse === "string" && newResponse.trim() !== "") {
            finalResponses[questionId] = newResponse;
            if (newResponse !== existingAnswer) {
              hasChanged = true;
            }
          } else if (existingAnswer) {
            finalResponses[questionId] = existingAnswer;
          }
        } else if (question.type === "file") {
          // Handle file uploads
          if (Array.isArray(newResponse) && newResponse.length > 0) {
            // Make sure existingAnswer is treated as an array
            const existingArray = Array.isArray(existingAnswer)
              ? existingAnswer
              : [];

            const combinedFiles = [
              ...existingArray.map((answer) => answer.path || answer),
              ...newResponse,
            ];

            finalResponses[questionId] = combinedFiles;

            // Check if files are different
            const existingPaths = existingArray.map((a) => a.path || a);
            const combinedPaths = combinedFiles.map((f) => f.path || f);

            if (
              JSON.stringify(existingPaths) !== JSON.stringify(combinedPaths)
            ) {
              hasChanged = true;
            }
          } else if (
            Array.isArray(existingAnswer) &&
            existingAnswer.length > 0
          ) {
            finalResponses[questionId] = existingAnswer.map(
              (answer) => answer.path || answer,
            );
          } else {
            // In case existingAnswer is not an array
            finalResponses[questionId] = [];
          }
        } else if (question.type === "select") {
          // Handle single select questions
          if (newResponse && newResponse.trim() !== "") {
            finalResponses[questionId] = newResponse;
            if (newResponse !== existingAnswer) {
              hasChanged = true;
            }
          } else if (existingAnswer) {
            finalResponses[questionId] = existingAnswer;
          }
        } else if (question.type === "multiselect") {
          // Handle multiselect questions
          if (Array.isArray(newResponse) && newResponse.length > 0) {
            // Extract values from the options if they're objects
            const processedResponse = newResponse.map((item) => {
              return typeof item === "object" ? item.value || item.id : item;
            });

            finalResponses[questionId] = processedResponse;

            // Ensure existingAnswer is an array before comparing
            const existingArray = Array.isArray(existingAnswer)
              ? existingAnswer
              : [];

            // Check if arrays are different
            if (
              JSON.stringify(processedResponse) !==
              JSON.stringify(existingArray)
            ) {
              hasChanged = true;
            }
          } else if (
            Array.isArray(existingAnswer) &&
            existingAnswer.length > 0
          ) {
            finalResponses[questionId] = existingAnswer;
          } else {
            finalResponses[questionId] = [];
          }
        } else {
          // Handle array type answers
          if (Array.isArray(newResponse)) {
            const nonNullAnswers = newResponse
              .filter((answer) => answer && answer !== null)
              .map((answer) => {
                return typeof answer === "object" && answer.path
                  ? answer.path
                  : answer;
              });

            // Ensure existingAnswer is an array
            const existingArray = Array.isArray(existingAnswer)
              ? existingAnswer
              : [];

            if (nonNullAnswers.length > 0 || existingArray.length > 0) {
              const combinedAnswers = [
                ...existingArray.map((answer) => answer.path || answer),
                ...nonNullAnswers,
              ];

              finalResponses[questionId] = combinedAnswers;

              // Check if arrays are different
              const existingPaths = existingArray.map((a) => a.path || a);
              if (
                JSON.stringify(existingPaths) !==
                JSON.stringify(combinedAnswers)
              ) {
                hasChanged = true;
              }
            }
          } else if (
            Array.isArray(existingAnswer) &&
            existingAnswer.length > 0
          ) {
            finalResponses[questionId] = existingAnswer.map(
              (answer) => answer.path || answer,
            );
          } else {
            finalResponses[questionId] = [];
          }
        }

        const selectedType = values.type?.toLowerCase();

        if (selectedType && editableData.type !== selectedType) {
          changedFields.type = selectedType;
          hasChanges = true;
        }

        if (hasChanged) {
          hasAnyResponseChanged = true;
        }
      }
    }

    // Include all responses if any have changed
    if (hasAnyResponseChanged) {
      changedFields.responses = finalResponses;
      hasChanges = true;
    }

    // Track changes for other fields
    if (values.summary && editableData.summary !== values.summary) {
      changedFields.summary = values.summary;
      hasChanges = true;
    }

    // Format and update the date field
    if (values.date && editableData.date !== values.date) {
      const formattedDate =
        values.date instanceof Date
          ? values.date.toISOString().split("T")[0] // Format date as YYYY-MM-DD
          : values.date;

      if (editableData.date !== formattedDate) {
        changedFields.date = formattedDate;
        hasChanges = true;
      }
    }

    // Process `responsible` as a string
    const responsibleString = Array.isArray(values.responsible)
      ? values.responsible.join(", ")
      : values.responsible;

    if (responsibleString && editableData.responsible !== responsibleString) {
      changedFields.responsible = responsibleString;
      hasChanges = true;
    }

    // Process RACI arrays
    ["accountable", "consulted", "informed"].forEach((field) => {
      const newValue = Array.isArray(values[field])
        ? values[field]
        : values[field]?.split(",").map((item) => item.trim()) || [];

      // Only include if there's a difference and the new value isn't empty
      if (
        JSON.stringify(editableData[field]) !== JSON.stringify(newValue) &&
        !(newValue.length === 1 && newValue[0] === "")
      ) {
        changedFields[field] = newValue;
        hasChanges = true;
      }
    });

    // Handle status separately
    if (values.status && editableData.status !== values.status) {
      changedFields.status = values.status;
      hasChanges = true;
    }

    // Send payload if changes are detected
    if (hasChanges) {
      try {
        const payload = [changedFields];

        console.log("Sending only changed fields:", payload);

        await axiosInstance.patch(
          `/nocodb/audit_tasks?org_id=${orgId}`,
          payload,
          {
            headers: {
              "session-token": sessionToken,
              "Content-Type": "application/json",
            },
          },
        );

        await dispatch(
          getOrganizationData(
            orgId,
            sessionToken,
            navigate,
            false,
            false,
            false,
            true,
            false,
            false,
          ),
        );

        setLoading(false);
        // Hide the modal only if the flag `shouldHideModal` is true
        if (shouldHideModal) {
          navigate(`/${orgId}/audits`, { replace: true });
          dispatch(hideModal());
        }
      } catch (error) {
        console.error("Error saving responses:", error);
        setLoading(false);
      }
    } else {
      setLoading(false);
      if (shouldHideModal) {
        navigate(`/${orgId}/audits`, { replace: true });
        dispatch(hideModal());
      }
    }
  };

  const initialValues = {
    responses: (openedModalData?.form || []).reduce((acc, formItem) => {
      Object.keys(formItem.questions).forEach((questionId) => {
        const question = formItem.questions[questionId];

        // Check question type and assign appropriate initial value
        if (question.type === "text") {
          acc[questionId] = question.answer || ""; // For text fields
        } else if (question.type === "select") {
          acc[questionId] = question.answer || ""; // For single select
        } else if (question.type === "multiselect") {
          acc[questionId] = question.answer || []; // For multiselect
        }
        // Note: File uploads are handled differently via the Upload component
      });
      return acc;
    }, {}),
    responsible: Array.isArray(editableData.responsible)
      ? editableData.responsible.join(", ")
      : editableData.responsible || "",
    accountable: editableData.accountable || "",
    consulted: editableData.consulted || "",
    informed: editableData.informed || "",
    status: editableData.status || "Open",
    summary: auditData?.summary || "",
    type: editableData.type === "external" ? "External" : "Internal",
    date: editableData.date ? new Date(editableData.date) : null,
  };

  const truncateText = (text, maxLength) => {
    if (!text) {
      // If text is null, undefined, or an empty string
      return "There is no description provided";
    }

    if (text.length <= maxLength) {
      return text;
    }

    return (
      <>
        {text.substring(0, maxLength)}
        <span className={styles.readMore}>...</span>
      </>
    );
  };

  const columns = [
    {
      accessorKey: "key",
      header: "Key",
      size: 100,
      cell: (props) => <div>{props.getValue()}</div>,
    },
    {
      accessorKey: "description",
      header: "Description",
      size: 350,
      cell: (props) => <div>{truncateText(props.getValue(), 80)}</div>,
    },
    {
      accessorKey: "responsible",
      header: "Responsible",
      size: 200,
      cell: (props) => {
        return props.row.original.responsible ? (
          <div>{props.row.original.responsible}</div>
        ) : (
          <div>Not defined</div>
        );
      },
    },
    {
      accessorKey: "status",
      header: "Status",
      size: 120,
      cell: (props) => {
        const status = props.getValue();
        let statusClassName = "";

        switch (status) {
          case "Open":
            statusClassName = styles.openStatus;
            break;
          case "In Progress":
            statusClassName = styles.inProgressStatus;
            break;
          case "In Review":
            statusClassName = styles.inReviewStatus;
            break;
          case "Done":
            statusClassName = styles.resolvedStatus;
            break;
          default:
            break;
        }

        return (
          <div className={classNames(styles.statusCell, statusClassName)}>
            {status}
          </div>
        );
      },
    },
  ];

  const handleOnClose = (dirty) => {
    if (dirty) {
      setShowConfirmationPopup(true);
    } else {
      navigate(`/${orgId}/audits`, { replace: true });
      dispatch(hideModal());
    }
  };

  const handleConfirmClose = () => {
    setShowConfirmationPopup(false);

    navigate(`/${orgId}/audits`, { replace: true });

    dispatch(hideModal()); // Close both the confirmation and the audits modal
    setSidebarOpen(false);
  };

  const handleCancel = () => {
    setShowConfirmationPopup(false); // Just close the confirmation popup
  };

  const statusOptions = [
    { label: "Open", value: "Open" },
    { label: "In Progress", value: "In Progress" },
    { label: "In Review", value: "In Review" },
    { label: "Done", value: "Done" },
  ];

  const validationSchema = Yup.object().shape({
    date: Yup.date().required("Due Date is required"),
  });

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => handleSaveResponses(values, true)}
        enableReinitialize
        validationSchema={validationSchema}
      >
        {({ values, setFieldValue, dirty, submitForm, isSubmitting }) => {
          const handleOpenNewFindingModal = (questionId) => {
            // Call handleSaveResponses before opening the new modal
            handleSaveResponses(values, false);

            // Find the corresponding form entry for the given questionId
            const formEntry = openedModalData.form.find((formItem) => {
              return Object.keys(formItem.questions).includes(questionId);
            });

            if (formEntry) {
              const risk = formEntry.risk; // Extract the risk from the matching form entry
              dispatch(
                showModal({
                  name: "new_finding_modal",
                  data: {
                    ...openedModalData,
                    questionId,
                    risk, // Pass the risk to the modal
                  },
                }),
              );
            } else {
              console.error("No form entry found for questionId:", questionId);
            }
          };
          return (
            <Modal
              name="audits_modal"
              title={openedModalData?.key}
              width="95vw"
              height="95vh"
              onClose={() => handleOnClose(dirty)}
              isPopupOpen={showConfirmationPopup}
              headerAction={
                <Button
                  text={loading ? "Saving..." : "Save Changes"}
                  onClick={submitForm}
                  className={styles.submitButton}
                  disabled={!dirty || isSubmitting}
                />
              }
            >
              <div className={styles.content}>
                <Form className={styles.formikContent}>
                  <div
                    className={classNames(styles.header, styles.spaceBetween)}
                  >
                    <div className={styles.leftSide}>
                      <div className={styles.row}>
                        <div className={styles.subheading}>Name:</div>
                        <div className={styles.bold}>
                          {openedModalData?.name}
                        </div>
                      </div>
                      <div className={styles.row}>
                        <div className={styles.title}>Due Date:</div>
                        {editField === "date" ? (
                          <Field
                            name="date"
                            component={DatePickerField}
                            onChange={(value) => {
                              setFieldValue("date", value);
                              setUnsavedChanges((prev) => ({
                                ...prev,
                                date: value,
                              }));
                            }}
                          />
                        ) : (
                          <div className={styles.dateContainer}>
                            <div className={styles.bold}>
                              {formatDate(values.date || editableData.date)}
                            </div>
                            <EditIcon
                              className={styles.editIcon}
                              onClick={() => setEditField("date")}
                            />
                          </div>
                        )}
                      </div>
                      <div className={styles.row}>
                        <div className={styles.title}>Type:</div>
                        <div className={styles.statusContainer}>
                          {editField === "type" ? (
                            <Field
                              name="type"
                              component={Select}
                              options={[
                                { label: "Internal", value: "internal" },
                                { label: "External", value: "external" },
                              ]}
                              text="Select type"
                              value={values.type}
                              onChange={(e) => {
                                setFieldValue("type", e.target.value);
                                setUnsavedChanges((prev) => ({
                                  ...prev,
                                  type: e.target.value,
                                }));
                                setEditField(null);
                              }}
                            />
                          ) : (
                            <>
                              <div className={styles.value}>{values.type}</div>
                              <EditIcon
                                className={styles.editIcon}
                                onClick={() => setEditField("type")}
                              />
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className={styles.rightSide}>
                      <div className={styles.row}>
                        <div className={styles.title}>Status:</div>
                        <div className={styles.statusContainer}>
                          {editField === "status" ? (
                            <Field
                              name="status"
                              component={Select}
                              options={statusOptions}
                              value={values.status}
                              onChange={(e) => {
                                setFieldValue("status", e.target.value);
                                setUnsavedChanges((prev) => ({
                                  ...prev,
                                  status: e.target.value,
                                }));
                                setEditField(null);
                              }}
                            />
                          ) : (
                            <>
                              <div
                                className={classNames(
                                  styles.statusCell,
                                  values.status === "In Progress"
                                    ? styles.inProgressStatus
                                    : values.status === "In Review"
                                      ? styles.inReviewStatus
                                      : values.status === "Done"
                                        ? styles.resolvedStatus
                                        : styles.openStatus,
                                )}
                              >
                                {values.status}
                              </div>
                              <EditIcon
                                className={styles.editIcon}
                                onClick={() => setEditField("status")}
                              />
                            </>
                          )}
                        </div>
                      </div>
                      <div className={styles.row}>
                        {!isSidebarOpen && (
                          <SecondaryButton
                            text="See Updates"
                            onClick={() => setSidebarOpen(true)}
                            className={styles.toggleSidebarButton}
                            type="Button"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <Separator />

                  <div className={styles.tableContainer}>
                    <table className={styles.teamRolesTable}>
                      <tbody>
                        {/* Responsible */}
                        <tr>
                          <td>Responsible</td>
                          <td>
                            {editField === "responsible" ? (
                              <Field
                                name="responsible"
                                component={Input}
                                placeholder="Enter responsible email"
                              />
                            ) : (
                              <div>
                                {Array.isArray(values.responsible)
                                  ? values.responsible.join(", ")
                                  : values.responsible || ""}
                              </div>
                            )}
                          </td>
                          <td>
                            <EditIcon
                              className={styles.editIcon}
                              onClick={() => setEditField("responsible")}
                            />
                          </td>
                        </tr>
                        {/* Accountable */}
                        <tr>
                          <td>Accountable</td>
                          <td>
                            {editField === "accountable" ? (
                              <Field
                                name="accountable"
                                component={Pill}
                                placeholder="Enter accountable emails"
                                limit={5}
                              />
                            ) : (
                              (values.accountable || []).map((email, index) => (
                                <div key={index}>{email}</div>
                              )) || "Not defined"
                            )}
                          </td>
                          <td>
                            <EditIcon
                              className={styles.editIcon}
                              onClick={() => setEditField("accountable")}
                            />
                          </td>
                        </tr>
                        {/* Consulted */}
                        <tr>
                          <td>Consulted</td>
                          <td>
                            {editField === "consulted" ? (
                              <Field
                                name="consulted"
                                component={Pill}
                                placeholder="Enter consulted emails"
                                limit={5}
                              />
                            ) : (
                              (values.consulted || []).map((email, index) => (
                                <div key={index}>{email}</div>
                              )) || "Not defined"
                            )}
                          </td>
                          <td>
                            <EditIcon
                              className={styles.editIcon}
                              onClick={() => setEditField("consulted")}
                            />
                          </td>
                        </tr>
                        {/* Informed */}
                        <tr>
                          <td>Informed</td>
                          <td>
                            {editField === "informed" ? (
                              <Field
                                name="informed"
                                component={Pill}
                                placeholder="Enter informed emails"
                                limit={5}
                              />
                            ) : (
                              (values.informed || []).map((email, index) => (
                                <div key={index}>{email}</div>
                              )) || "Not defined"
                            )}
                          </td>
                          <td>
                            <EditIcon
                              className={styles.editIcon}
                              onClick={() => setEditField("informed")}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  {values.summary && auditData?.status === "Done" && (
                    <>
                      <Separator />
                      <div className={styles.title}>Summary:</div>
                      {editField === "summary" ? (
                        <div className={styles.editorContainer}>
                          <Field
                            name="summary"
                            component={WYSIWYGEditor}
                            placeholder="Edit the summary..."
                          />
                        </div>
                      ) : (
                        <div className={styles.summaryContent}>
                          <EditIcon
                            className={styles.editIcon}
                            onClick={() => setEditField("summary")} // Enable editing
                          />
                          {values.summary ? (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(
                                  marked.parse(values.summary.trim()),
                                ),
                              }}
                            />
                          ) : (
                            "No summary provided."
                          )}
                        </div>
                      )}
                    </>
                  )}

                  {editableData?.form?.length > 0 && <Separator />}

                  {(editableData.form || []).map((formItem, index) => (
                    <div key={index} className={styles.formItem}>
                      <div className={classNames(styles.row, styles.center)}>
                        <div className={styles.title}>Risk ID:</div>
                        <div className={styles.bold}>
                          {formItem.risk.risk_id}
                        </div>
                      </div>
                      <Accordion
                        items={Object.keys(formItem.questions).map(
                          (questionId) => {
                            const question = formItem.questions[questionId];
                            const currentAnswers = question.answer || [];

                            return {
                              question: question.question,
                              answer: {
                                type: question.type,
                                answers: currentAnswers,
                                questionId: questionId,
                                modifyDescription: modifyDescription,
                                editable: editableData.status !== "Done",
                                options: question.options,
                              },
                            };
                          },
                        )}
                        setFieldValue={setFieldValue}
                        openAllByDefault
                        icons
                        status={editableData?.status}
                        handleOpenNewFindingModal={handleOpenNewFindingModal}
                      />
                    </div>
                  ))}
                  <Separator />
                  {renderLinkedFindings()}
                </Form>
                <ModalSideBar
                  isOpen={isSidebarOpen}
                  onClose={() => setSidebarOpen(false)}
                  updates={updates}
                  setUpdates={setUpdates}
                  auditTaskId={openedModal?.data?.id}
                />
              </div>
            </Modal>
          );
        }}
      </Formik>
      {showConfirmationPopup && (
        <ConfirmationPopup
          message="Your changes won’t be saved if you close this window."
          illustration={WarningIllustration}
          confirmText="Close Without Saving"
          onConfirm={handleConfirmClose}
          onCancel={handleCancel}
        />
      )}
    </>
  );
};

export default AuditsModal;

import React from "react";
import MultiSelect from "../../MultiSelect/MultiSelect";

const FormikMultiSelect = ({ field, form, options, label, className }) => {
  const fieldValue = field.value || [];

  // Convert string values to objects for the MultiSelect component
  const selectedValues = Array.isArray(fieldValue)
    ? fieldValue.map((value) => {
        // If the value is already an ID, return it
        if (typeof value === "string") {
          // Find the matching option object
          const matchingOption = options.find(
            (opt) => opt.id === value || opt.label === value,
          );
          return matchingOption ? matchingOption.id : value;
        }
        return value.id || value;
      })
    : [];

  // Get the labels of selected options for display
  const selectedLabels = selectedValues
    .map((id) => {
      const option = options.find((opt) => opt.id === id);
      return option ? option.label : "";
    })
    .filter(Boolean);

  // Dynamic label: show selected items if any, otherwise show default label
  const dynamicLabel =
    selectedLabels.length > 0 ? selectedLabels.join(", ") : label;

  return (
    <MultiSelect
      label={dynamicLabel}
      options={options}
      selectedValues={selectedValues}
      onChangeSelectedValues={(selected) => {
        form.setFieldValue(field.name, selected);
      }}
      className={className}
    />
  );
};

export default FormikMultiSelect;

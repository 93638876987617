import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import styles from "./HomePage.module.scss";
import MainLayout from "../../layouts/MainLayout/MainLayout";
import { setLoadingContext } from "../../redux/loaderStore";
import { PAGE_LOADER } from "../../constants/loader";
import Card from "../../components/Card/Card";
import TreeLayout from "../../components/TreeLayout/TreeLayout";
import useFetchOrganizationData from "../../actions/useFetchOrganizationData";

const HomePage = () => {
  const { orgId } = useParams();
  const dispatch = useDispatch();

  useFetchOrganizationData();

  const modules = useSelector((state) => state.user.modules);

  const [organizationModules, setOrganizationModules] = useState(null);

  useEffect(() => {
    // dispatch(setLoadingContext({ context: PAGE_LOADER, isLoading: true }));

    if (modules && modules[orgId]) {
      setOrganizationModules(modules[orgId]);
      // dispatch(setLoadingContext({ context: PAGE_LOADER, isLoading: false }));
    }
  }, [modules, orgId]);

  const homeModule = organizationModules?.find(
    (module) => module.title === "Home",
  );

  const filterModulesByCategory = (category) => {
    return organizationModules
      ? organizationModules.filter(
          (module) => module.category?.toLowerCase() === category.toLowerCase(),
        )
      : [];
  };

  return (
    <MainLayout>
      <div className={styles.homePageWrapper}>
        {homeModule && (
          <>
            <div className={styles.cardWrapper}>
              <Card
                category="define"
                modules={filterModulesByCategory("Define")}
              />
              <Card
                category="measure"
                modules={filterModulesByCategory("Measure")}
              />
              <Card
                category="analyse"
                modules={filterModulesByCategory("Analyse")}
              />
              <Card
                category="improve"
                modules={filterModulesByCategory("Improve")}
              />
              <Card
                category="control"
                modules={filterModulesByCategory("Control")}
              />
            </div>
            <TreeLayout />
          </>
        )}
      </div>
    </MainLayout>
  );
};

export default HomePage;

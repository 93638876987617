import { createSlice } from "@reduxjs/toolkit";

export const rowSelectionStore = createSlice({
  name: "rowSelection",
  initialState: {
    resetSelection: false,
  },
  reducers: {
    resetSelection: (state) => {
      state.resetSelection = true; // Explicitly set to true
    },
    clearResetSelection: (state) => {
      state.resetSelection = false; // Explicitly set to false
    },
  },
});

export const { resetSelection, clearResetSelection } =
  rowSelectionStore.actions;

export default rowSelectionStore.reducer;

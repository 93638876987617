import React, { useEffect, useRef, useState } from "react";
import MainLayout from "../../layouts/MainLayout/MainLayout";
import styles from "./RiskAnalysis.module.scss";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import PageLoader from "../../components/Loader/PageLoader";
import Button from "../../components/Button/Button";
import WorkInProgressIllustration from "../../icons/work-in-progress-illustration.svg";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Separator from "../../components/Separator/Separator";
import GraphAccordion from "../../components/GraphAccordion/GraphAccordion";
import classNames from "classnames";
import SecondaryButton from "../../components/Button/SecondaryButton";
import MultiSelect from "../../components/MultiSelect/MultiSelect";
import DateRangeFilter from "../../components/DateRangeFilter/DateFIlterRange";
import Input from "../../components/Input/Input";
import SearchIcon from "../../icons/search.svg";
import Table from "../../components/Table/Table";
import {
  formatDate,
  generateCumulativeRisk,
  getPast12Months,
} from "../../utils/helpers";
import _ from "lodash";

const RiskAnalysis = () => {
  const { orgId } = useParams();
  let navigate = useNavigate();
  const location = useLocation();
  const leftSideRef = useRef(null);
  const rightSideRef = useRef(null);

  const modules = useSelector((state) => state.user.modules);
  const { findingsData, improvementsData, auditsData, data, risksData } =
    useSelector((state) => state.organization);
  const meModeActive = useSelector((state) => state.filters.meModeActive);
  const currentUser = useSelector((state) => state.user.email);

  const [loading, setLoading] = useState(true);
  const [customHtmlModule, setCustomHtmlModule] = useState(null);
  const [openChapter, setOpenChapter] = useState(null);
  const [riskCount, setRiskCount] = useState({});
  const [selectedRiskIds, setSelectedRiskIds] = useState([]);
  const [selectedLevels, setSelectedLevels] = useState([]);
  const [selectedChapters, setSelectedChapters] = useState([]);
  const [dateRange, setDateRange] = useState([null, null]);
  const [searchQuery, setSearchQuery] = useState("");

  // Extracting path from URL
  const lastIndex = location.pathname.lastIndexOf("/");
  const modifiedPath = location.pathname.substring(lastIndex);

  // Find the module object corresponding to the current path
  const currentModule = modules[orgId]?.find(
    (mod) => mod.path === modifiedPath,
  );

  const risksDataArray = risksData ? Object.values(risksData) : [];

  useEffect(() => {
    if (modules && modules[orgId]) {
      const module = modules[orgId].find(
        (module) => module.title === "Risk Analysis",
      );
      if (module && module.module === "CustomHtml") {
        setCustomHtmlModule(module);
      }
      setLoading(false);
    }
  }, [modules, orgId]);

  useEffect(() => {
    const riskCount = generateCumulativeRisk(findingsData, improvementsData);
    setRiskCount(riskCount);
  }, [findingsData, improvementsData]);

  const filteredData = risksDataArray.filter((item) => {
    const {
      risk_id,
      description,
      level,
      qms_id,
      qms_description,
      reason,
      date,
    } = item;

    const dueDate = new Date(date);
    const [startDate, endDate] = dateRange;

    const searchQueryLower = searchQuery.toLowerCase();

    const riskIdFirstLetter = risk_id?.charAt(0).toUpperCase();

    return (
      (!selectedRiskIds.length ||
        selectedRiskIds.includes(riskIdFirstLetter)) &&
      (!selectedLevels.length || selectedLevels.includes(level)) &&
      (!selectedChapters.length || selectedChapters.includes(qms_id)) &&
      (!startDate ||
        !endDate ||
        (dueDate >= startDate && dueDate <= endDate)) &&
      (!searchQueryLower ||
        risk_id.toLowerCase().includes(searchQueryLower) ||
        description?.toLowerCase().includes(searchQueryLower) ||
        level?.toLowerCase().includes(searchQueryLower) ||
        reason?.toLowerCase().includes(searchQueryLower) ||
        qms_description?.toLowerCase().includes(searchQueryLower))
    );
  });

  if (loading) {
    return (
      <PageLoader pageLoader text="Loading, thank you for your patience..." />
    );
  }

  const months = getPast12Months();

  const handleAccordionToggle = (chapterKey) => {
    setOpenChapter(chapterKey); // Update the state when an accordion is toggled
  };

  // Filter unresolved findings
  const unresolvedFindings = Object.values(findingsData)
    .filter((finding) => {
      // Check if the finding is unresolved
      const isUnresolved =
        finding.status !== "Done" &&
        (!finding.linked_improvements ||
          finding.linked_improvements.length === 0);

      // Check if the current user is associated with the finding
      const isAssignedToMe = [
        finding.reporter,
        finding.responsible,
        ...finding.accountable,
        ...finding.consulted,
        ...finding.informed,
      ].includes(currentUser);

      // Safely check for qms_id if risk object exists
      const isChapterMatch =
        (!openChapter && finding.risk) || // If openChapter is null, show all defined findings
        (openChapter === "Not Defined" && finding.risk === null) || // If openChapter is "Not Defined", show findings with no defined risk
        (finding.risk && finding.risk.qms_id === openChapter); // Show findings with matching chapter

      // Filter based on Me Mode, unresolved status, and openChapter if applicable
      return (
        isUnresolved &&
        (!meModeActive || isAssignedToMe) && // Show only assigned findings if Me Mode is active
        isChapterMatch // Check if chapter matches
      );
    })
    .sort((a, b) => new Date(b.date) - new Date(a.date));

  const getRiskLevelClass = (level) => {
    switch (level?.toLowerCase()) {
      case "low":
        return styles.lowRisk;
      case "medium":
        return styles.mediumRisk;
      case "high":
        return styles.highRisk;
      default:
        return styles.unknownRisk;
    }
  };

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    }
    return (
      <>
        {text.substring(0, maxLength)}
        <span className={styles.readMore}>...</span>
      </>
    );
  };

  const getCountForRisk = (risk_num, groupedData) => {
    const riskData = groupedData[risk_num];
    if (!riskData) return "No data"; // If no data exists for the risk number

    const latestMonthData = riskData[riskData.length - 1];
    return latestMonthData ? latestMonthData.count : "No data";
  };

  const columns = [
    {
      accessorKey: "risk_id",
      header: "Risk ID",
      size: 120,
      cell: (props) => <div>{props.getValue()}</div>,
      sortingFn: (a, b) => a.original.risk_id.localeCompare(b.original.risk_id), // Custom sorting function for Risk ID
      enableSorting: true, // Enable sorting for Risk ID
    },
    {
      accessorKey: "description",
      header: "Description",
      size: 350,
      cell: (props) => <div>{truncateText(props.getValue(), 80)}</div>,
      enableSorting: false,
    },
    {
      accessorKey: "level",
      header: "Level",
      size: 120,
      cell: (props) => <div>{truncateText(props.getValue(), 80)}</div>,
      enableSorting: false,
    },
    {
      accessorKey: "reason",
      header: "Reason",
      size: 350,
      cell: (props) => <div>{truncateText(props.getValue(), 80)}</div>,
      enableSorting: false,
    },
    {
      accessorKey: "chapter",
      header: "Chapter",
      cell: (props) => {
        const qmsId = props.row.original?.qms_id;
        const qmsDescription = props.row.original?.qms_description;

        return (
          <div>
            {qmsId && qmsDescription ? (
              <div>
                {qmsId} - {qmsDescription}
              </div>
            ) : (
              "No Chapter Info"
            )}
          </div>
        );
      },
      size: 270,
      enableSorting: false,
    },
    {
      accessorKey: "date",
      header: "Date",
      size: 130,
      cell: (props) => (
        <div>
          {props.getValue() ? formatDate(props.getValue()) : "Not defined"}
        </div>
      ),
      sortingFn: "datetime",
    },
    {
      accessorKey: "count",
      header: "RPS",
      size: 100,
      cell: (props) => {
        const risk_num = props.row.original?.risk_num; // Get risk_num from data
        const count = getCountForRisk(risk_num, riskCount); // Get the count using the helper function
        return <div>{count}</div>; // Render the count
      },
      sortingFn: (a, b) => {
        const getParsedCount = (riskNum) => {
          const count = getCountForRisk(riskNum, riskCount);
          return count === "No data" || count === null
            ? 0
            : parseInt(count, 10); // Treat "No data" as 0
        };

        const countA = getParsedCount(a.original?.risk_num);
        const countB = getParsedCount(b.original?.risk_num);

        return countA - countB; // Sort by numeric value
      },
      enableSorting: true,
    },
  ];

  const chapterOptions = _.uniqBy(
    risksDataArray.map((risk) => ({
      id: risk.qms_id,
      label: `${risk.qms_id} - ${risk.qms_description}`,
    })),
    "id", // Ensures uniqueness based on the qms_id
  ).sort((a, b) => a.id.localeCompare(b.id)); // Sorting by qms_id alphabetically

  const riskIdOptions = ["A", "B", "C", "D", "E", "F", "G", "H"].map(
    (letter) => ({
      id: letter,
      label: letter,
    }),
  );

  const levelOptions = [
    { id: "low", label: "Low" },
    { id: "medium", label: "Medium" },
    { id: "high", label: "High" },
  ];

  return (
    <MainLayout>
      <div className={styles.column}>
        <Breadcrumbs data={currentModule} />
        <Separator />

        {/* OLD RISK TABLE */}
        {/*{customHtmlModule ? (*/}
        {/*  <div*/}
        {/*    className={styles.content}*/}
        {/*    dangerouslySetInnerHTML={{*/}
        {/*      __html: customHtmlModule.params.content,*/}
        {/*    }}*/}
        {/*  />*/}
        {/*) : (*/}
        {/*  <div className={styles.workInProgressWrapper}>*/}
        {/*    <img*/}
        {/*      src={WorkInProgressIllustration}*/}
        {/*      alt="Work in progress illustration"*/}
        {/*    />*/}
        {/*    <span>This module is not configured yet.</span>*/}
        {/*    <Button*/}
        {/*      text="Go back"*/}
        {/*      backgroundColor="#DBD7FC"*/}
        {/*      type="button"*/}
        {/*      onClick={() => navigate(`/${orgId}`)}*/}
        {/*      className={styles.button}*/}
        {/*    />*/}
        {/*  </div>*/}
        {/*)}*/}

        {/*NEW RISK TABLE*/}
        <div className={styles.graphsWrapper}>
          <div className={styles.leftSide} ref={leftSideRef}>
            <div className={styles.months}>
              {months.map((month) => (
                <div>{month}</div>
              ))}
            </div>
            <GraphAccordion
              findingsData={findingsData}
              improvementsData={improvementsData}
              onAccordionToggle={handleAccordionToggle}
            />
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div className={styles.title}>Unresolved Findings</div>
            <div
              className={styles.rightSide}
              ref={rightSideRef}
              style={{ overflowY: "auto" }}
            >
              <div className={styles.unresolvedFindings}>
                {unresolvedFindings.length > 0 ? (
                  unresolvedFindings.map((finding) => (
                    <div key={finding.id} className={styles.findingItem}>
                      <div
                        className={classNames(
                          styles.key,
                          getRiskLevelClass(finding.risk?.level),
                        )}
                      >
                        {finding.key}:
                      </div>
                      <div>
                        {finding.risk ? (
                          <>
                            <span className={styles.risk}>
                              {`${finding.risk?.risk_id} - `}
                            </span>
                            {truncateText(finding.description, 120)}
                          </>
                        ) : (
                          "Risk is not defined"
                        )}
                      </div>
                    </div>
                  ))
                ) : (
                  <p>No unresolved findings.</p>
                )}
              </div>
            </div>
            <SecondaryButton
              text="View all findings"
              onClick={() => navigate(`/${orgId}/findings`)}
              className={styles.secondaryButton}
            />
          </div>
        </div>
        <Separator className={styles.separator} />
        <div className={styles.filtersContainer}>
          <div className={styles.filtersWrapper}>
            <MultiSelect
              label={
                selectedRiskIds.length > 0
                  ? selectedRiskIds.join(", ")
                  : "Risk ID"
              }
              options={riskIdOptions}
              selectedValues={selectedRiskIds}
              onChangeSelectedValues={setSelectedRiskIds}
            />
            <MultiSelect
              label={
                selectedLevels.length > 0 ? selectedLevels.join(", ") : "Level"
              }
              options={levelOptions}
              selectedValues={selectedLevels}
              onChangeSelectedValues={setSelectedLevels}
            />
            <MultiSelect
              label={
                selectedChapters.length > 0
                  ? selectedChapters
                      .map(
                        (id) =>
                          chapterOptions.find((opt) => opt.id === id).label,
                      )
                      .join(", ")
                  : "Chapter"
              }
              options={chapterOptions}
              selectedValues={selectedChapters}
              onChangeSelectedValues={setSelectedChapters}
            />
            <DateRangeFilter
              dateRange={dateRange}
              handleDateRangeChange={setDateRange}
              isClearable
            />
          </div>
          <Input
            type="text"
            placeholder="Search"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            icon={SearchIcon}
            className={styles.input}
          />
        </div>
        <div className={styles.tableContainer}>
          <Table
            columns={columns}
            data={filteredData}
            initialSorting={[{ id: "risk_id", desc: false }]}
          />
          {!filteredData?.length && (
            <div className={styles.noData}>
              Sorry, there is no data to display.
            </div>
          )}
        </div>
      </div>
    </MainLayout>
  );
};

export default RiskAnalysis;

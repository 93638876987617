import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import styles from "./Select.module.scss";
import DownArrowIcon from "../../icons/down-arrow.svg";
import UpArrowIcon from "../../icons/up-arrow.svg";
import OutsideClickHandler from "react-outside-click-handler";

const Select = ({
  text,
  field,
  form,
  options,
  disabled,
  className,
  isOverlay = true,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const optionsRefs = useRef([]);

  const handleSelect = (option) => {
    form.setFieldValue(field.name, option.label);
    setIsOpen(false);
  };

  // Check if the current field value is an id or label
  const selectedOption = options.find(
    (option) => option.label === field.value || option.id === field.value,
  );

  const hasError = form.errors[field.name] && form.touched[field.name];

  // Scroll to the option starting with the pressed letter
  useEffect(() => {
    if (isOpen) {
      const handleKeyDown = (e) => {
        e.stopPropagation();

        const key = e.key.toUpperCase();
        const matchedIndex = options.findIndex((option) =>
          option.label.toUpperCase().startsWith(key),
        );

        if (matchedIndex !== -1 && optionsRefs.current[matchedIndex]) {
          optionsRefs.current[matchedIndex].scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
      };

      window.addEventListener("keydown", handleKeyDown);

      return () => {
        window.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [isOpen, options]);

  return (
    <OutsideClickHandler onOutsideClick={() => setIsOpen(false)}>
      <div className={classNames(styles.selectWrapper, className)}>
        <div
          className={classNames(
            styles.select,
            disabled && styles.disabled,
            hasError && styles.error,
            selectedOption && styles.active,
          )}
          onClick={() => !disabled && setIsOpen(!isOpen)}
        >
          {/* Display the label of the selected option or fallback to the placeholder text */}
          <span
            className={classNames({ [styles.placeholder]: !selectedOption })}
          >
            {selectedOption ? selectedOption.label : text}
          </span>{" "}
          {isOpen ? (
            <img
              src={UpArrowIcon}
              alt="Up Arrow Icon"
              className={styles.icon}
            />
          ) : (
            <img
              src={DownArrowIcon}
              alt="Down Arrow Icon"
              className={styles.icon}
            />
          )}
        </div>
        {isOpen && (
          <div
            className={classNames(styles.options, isOverlay && styles.overlay)}
          >
            {options.map((option, index) => {
              return (
                <div
                  key={index}
                  ref={(el) => (optionsRefs.current[index] = el)} // Assign ref to each option
                  className={classNames(
                    styles.option,
                    option.label === field.value || option.value === field.value
                      ? styles.selected
                      : null, // Highlight selected by label or value
                  )}
                  onClick={() => handleSelect(option)}
                >
                  {option.label}
                </div>
              );
            })}
          </div>
        )}
        {hasError && (
          <div className={styles.errorMessage}>{form.errors[field.name]}</div>
        )}
      </div>
    </OutsideClickHandler>
  );
};

export default Select;

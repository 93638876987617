import React, { useEffect, useState } from "react";
import styles from "./Findings.module.scss";
import Table from "../../components/Table/Table";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import MainLayout from "../../layouts/MainLayout/MainLayout";
import PageLoader from "../../components/Loader/PageLoader";
import classNames from "classnames";
import Input from "../../components/Input/Input";
import SearchIcon from "../../icons/search.svg";
import MultiSelect from "../../components/MultiSelect/MultiSelect";
import { formatDate, getLinkedData, handleExport } from "../../utils/helpers";
import { showModal } from "../../redux/modalStore";
import { useDispatch, useSelector } from "react-redux";
import FindingsModal from "../../modals/FindingsModal";
import { FINDINGS_LOADER, FINDINGS_MODAL_LOADER } from "../../constants/loader";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Separator from "../../components/Separator/Separator";
import { setLinkedData } from "../../redux/organizationStore";
import Button from "../../components/Button/Button";
import DateRangeFilter from "../../components/DateRangeFilter/DateFIlterRange";
import useFetchOrganizationData from "../../actions/useFetchOrganizationData";
import ArchiveIcon from "../../icons/archive.svg";
import ExportIcon from "../../icons/export.svg";
import axiosInstance from "../../utils/utils";
import { getOrganizationData } from "../../actions/organization";
import Swal from "sweetalert2";
import BulkEditModal from "../../modals/BulkEditModal/BulkEditModal";
import EditIcon from "../../icons/edit.svg";
import {
  clearResetSelection,
  resetSelection,
} from "../../redux/rowSelectionStore";
import ConfirmationPopup from "../../modals/ConfirmationPopup";
import ArchiveIllustration from "../../icons/archive-illustration.svg";
import { hideOverlay, showOverlay } from "../../redux/overlayLoaderStore";

const Findings = () => {
  const [selectedFindingData, setSelectedFindingData] = useState(null);
  const [selectedFindings, setSelectedFindings] = useState([]);
  const [selectedResponsibles, setSelectedResponsibles] = useState([]);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [selectedLevels, setSelectedLevels] = useState([]);
  const [selectedRisks, setSelectedRisks] = useState([]);
  const [dateRange, setDateRange] = useState([null, null]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingAction, setLoadingAction] = useState("");
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);

  const { orgId, findingKey } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const sessionToken = localStorage.getItem("session_token");

  useFetchOrganizationData();

  const loadingContexts = useSelector((state) => state.loader.loadingContexts);
  const modules = useSelector((state) => state.user.modules[orgId]);
  const { meModeActive } = useSelector((state) => state.filters);
  const { risksData, findingsData, data } = useSelector(
    (state) => state.organization,
  );
  const currentUser = useSelector((state) => state.user.email);
  const resetRowSelection = useSelector(
    (state) => state.rowSelection.resetSelection,
  );

  const isFindingsModalLoading = loadingContexts[FINDINGS_MODAL_LOADER];
  const isFindingsDataLoading = loadingContexts[FINDINGS_LOADER];

  // Extracting path from URL
  const lastIndex = location.pathname.lastIndexOf("/");
  const modifiedPath = location.pathname.substring(lastIndex);

  // Find the module object corresponding to the current path
  const currentModule = modules?.find((mod) => mod.path === modifiedPath);

  const findingsArray = Object.values(findingsData || {});

  useEffect(() => {
    if (findingKey && findingsData) {
      const matchedFinding = Object.values(findingsData).find(
        (finding) => finding.key === findingKey,
      );

      if (matchedFinding) {
        const linkedData = getLinkedData(matchedFinding, data);
        dispatch(setLinkedData(linkedData));
        setSelectedFindingData(matchedFinding);
        dispatch(
          showModal({
            name: "findings_modal",
            data: matchedFinding,
          }),
        );
      }
    }
  }, [findingKey, findingsData, dispatch, data]);

  // Unique responsible options
  const uniqueResponsibles = findingsArray
    .flatMap((finding) =>
      Array.isArray(finding.responsible)
        ? finding.responsible
        : [finding.responsible],
    )
    .filter(Boolean)
    .filter((value, index, self) => self.indexOf(value) === index)
    .map((responsible, index) => ({ id: responsible, label: responsible }));

  // Unique Risk Levels Options
  const uniqueLevels = findingsArray
    .map((finding) => finding.risk?.level || "unknown") // Handle missing level
    .filter((value, index, self) => value && self.indexOf(value) === index)
    .map((level) => ({
      id: level.toLowerCase(), // Keep lowercase for filtering
      label: level.charAt(0).toUpperCase() + level.slice(1).toLowerCase(), // Format for display
    }));

  const handleLevelFilterChange = (newSelectedValues) => {
    setSelectedLevels(newSelectedValues);
  };

  // Status Options
  const uniqueStatuses = findingsArray
    .map((finding) => finding.status)
    .filter((value, index, self) => value && self.indexOf(value) === index)
    .map((status, index) => ({ id: status, label: status }));

  // Unique Risk Options
  const uniqueRisks = findingsArray
    .map((finding) => finding.risk?.risk_id || "No Risk ID") // Handle missing risk_id
    .filter((value, index, self) => value && self.indexOf(value) === index)
    .map((riskId) => ({ id: riskId, label: riskId }))
    .sort((a, b) => a.label.localeCompare(b.label)); // Sort alphabetically by label

  const handleResponsibleFilterChange = (newSelectedValues) => {
    setSelectedResponsibles(newSelectedValues);
  };

  const handleStatusFilterChange = (newSelectedValues) => {
    setSelectedStatuses(newSelectedValues);
  };

  const handleDateRangeFilterChange = (newDateRange) => {
    setDateRange(newDateRange);
  };

  const handleRiskFilterChange = (newSelectedValues) => {
    setSelectedRisks(newSelectedValues);
  };

  const handleExportSelectedFindings = async () => {
    setLoading(true);
    setLoadingAction("export");

    await handleExport({
      selectedItems: selectedFindings,
      orgId,
      sessionToken,
      getKey: (item) => item.key,
      filePrefix: "Finding",
    });
    setLoading(false);
    setLoadingAction("");
  };

  const handleBulkArchive = async () => {
    setShowConfirmationPopup(false);

    if (!selectedFindings.length) return;

    const payload = selectedFindings.map((project) => ({
      id: project.id,
      archived: true,
    }));

    const archivedKeys = selectedFindings.map((project) => project.key);

    setLoading(true);
    setLoadingAction("archive");
    dispatch(showOverlay());

    try {
      await axiosInstance.patch(`/nocodb/findings?org_id=${orgId}`, payload, {
        headers: {
          "Content-Type": "application/json",
          "session-token": sessionToken,
        },
      });

      // Reset selection state
      setSelectedFindings([]);

      // Dispatch updated organization data
      await dispatch(
        getOrganizationData(
          orgId,
          sessionToken,
          navigate,
          false,
          true,
          false,
          false,
          false,
          false,
        ),
      );

      // Show success alert
      Swal.fire({
        toast: true,
        icon: "success",
        title: `Archive successful`,
        text: `Keys archived: ${archivedKeys.join(", ")}`,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });
    } catch (error) {
      Swal.fire({
        toast: true,
        icon: "error",
        title: `Archive failed`,
        text: `Could not archive findings. Please try again.`,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });
    } finally {
      setLoading(false);
      setLoadingAction("");
      dispatch(hideOverlay()); // Hide overlay

      // Trigger row selection reset
      dispatch(resetSelection());
      setTimeout(() => dispatch(clearResetSelection()), 100); // Reset to false after a short delay
    }
  };

  const filteredData = findingsArray.filter((item) => {
    const {
      responsible,
      accountable,
      consulted,
      informed,
      status,
      date: itemDate,
      risk,
      reference,
      reporter,
    } = item;
    const dueDate = new Date(itemDate);

    const formattedStartDate = dateRange[0];
    const formattedEndDate = dateRange[1];

    const searchQueryLower = searchQuery.toLowerCase();

    const filters = [
      selectedResponsibles.length === 0 ||
        (Array.isArray(responsible)
          ? responsible.some((r) => selectedResponsibles.includes(r))
          : selectedResponsibles.includes(responsible)),
      selectedStatuses.length === 0 || selectedStatuses.includes(status),
      selectedLevels.length === 0 ||
        selectedLevels.includes(risk?.level?.toLowerCase() || "unknown"),
      selectedRisks.length === 0 ||
        selectedRisks.includes(risk?.risk_id || "No Risk ID"),
      (!formattedStartDate && !formattedEndDate) ||
        (dueDate >= formattedStartDate && dueDate <= formattedEndDate),
      !searchQueryLower ||
        item.key?.toLowerCase().includes(searchQueryLower) ||
        item.description?.toLowerCase().includes(searchQueryLower) ||
        reference?.toLowerCase().includes(searchQueryLower) ||
        reporter?.toLowerCase().includes(searchQueryLower) ||
        (Array.isArray(responsible)
          ? responsible.some((r) =>
              r
                .toLowerCase()
                .normalize()
                .includes(searchQueryLower.normalize()),
            )
          : responsible
              ?.toLowerCase()
              .normalize()
              .includes(searchQueryLower.normalize())) ||
        status
          ?.toLowerCase()
          .normalize()
          .includes(searchQueryLower.normalize()) ||
        risk?.qms_id
          ?.toLowerCase()
          .normalize()
          .includes(searchQueryLower.normalize()) ||
        risk?.risk_id
          ?.toLowerCase()
          .normalize()
          .includes(searchQueryLower.normalize()) ||
        risk?.level
          ?.toLowerCase()
          .normalize()
          .includes(searchQueryLower.normalize()),
      !meModeActive ||
        [responsible, accountable, consulted, informed, reporter].some(
          (role) =>
            Array.isArray(role)
              ? role.includes(currentUser)
              : role === currentUser,
        ),
    ];

    return filters.every(Boolean);
  });

  const handleRowClick = (row) => {
    const linkedData = getLinkedData(row.original, data);

    dispatch(setLinkedData(linkedData));
    setSelectedFindingData(row.original);

    navigate(`/${orgId}/findings/${row.original.key}`, { replace: false });

    dispatch(
      showModal({
        name: "findings_modal",
        data: row.original,
      }),
    );
  };

  const truncateText = (text, maxLength) => {
    if (text?.length <= maxLength) {
      return text;
    }
    return (
      <>
        {text?.substring(0, maxLength)}
        <span className={styles.readMore}>... read more</span>
      </>
    );
  };

  const handleSelectedRowsChange = (selectedRows) => {
    setSelectedFindings(selectedRows);
  };

  const handleOpenNewImprovementModal = () => {
    dispatch(
      showModal({
        name: "new_improvement_modal",
        data: selectedFindings,
      }),
    );
  };

  const handleBulkEdit = () => {
    dispatch(
      showModal({
        name: "bulk_edit_modal",
        data: selectedFindings,
      }),
    );
  };

  const columns = [
    {
      accessorKey: "select",
      header: ({ table }) => (
        <div
          className={styles.customCheckboxContainer}
          onClick={(e) => {
            e.stopPropagation(); // Stop click event propagation
            table.toggleAllPageRowsSelected(!table.getIsAllPageRowsSelected());
          }}
        >
          {table.getIsAllPageRowsSelected() ? (
            <div className={styles.checked} />
          ) : (
            <div className={styles.unchecked} />
          )}
        </div>
      ),
      size: 50,
      cell: ({ row }) => (
        <div
          className={styles.customCheckboxContainer}
          onClick={(e) => {
            e.stopPropagation(); // Prevent row click
            row.toggleSelected();
          }}
        >
          {row.getIsSelected() ? (
            <div className={styles.checked} />
          ) : (
            <div className={styles.unchecked} />
          )}
        </div>
      ),
      enableSorting: false,
    },
    {
      accessorKey: "key",
      header: "Key",
      size: 90,
      cell: (props) => <div>{props.getValue()}</div>,
      sortingFn: (rowA, rowB, columnId) => {
        const valueA = rowA.getValue(columnId);
        const valueB = rowB.getValue(columnId);

        // Extract numeric portion from keys
        const numA = parseInt(valueA.split("-")[1], 10);
        const numB = parseInt(valueB.split("-")[1], 10);

        return numA - numB;
      },
    },
    {
      accessorKey: "reference",
      header: "Reference",
      size: 200,
      cell: (props) => <div>{props.getValue()}</div>,
      enableSorting: false,
    },
    {
      accessorKey: "risk.risk_id",
      header: "Risk",
      size: 100,
      cell: (props) => {
        const riskId = props.row.original?.risk?.risk_id;
        return <div>{riskId || "No Risk ID"}</div>; // Fallback if risk_id is undefined
      },
      enableSorting: false,
    },
    {
      accessorKey: "risk.level",
      header: "Level",
      size: 100,
      cell: (props) => {
        const level = props.row.original?.risk?.level || "unknown"; // Fallback if level is undefined
        const formattedLevel =
          level.charAt(0).toUpperCase() + level.slice(1).toLowerCase(); // Format to uppercase first letter
        return <div>{formattedLevel}</div>;
      },
      enableSorting: false,
    },
    {
      accessorKey: "description",
      header: "Description",
      size: 350,
      cell: (props) => <div>{truncateText(props.getValue(), 80)}</div>,
      enableSorting: false,
    },
    {
      accessorKey: "reporter",
      header: "Reporter",
      size: 170,
      cell: (props) => {
        return props.row.original.reporter ? (
          <div>{props.row.original.reporter}</div>
        ) : (
          <div>Not defined</div>
        );
      },
      enableSorting: false,
    },
    {
      accessorKey: "responsible",
      header: "Responsible",
      size: 170,
      cell: (props) => {
        return props.row.original.responsible ? (
          <div>{props.row.original.responsible}</div>
        ) : (
          <div>Not defined</div>
        );
      },
      enableSorting: false,
    },
    {
      accessorKey: "status",
      header: "Status",
      size: 120,
      cell: (props) => {
        const status = props.getValue();
        let statusClassName = "";

        switch (status) {
          case "Open":
            statusClassName = styles.openStatus;
            break;
          case "In Progress":
            statusClassName = styles.inProgressStatus;
            break;
          case "In Review":
            statusClassName = styles.inReviewStatus;
            break;
          case "Done":
            statusClassName = styles.resolvedStatus;
            break;
          default:
            break;
        }

        return (
          <div className={classNames(styles.statusCell, statusClassName)}>
            {status}
          </div>
        );
      },
      enableSorting: false,
    },
    {
      accessorKey: "date",
      header: "Date",
      size: 130,
      cell: (props) => <div>{formatDate(props.getValue())}</div>,
      sortingFn: "datetime",
    },
  ];

  const risksArray = Object.values(risksData || {});

  // Pie chart URL for risk levels
  const pieChartUrl =
    "https://chart.entropical.io/chart?c=" +
    encodeURIComponent(
      JSON.stringify({
        type: "pie",
        data: {
          datasets: [
            {
              data: [
                filteredData.filter((r) => r.risk?.level === "low").length,
                filteredData.filter((r) => r.risk?.level === "medium").length,
                filteredData.filter((r) => r.risk?.level === "high").length,
              ],
              backgroundColor: ["#007940", "#FF7427", "#C60C30"],
            },
          ],
          labels: ["Low", "Medium", "High"],
        },
        options: {
          title: {
            display: true,
            position: "top",
            fontSize: 20,
            text: "Risk levels",
          },
          plugins: {
            datalabels: {
              display: false,
            },
          },
        },
      }),
    );

  // Generate the bar chart URL with the QMS chapters counts
  const barChartUrl =
    "https://chart.entropical.io/chart?c=" +
    encodeURIComponent(
      JSON.stringify({
        type: "bar",
        data: {
          labels: ["A", "B", "C", "D", "E", "F", "G", "H"], // Main QMS letters
          datasets: [
            {
              backgroundColor: "#B4E1FA",
              data: ["A", "B", "C", "D", "E", "F", "G", "H"].map((letter) => {
                return filteredData.filter(
                  (item) =>
                    item.risk &&
                    item.risk.qms_id &&
                    item.risk.qms_id.trim().startsWith(letter),
                ).length;
              }),
            },
          ],
        },
        options: {
          legend: {
            display: false,
          },
          title: {
            display: true,
            text: "QMS Chapters",
            fontSize: 20,
          },
          plugins: {
            datalabels: {
              anchor: "center",
              align: "center",
              color: "#222229",
              font: {
                weight: "normal",
                size: 14,
              },
            },
          },
        },
      }),
    );

  return (
    <MainLayout>
      <div className={styles.column}>
        <FindingsModal
          findingData={selectedFindingData}
          isLoading={isFindingsModalLoading}
        />
        <BulkEditModal modalType="findings" orgId={orgId} />
        {isFindingsDataLoading && (
          <PageLoader text="Loading, it will be ready shortly" />
        )}
        {!isFindingsDataLoading && (
          <>
            <Breadcrumbs data={currentModule} />
            <div className={styles.findingsContainer}>
              <div className={styles.rowWrapper}>
                <div className={styles.actionsWrapper}>
                  <Button
                    text="Bulk Edit"
                    className={styles.button}
                    onClick={handleBulkEdit}
                    disabled={selectedFindings.length < 2 || loading}
                    icon={EditIcon}
                    iconPosition="right"
                  />
                  <Button
                    text={
                      loading && loadingAction === "archive"
                        ? "Archiving..."
                        : "Archive"
                    }
                    className={styles.button}
                    onClick={() => setShowConfirmationPopup(true)}
                    disabled={selectedFindings.length === 0 || loading}
                    icon={ArchiveIcon}
                    iconPosition="right"
                  />
                  <Button
                    text={
                      loading && loadingAction === "export"
                        ? "Exporting..."
                        : "Export"
                    }
                    className={styles.button}
                    onClick={handleExportSelectedFindings}
                    disabled={selectedFindings.length === 0 || loading}
                    icon={ExportIcon}
                    iconPosition="right"
                  />
                  <Button
                    text="Create New Improvement Project"
                    className={styles.button}
                    onClick={handleOpenNewImprovementModal}
                    disabled={selectedFindings.length === 0}
                  />
                </div>
              </div>
              <div className={styles.rowWrapper}>
                <div className={styles.filtersWrapper}>
                  <MultiSelect
                    label={
                      selectedResponsibles.length > 0
                        ? selectedResponsibles.join(", ")
                        : "Responsible"
                    }
                    options={uniqueResponsibles}
                    onChangeSelectedValues={handleResponsibleFilterChange}
                    selectedValues={selectedResponsibles}
                    disabled={!findingsArray.length}
                  />
                  <MultiSelect
                    label={
                      selectedStatuses.length > 0
                        ? selectedStatuses.join(", ")
                        : "Status"
                    }
                    options={uniqueStatuses}
                    onChangeSelectedValues={handleStatusFilterChange}
                    selectedValues={selectedStatuses}
                    disabled={!findingsArray.length}
                  />
                  <MultiSelect
                    label={
                      selectedLevels.length > 0
                        ? selectedLevels
                            .map(
                              (level) =>
                                uniqueLevels.find(
                                  (option) => option.id === level,
                                )?.label || level,
                            )
                            .join(", ")
                        : "Levels"
                    }
                    options={uniqueLevels}
                    onChangeSelectedValues={handleLevelFilterChange}
                    selectedValues={selectedLevels}
                    disabled={!findingsArray.length}
                  />
                  <MultiSelect
                    label={
                      selectedRisks.length > 0
                        ? selectedRisks.join(", ")
                        : "Risk"
                    }
                    options={uniqueRisks}
                    onChangeSelectedValues={handleRiskFilterChange}
                    selectedValues={selectedRisks}
                    disabled={!findingsArray.length}
                  />
                  <DateRangeFilter
                    handleDateRangeChange={handleDateRangeFilterChange}
                    dateRange={dateRange}
                    isClearable
                  />
                </div>
                <Input
                  type="text"
                  placeholder="Search"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className={styles.input}
                  icon={SearchIcon}
                />
              </div>
              <div className={styles.tableWrapper}>
                <Table
                  columns={columns}
                  data={filteredData}
                  onRowClick={handleRowClick}
                  onSelectedRowsChange={handleSelectedRowsChange}
                  rowHover
                  initialSorting={[{ id: "date", desc: true }]}
                  resetSelection={resetRowSelection}
                />
              </div>
              {!filteredData.length && (
                <div className={styles.noData}>
                  Sorry, there is no data to display.
                </div>
              )}
              <Separator className={styles.separator} />
              <div className={styles.chartWrapper}>
                {/* Display the charts */}
                <img
                  src={pieChartUrl}
                  alt="Risk Levels Pie Chart"
                  className={styles.chartImage}
                />
                <img
                  src={barChartUrl}
                  alt="QMS Chapters Bar Chart"
                  className={styles.chartImage}
                />
              </div>
            </div>
          </>
        )}
      </div>
      {showConfirmationPopup && (
        <ConfirmationPopup
          message="Are you sure you want to archive selected findings?"
          illustration={ArchiveIllustration}
          confirmText="Yes, archive"
          onConfirm={handleBulkArchive}
          onCancel={() => setShowConfirmationPopup(false)}
        />
      )}
    </MainLayout>
  );
};

export default Findings;
